import React, { useState, useEffect, useContext, useCallback } from 'react';
import { ElementaryFlowOutSimple, ProcessCalculate, LciResultItems, ResultItems0, ResultItems1, LciResultBreakdown } from '@typeList/types';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import ProcessCalculateResultTable from '@common/table/ProcessCalculateResultTable';
import ProessContributionAnalysisModal from '../contribution_analysis/ProessContributionAnalysisModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { formatNumber } from '@utils/utils';

interface InventoryAnalysisProps {
    calculateData: ProcessCalculate[] | null;
    filteredData: any[];
    selectedProcessId: number | null;
}

const InventoryAnalysis: React.FC<InventoryAnalysisProps> = ({ calculateData, filteredData, selectedProcessId }) => {
    const {t} = useTranslation();
    const context = useContext(ProcessViewContext)
    const {userData } = context;

    const [resultData, setResultData] = useState<any[] | null>([]);
    useEffect(() => {
        setResultData(calculateData);
    }, [calculateData]);

    // エクスポート用のcolumn data
    const [exportColumns, setExportColumns] = useState<any>()
    const [exportData, setExportData] = useState<any>()
    const exportSet = (columns: any, data: any) => {
        setExportColumns(columns);
        setExportData(data);
    }

    // CSVダウンロード用の関数を定義
    const downloadCsv = (csv: string, filename: string) => {
        // UTF-8 BOMを追加
        const csvWithBom = `\uFEFF${csv}`;

        // CSV文字列をBlobオブジェクトに変換
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

        // ダウンロードリンクを作成してダウンロードをトリガー
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // メモリリークを防ぐためにURLを解放
    };

    // データを整形する関数
    const transformData = (data: any[], columns: ColumnDef<any>[]) => {
        const headers = columns.map((column) => {
            if (typeof column.header === 'function') {
                const headerContent = column.header({} as any);
                return headerContent.props ? `${headerContent.props.children[0]} ${headerContent.props.children[2]}` : headerContent;
            }
            return column.header;
        });
        const transformedData = data.map((row) => {
            const transformedRow: any = {};

            columns.forEach((column) => {
                if (!column.id) return;

                switch (column.id) {
                    case 'direction':
                        transformedRow[column.id] = row.direction === 'out' ? t('出力') : t('入力');
                        break;
                    case 'exchange.name':
                        transformedRow[column.id] = row.exchange?.name || '';
                        break;
                    case 'exchange.category1_name':
                        transformedRow[column.id] = row.exchange?.category1_name || '';
                        break;
                    case 'exchange.category2_name':
                        transformedRow[column.id] = row.exchange?.category2_name || '';
                        break;
                    case 'exchange.category3_name':
                        transformedRow[column.id] = row.exchange?.category3_name || '';
                        break;
                    case 'amount':
                        transformedRow[column.id] = row.amount || 0;
                        break;
                    case 'unit.name':
                        transformedRow[column.id] = row.unit?.name || '';
                        break;
                    default:
                        if (column.id.startsWith('breakdown_')) {
                            // breakdown_<grouping_key_id>_<sub_grouping_key_id> の形式から ID を取得
                            const [groupingKeyId, subGroupingKeyId] = column.id.split('_').slice(1).map(Number);

                            // `relatedBreakdown` の中から `groupingKeyId` と `subGroupingKeyId` に一致する項目を探す
                            const matchingBreakdowns = row.relatedBreakdown.filter(
                                (b: any) => b.groupingKeyId === groupingKeyId && b.subGroupingKeyId === subGroupingKeyId
                            );

                            // マッチする breakdown の amount を取り出して連結
                            transformedRow[column.id] = matchingBreakdowns.map((b: any) => b.amount).join(', ') || '0'; // カンマ区切りで連結
                        } else {
                            transformedRow[column.id] = ''; // その他のカラムは空に設定
                        }
                        break;
                }
            });

            return transformedRow;
        });
        return { headers, transformedData };
    };

    // エクスポート関数
    const exportCsv = (data: any[], columns: ColumnDef<any>[]) => {
        console.log("data", data , "columns", columns);
        if (!data || data.length === 0 || !columns || columns.length === 0) {
            console.error(t('エクスポートするデータまたはカラム情報が不足しています。'));
            return;
        }

        // データを変換
        const { headers, transformedData } = transformData(data, columns);

        try {
            // CSVを生成する
            const csvContent = [
                headers.map(header => `"${header}"`).join(','),
                ...transformedData.map(row =>
                    Object.values(row).map(value => `"${String(value).replace(/"/g, '""')}"`).join(',')
                )].join('\n');

            // Blobを使ってダウンロード
            downloadCsv(csvContent, "process_lci_results" || 'default.csv');
        } catch (error) {
            console.error(t('CSVのエクスポート中にエラーが発生しました') +':', error);
        }
    };

    // 型ガード関数の定義
    function isResultItems0(item: ResultItems0 | ResultItems1): item is ResultItems0 {
        return (item as ResultItems0).exchange !== undefined;
    }

    // 型ガード関数の定義
    function isElementaryFlowOutSimple(exchange: any): exchange is ElementaryFlowOutSimple {
        return 'category1_name' in exchange;
    }

    // フラットなデータを作成する関数
    const flattenResultData = useCallback(() => {
        if (!filteredData || filteredData.length === 0) return [];
        // console.log("filteredData", filteredData[0])
        return filteredData[0].result_items.map((item: any) => {
            // 型ガードで lcia_model プロパティが存在することを確認
            if (!isResultItems0(item)) {
                return { ...item, relatedBreakdown: [] };
            }

            const relatedBreakdown = filteredData[0].result_breakdown?.lci_result_breakdown.map((breakdown: LciResultBreakdown) => {
                // result_key_id と exchange.id が一致する場合のみ関連付け
                const matchingItems = breakdown.lci_result_breakdown_items.filter(
                    (breakdownItem) => breakdownItem.result_key_id === item.exchange.id
                );
                // console.log("matchingItems", matchingItems);

                // result_key_id が一致する場合のみ breakdown を含める
                if (matchingItems.length === 0) return null;

                return {
                    groupingKeyId:  breakdown.grouping_key_id,
                    subGroupingKeyId:  breakdown.sub_grouping_key_id,
                    groupingName: breakdown.grouping_name,
                    amount: matchingItems[0].amount,
                    ratio: matchingItems[0].ratio,
                };
            }).filter(Boolean); // null の値を除外

            return {
                ...item,
                relatedBreakdown: relatedBreakdown || [],
            };
        });
    }, [filteredData]);

    const generateColumns = useCallback((): ColumnDef<any>[] => {
        let baseColumns: ColumnDef<any>[] = [
            {
                id: 'direction',
                header: () => t('方向'),
                accessorFn: (row) => row.direction, // ソート用のデータを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    if (resultItem.direction === "out") {
                        return t('出力');
                    } else {
                        return t('入力');
                    }
                    // return isResultItems0(resultItem) ? resultItem.direction : '';
                },
            },
            {
                id: 'exchange.name',
                header: () => t('物質名'),
                accessorKey: 'exchange.name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) ? resultItem.exchange.name : '';
                },
            },
            {
                id: 'exchange.category1_name',
                header: () => t('カテゴリ1'),
                accessorKey: 'exchange.category1_name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) && isElementaryFlowOutSimple(resultItem.exchange)
                        ? resultItem.exchange.category1_name
                        : '';
                },
            },
            {
                id: 'exchange.category2_name',
                header: () => t('カテゴリ2'),
                accessorKey: 'exchange.category2_name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) && isElementaryFlowOutSimple(resultItem.exchange)
                        ? resultItem.exchange.category2_name
                        : '';
                },
            },
            {
                id: 'exchange.category3_name',
                header: () => t('カテゴリ3'),
                accessorKey: 'exchange.category3_name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) && isElementaryFlowOutSimple(resultItem.exchange)
                        ? resultItem.exchange.category3_name
                        : '';
                },
            },
            {
                id: 'amount',
                header: () => t('合計'),
                accessorKey: 'amount', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    const amount = isResultItems0(resultItem) ? resultItem.amount : 0;
                    // if (inputDsplayFormatValue === "2" && amount > 0) {
                    //     return ((resultItem.amount / amount) * 100).toFixed(2) + '%';
                    // }
                    if (userData) {
                        return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                    } else {
                        return amount; // デフォルトではそのままの値を表示
                    }
                },
            },
            {
                id: 'unit.name',
                header: () => t('単位'),
                accessorKey: 'unit.name', // ソート用のキーを指定
                enableSorting: true,    
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) ? resultItem.unit.name : '';
                },
            },
        ];

        // lci_result_breakdownを使用した追加カラムの処理
        filteredData[0]?.result_breakdown?.lci_result_breakdown.forEach((breakdown: any, breakdownIndex: any) => {
            baseColumns.push({
                id: `breakdown_${breakdown.grouping_key_id}_${breakdown.sub_grouping_key_id}`,
                header: () =>(
                    <span>
                        {breakdown.grouping_name}
                        <br />
                        {breakdown.sub_grouping_name}
                    </span>
                ),
                accessorFn: (row) => {
                    // ソート用のデータ指定
                    const relatedBreakdown = row.relatedBreakdown.find(
                        (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                    );
                    return relatedBreakdown ? relatedBreakdown.amount : 0;
                },
                enableSorting: true,
                cell: (info) => {
                    const relatedBreakdown = info.row.original.relatedBreakdown.find(
                        (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                    );
                    const amount = relatedBreakdown ? relatedBreakdown.amount : 0;
                    if (userData) {
                        return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                    } else {
                        return amount; // デフォルトではそのままの値を表示
                    }
                },
            });
        });


        return baseColumns;
    }, [resultData, filteredData]);

    const [columns, setColumns] = useState<ColumnDef<any>[]>(generateColumns());
    const [flattenedData, setFlattenedData] = useState<any[]>([]);

    useEffect(() => {
        setFlattenedData(flattenResultData());
    }, [filteredData]);

    useEffect(() => {
        setColumns(generateColumns());
    }, [filteredData]);

    return (
        <Section>
            <ProcessCalculateResultTable columns={columns} data={flattenedData} paging_flag={false} exportSet={exportSet}/>
            <ButtonWrap>
                <Button type='button' onClick={() => exportCsv(exportData || [], exportColumns || [])}>
                    <ButtonText>{t('エクスポート')}</ButtonText>
                </Button>
                <ProessContributionAnalysisModal calculateData={filteredData} selectedProcessId={selectedProcessId} result_type="lci"></ProessContributionAnalysisModal>
            </ButtonWrap>
        </Section>
    );
};

export default InventoryAnalysis;

const Section = styled.section`
    margin-bottom: 32px;
    display: block;
`

const H2 = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    // border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const ButtonWrap = styled.div`
    margin: 20px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
`

const Button = styled.button`
    margin-right: 10px;
    background-color: var(--color-site-secondary);
    border-radius: 4px;
    border: 1px solid var(--color-site-secondary);
    padding: 7px 16px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    font: inherit;
    outline: none;
`

const ButtonText = styled.span`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
`

import Modal from '@common/modal/Modal';
import { LinkMenu } from '@styles/Common';
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { useProcessTsvImport } from '@hooks/useBackendApi';
import ErrorMsg from '@common/error/ErrorMsg';
import { GetUserDetail } from '@typeList/types';
import { getCurrentDefaultDatabasePackId } from '@services/Cookies';
import { getValueByKey } from '@utils/utils';
import { FadeLoader } from 'react-spinners';

interface ProcessImportModalProps {
    userData: GetUserDetail | null;
}

/**
 * 026 プロセスのインポート
 * @returns 
 */
const ProcessImportModal:React.FC<ProcessImportModalProps> = ({userData}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [inpuProcessInportRadio, setInpuProcessInportRadio] = useState<string>("1");
    const [inputFiles, setInputFiles] = useState<File | null>(null);
    const [inputFileName, setInputFileName] = useState<string>("");
    const inputFileRef = useRef<HTMLInputElement>(null);

    // データベースパック
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
        }
    }, [userData]);

    useEffect(() => {
        if(getCurrentDefaultDatabasePackId()){
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    // フォーマット選択
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "process_format":
                if (event.target.value === "1") {
                    setInpuProcessInportRadio("1");
                }
                if (event.target.value === "2") {
                    setInpuProcessInportRadio("2");
                }
                if (event.target.value === "3") {
                    setInpuProcessInportRadio("3");
                }
                break;               
        }
    };

    // ファイル選択
    const handleFileSelect = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '*/*';
        input.onchange = (event) => {
            const selectedFile = (event.target as HTMLInputElement).files?.[0];
            if (selectedFile) {
                setInputFiles(selectedFile);
                setInputFileName(selectedFile.name);
            }
        };
        input.click();
    };

    // データベースパック
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                break;
        }
    };

    // TSV形式インポートAPI
    const {processTsvImport, processTsvImportError, processTsvImportLoading} = useProcessTsvImport()

    const handleClickOK = async () => {
        if(!inputFiles || !inputDatabasePackId) {
            return
        }
        try {
            if(inpuProcessInportRadio === "1"){
                // TSV形式
                await processTsvImport(inputDatabasePackId, inputFiles)
            }
            if(inpuProcessInportRadio === "2"){
                // クラウド版MiLCA形式
            }
            if(inpuProcessInportRadio === "3"){
                 // EcoSpold2形式
            }
            setInputFileName("")
            setInputFiles(null)
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <LinkMenu onClick={openModal}>
                {t('インポート')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('プロセスのインポート')}</Title>
                    {processTsvImportLoading && 
                        <FadeLoader
                            color="#48bdbb"
                            height={10}
                            radius={2}
                            width={5}
                        />
                    }
                    {!processTsvImportLoading && 
                        <SectionBody>
                            <SectionContent>
                                <DatabasePack>
                                    <DatabasePackLabel>
                                        <DatabasePackText>{t('データベースパック')}</DatabasePackText>
                                    </DatabasePackLabel>
                                </DatabasePack>
                            </SectionContent>

                            <SectionContent>
                                <SelectPrimary>
                                    <SelectInner
                                        name="database-pack"
                                        id="database-pack-select"
                                        onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                        value={inputDatabasePackId ?? ''}
                                    >
                                        {databasePacks.map((database_pack) => (
                                            <option key={database_pack.id} value={database_pack.id}>
                                                {database_pack.name}
                                            </option>
                                        ))}
                                    </SelectInner>
                                </SelectPrimary>
                            </SectionContent>

                            <SectionContent>
                                <Radio>
                                    <RadioLabel>
                                        <RadioInput
                                            type="radio"
                                            name="radio2"
                                            id="1"
                                            value="1"
                                            checked={inpuProcessInportRadio === "1"}
                                            onChange={(event) => handleInputChange(event, "process_format")}
                                        />
                                        <RadioText>{t('TSV形式')}</RadioText>
                                    </RadioLabel>
                                    {/* <RadioLabel>
                                        <RadioInput
                                            type="radio"
                                            name="radio1"
                                            id="2"
                                            value="2"
                                            checked={inpuProcessInportRadio === "2"}
                                            onChange={(event) => handleInputChange(event, "process_format")}
                                        />
                                        <RadioText>{t('クラウド版MiLCA形式')}</RadioText>
                                    </RadioLabel> */}
                                    {/* <RadioLabel>
                                        <RadioInput
                                            type="radio"
                                            name="radio3"
                                            id="3"
                                            value="3"
                                            checked={inpuProcessInportRadio === "3"}
                                            onChange={(event) => handleInputChange(event, "process_format")}
                                        />
                                        <RadioText>{t('EcoSpold2形式')}</RadioText>
                                    </RadioLabel> */}
                                </Radio>
                            </SectionContent>
                            <SectionContent>
                                <InputPrimary
                                        type="text"
                                        value={inputFileName ?? ''}
                                        disabled
                                />                       

                                <StyledButton onClick={handleFileSelect} >
                                    {t('ファイルを選択')}
                                </StyledButton>
                                <input 
                                    ref={inputFileRef} 
                                    style={{ display: 'none' }}
                                    type="file"
                                />                            
                            </SectionContent>
                        </SectionBody>
                    }
                    {processTsvImportError && <ErrorMsg>{processTsvImportError}</ErrorMsg>}
                    <ButtonCreateSectionTabel>
                        <ModalButton onClick={handleClickOK} text="OK" />
                    </ButtonCreateSectionTabel>                    
                </Inner>
            </Modal>
        </>
    );
};

export default ProcessImportModal;


const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`
const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionBody = styled.div`
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`

const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const DatabasePack = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px;
`

const DatabasePackLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
  
`

const DatabasePackText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`
import { GetProcessType, SubsystemIODefinedProductOut } from '@typeList/types';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { useGetProcessForProcessView } from '@hooks/useBackendApi';
import { CaseStudyViewContext, FlowState } from '@pages/case_study/CaseStudyView';
import { Edge, MarkerType } from 'reactflow';

import iconArrowTop from '@images/product_system/icon_arrow_top_right.svg'
import iconRemainder from '@images/table/icon_remainder.svg'
import { useTranslation } from 'react-i18next';


interface UpperOutputUpstreamSubsystemProps {
  input: SubsystemIODefinedProductOut;
  currentNodeData: any;
  nodeBoxRef: React.MutableRefObject<HTMLDivElement | null>;
  type: String;
}


/**
 * サブシステム上流をサブシステム化
 * 上流を別タブ展開
 * 
 * @param param0 
 * @returns 
 */
const UpperOutputUpstreamSubsystem: React.FC<UpperOutputUpstreamSubsystemProps> = ({ input, currentNodeData, nodeBoxRef, type }) => {
  const {t} = useTranslation();
  const { setNodes, edges, setEdges, setFlowState, flowState, subsystemCategorySetData, userData } = useContext(CaseStudyViewContext);
  const [nodePosition, setNodePosition] = useState({ x: 0, y: 0 });
  // undo、redo用の変更監視
  const updateFlowState = useCallback((updater: (prev: FlowState) => FlowState) => {
    setFlowState((prev) => {
      const newState = updater(prev);
      return { ...newState };
    });
  }, [setFlowState]);


  // カテゴリ
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<Array<number>>([]);
  useEffect(() => {
    if (subsystemCategorySetData && subsystemCategorySetData.length > 0) {
        // subsystemCategorySetDataの各categorySetから最初のsubsystem_categoriesのIDを取得
        const initialIds = subsystemCategorySetData
            .map((categorySet) => categorySet.subsystem_categories[0]?.id)
            .filter((id): id is number => id !== undefined); // undefinedを除外
        if (initialIds.length > 0) {
            setSelectedCategoryIds(initialIds);
            // setSelectedCategoryId(initialIds[0]);
        }
    }
}, [subsystemCategorySetData]);

  // 新規ノード追加
  const addNode = useCallback((
    input: SubsystemIODefinedProductOut,
    process: GetProcessType,
    x: number,
    y: number,
  ) => {
    const maxId = flowState.nodes ? Math.max(...flowState.nodes.map((node: any) => node.id), 0) : 0;

    let maxProcessIoNo = Math.max(
      ...flowState.nodes.flatMap(node => node.data.subsystem_ios.map((io: any) => io.process_io_no)),
      0
    );
    maxProcessIoNo++

    let maxCaseStudyProductNo = Math.max(
      ...flowState.nodes.flatMap(node =>
        node.data.subsystem_ios
          .map((io: any) => io.exchange?.case_study_product_no)
          .filter((no: number | undefined | null): no is number => no !== undefined && no !== null)
      ),
      0
    );

    const position = { x, y };
    const subsystemIos = process.process_ios.map((ios: any) => {
      if (ios.exchange.type === "product" && ios.direction === "out") {
        // ケーススタディ製品に変換
        return {
          locale: ios.locale,
          compatible_product_name: ios.compatible_product_name,
          public_comment: ios.public_comment,
          private_comment: ios.private_comment,
          information_sources: ios.information_sources,
          amount_comment: ios.amount_comment,
          process_io_no: maxProcessIoNo++,
          formula: ios.formula,
          amount: ios.amount,
          direction: ios.direction,
          compatibility_level: ios.compatibility_level,
          // id: maxProcessIoNo++,
          id: null,
          global_id: ios.global_id,
          unit: {
            ...ios.unit,  // 元のunitの内容を展開
            id: ios.exchange.unit.id,  // idをios.exchange.unit_idで設定
          },
          type: "case_study_product",
          case_study_product_no: maxCaseStudyProductNo++,
          // exchange: ios.exchange,
          exchange: {
            locale: ios.exchange.locale,
            name: ios.exchange.name,
            synonyms_text: ios.exchange.synonyms_text,
            include_items_text: ios.exchange.include_items_text,
            related_items_text: ios.exchange.related_items_text,
            public_comment: ios.exchange.public_comment,
            private_comment: ios.exchange.private_comment,
            id: 0, // 自動採番
            unit_id: ios.exchange.unit.id,
            case_study_product_no: maxCaseStudyProductNo++,
          },
          // upper_subsystem_output_no: ios.upper_output?.id
          upper_subsystem_output_no: 0,
        }
      }
      if (ios.exchange.type === "product" && ios.direction === "in") {
        return {
          locale: ios.locale,
          compatible_product_name: ios.compatible_product_name,
          public_comment: ios.public_comment,
          private_comment: ios.private_comment,
          information_sources: ios.information_sources,
          amount_comment: ios.amount_comment,
          process_io_no: maxProcessIoNo++,
          formula: ios.formula,
          amount: ios.amount,
          direction: ios.direction,
          compatibility_level: ios.compatibility_level,
          // id: maxProcessIoNo++,
          id: null,
          global_id: ios.global_id,
          unit: ios.unit,
          type: "defined_product",
          exchange: ios.exchange,
          upper_process_io_id: ios.upper_output?.id
        }
      }
      if (ios.exchange.type === "elementary_flow") {
        return {
          locale: ios.locale,
          compatible_product_name: ios.compatible_product_name,
          public_comment: ios.public_comment,
          private_comment: ios.private_comment,
          information_sources: ios.information_sources,
          amount_comment: ios.amount_comment,
          process_io_no: maxProcessIoNo++,
          formula: ios.formula,
          amount: ios.amount,
          direction: ios.direction,
          compatibility_level: ios.compatibility_level,
          // id: maxProcessIoNo++,
          id: null,
          global_id: ios.global_id,
          unit: ios.unit,
          type: "elementary_flow",
          exchange: ios.exchange,
        }
      }
      return {};
    })

    const newNode = {
      id: (maxId + 1).toString(),
      position,
      data: {
        name: input.upper_output?.product_name,
        criteria_for_excluding_intermediate_flow: "",
        id: (maxId + 1).toString(),
        locale: process?.locale,
        private_comment: process.private_comment,
        public_comment: process.public_comment,
        sampling_procedure: "",
        subsystem_category_ids: selectedCategoryIds,
        subsystem_ios: subsystemIos,
        subsystem_no: maxId + 1,
      },
      type: 'SUBSYSTEM',
      width: 212,
      height: 165,
    };

    // 新しいノードをsetNodesで追加
    const newNodeIo = newNode.data?.subsystem_ios.filter(
      (io: any) =>
        io.exchange.name === input.exchange.name && io.direction === 'out'
    ) || [];
    const newEdgeId = `e${newNode.id}-${currentNodeData.id}-${newNodeIo[0]?.process_io_no}-${input.process_io_no}`;
    const newEdge: Edge = {
      id: newEdgeId,
      source: String(newNode.id),
      target: String(currentNodeData.id),
      sourceHandle: `output-${newNodeIo[0]?.process_io_no}`,
      targetHandle: `input-${input.process_io_no}`,
      style: { stroke: '#2b2b2b', strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#2b2b2b',
      },
    };
    // 新しいedgeをsetEdgesで追加
    updateFlowState((prev) => ({
      ...prev,
      nodes: prev.nodes.concat(newNode),
      edges: [...prev.edges, newEdge],
    }));
    setEdges(prevEdges => [...prevEdges, newEdge]);    
    setNodes((prevNodes) => prevNodes.concat(newNode));
  }, [flowState.nodes, updateFlowState, setNodes]);


  const [upperOutputContextMenuVisible, setUpperOutputContextMenuVisible] = useState(false);
  const upperOutputContextMenuRef = useRef<HTMLDivElement | null>(null);
  const [upperOutputContextMenuPosition, setUpperOutputContextMenuPosition] = useState<{ top: number; left: number } | null>(null)

  const { getProcessForProcessView } = useGetProcessForProcessView();
  const upperOutputUpstream = async (input: SubsystemIODefinedProductOut) => {
    try {
      const process = await getProcessForProcessView(input.upper_output?.process_id);
      if(process) {
        addNode(input, process, nodePosition.x, nodePosition.y);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const toggleUpperOutputContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    // ボタンの位置を基にしてメニューの位置を設定
    const parentRect = button.parentElement!.getBoundingClientRect(); // 親要素の位置とサイズを取得 

    // ボタンの位置を基にしてメニューの位置を設定
    if(type === "node"){
      setUpperOutputContextMenuPosition({
        // top: ((rect.bottom - parentRect.top) / parentRect.height) * 100, // ボタンの下に表示
        top:  Math.round((rect.bottom - rect.top + 200) / 10),
        left: 0,  // ボタンの左に表示
      });
    }
    if(type === "list"){
      const scrollContainer = document.querySelector('.dialog-scroll-container');
      console.log("rect.bottom", rect.bottom)
      console.log("rect.top", rect.top)
      setUpperOutputContextMenuPosition({
        top:  Math.round((rect.bottom- 10)),
        left: 140, // 左位置もスクロール量を考慮
      });
    }
    setUpperOutputContextMenuVisible(prev => !prev)
  };

  useEffect(() => {
    // handleClickOutside関数をuseEffect内で定義する
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      // NodeBoxの外側をクリックした場合にメニューを閉じる
      if (
        !nodeBoxRef.current?.contains(target)
      ) {
        setUpperOutputContextMenuVisible(false);
      }
    };

    // NodeBoxごとに個別のリスナーを追加
    document.addEventListener('click', handleClickOutside);

    // クリーンアップ関数としてリスナーを削除
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [nodeBoxRef, upperOutputContextMenuVisible]);

  // 上流を別タブで開く
  const OpenUpperProcess = async() =>{
    if (input.upper_output?.process_id) {
      window.open(`/process/process_view/${input.upper_output.process_id}`, '_blank');
    }
  }

  useEffect(() => {
    if (type === "list") {
      // スクロール時にメニューを閉じるためのハンドラーを定義
      const handleScroll = () => {
        setUpperOutputContextMenuVisible(false);
      };
  
      const scrollContainer = document.querySelector('.dialog-scroll-container');
      if (scrollContainer) {
        // スクロールイベントリスナーを追加
        scrollContainer.addEventListener('scroll', handleScroll);
      }
  
      return () => {
        // クリーンアップ: スクロールイベントリスナーを削除
        if (scrollContainer) {
          scrollContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [type]);

  return (
    <>
        {input.upper_output?.id ? (
        <NodeBoxDlButton
          type='button'
          onClick={toggleUpperOutputContextMenu}
        >
          <NodeBoxDirectionTopButtonIcon />
        </NodeBoxDlButton>
      ) : (
        <NodeBoxDlButton
          type='button'
        >
          <NodeBoxReminderFlowButtonIcon />
        </NodeBoxDlButton>
        )}
      {upperOutputContextMenuVisible && upperOutputContextMenuPosition &&
        <UpperOutputContextMenu 
          ref={upperOutputContextMenuRef} 
          top={upperOutputContextMenuPosition.top} 
          left={upperOutputContextMenuPosition.left}
        >
          <ContextMenuItem onClick={() => {
            // setOutputElementaryFlowSearchModalVisible(true); 
            OpenUpperProcess();
          }}>
            {t('上流のDB登録済みプロセスを別タブで開く')}
          </ContextMenuItem>
          {userData?.is_admin &&
          <ContextMenuItem onClick={() => {
            upperOutputUpstream(input);
            setUpperOutputContextMenuVisible(false);
          }}>
            [SystemTest]{t('上流をサブシステム化する')}
          </ContextMenuItem>
          }
        </UpperOutputContextMenu>
      }
    </>
  );
};

export default UpperOutputUpstreamSubsystem;

const NodeBoxDlButton = styled.button`
  white-space: nowrap;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
`

const NodeBoxDirectionTopButtonIcon = styled.span`
  mask: url(${iconArrowTop}) no-repeat center center / contain;
  -webkit-mask: url(${iconArrowTop}) no-repeat center center / contain;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--color-txt-primary);
`

const NodeBoxReminderFlowButtonIcon = styled.span`
  mask: url(${iconRemainder}) no-repeat center center / contain;
  -webkit-mask: url(${iconRemainder}) no-repeat center center / contain;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--color-txt-primary);
`

const ContextMenuItem = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`
const UpperOutputContextMenu = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  white-space: nowrap;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1;
`;
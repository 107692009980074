import React, { useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import styled from 'styled-components';
import { LinkMenu } from '@styles/Common';

import DataTable from '@common/table/DataTable';
import { createColumnHelper } from '@tanstack/react-table'
import { GetElementaryFlowsList, GetUserDetail, SearchElementaryFlows } from '@typeList/types';
import { useSearchElementaryFlows } from '@hooks/useBackendApi';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TablePageNation from '@common/pagenation/TablePageNation';
import ElementaryFlowsSearchTable from '@common/table/ElementaryFlowsSearchTable';
import { getCurrentDefaultDatabasePackId } from '@services/Cookies';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';

interface ElementaryFlowsModalProps {
    userData: GetUserDetail | null
}

/**
 * 001　基本フローマスタ
 * @returns 
 */
const ElementaryFlowsModal: React.FC<ElementaryFlowsModalProps> = ({ userData }) => {
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm<SearchElementaryFlows>();

    const [data, setData] = useState<any | null>(null);
    const [dataCount, setDataCount] = useState<number>(0);

    // データベースパック
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    // const [inputDatabasePackName, setInputDatabasePackName] = useState("")
    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            // デフォルト設定のデータベースパック取得
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
        }
    }, [userData]);

    useEffect(() => {
        if(getCurrentDefaultDatabasePackId()){
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    // ラジオボタン
    const [inputFulltextSearch, setInputFulltextSearch] = useState<string>("1")
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case 'is_fulltext_search':
                setInputFulltextSearch(event.target.value);
                break;
        }
    };

    // モーダル開閉
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // テーブル設定
    const columnHelper = createColumnHelper<GetElementaryFlowsList>()
    const columns = [
        columnHelper.accessor('name', {
            header: () => t('名前'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('synonyms_text', {
            header: () => t('同義語'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('include_items_text', {
            header: () => t('包含品目'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('related_items_text', {
            header: () => t('関連項目'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('unit.code', {
            header: () => t('単位'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('cas_number', {
            header: () => t('CAS Number'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category1_name', {
            header: () => t('カテゴリ1'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category2_name', {
            header: () => t('カテゴリ2'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('category3_name', {
            header: () => t('カテゴリ3'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('public_comment', {
            header: () => t('説明'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('identifier_scheme_values', {
            header: () => t('参照数(入力／出力)'),
            cell: info => '',
        }),
        columnHelper.accessor('global_id', {
            header: () => t('UUID'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor(row => {
            // identifier_scheme_values が配列であれば、その中から name: value の形に変換し、改行で結合
            return row.identifier_scheme_values
                .map(scheme => `${scheme.identifier_scheme.name} : ${scheme.value}`)
                .join('\n');  // 改行で結合
        }, {
            id: 'identifier_scheme_values',  // 必須の id プロパティを追加
            header: () => t('外部ID'),
            cell: info => {
                // 改行を反映させるために <pre> タグを使用
                return <pre>{info.getValue()}</pre>;
            },
        }),
    ]

    // 検索ボタン
    const { searchElementaryFlows, searchElementaryFlowsData, searchElementaryFlowsError, searchElementaryFlowsLoading } = useSearchElementaryFlows();
    // ページング
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if(searchElementaryFlowsLoading){
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    },[searchElementaryFlowsLoading])

    const onSubmit: SubmitHandler<SearchElementaryFlows> = async data => {
        if (isDisabled) return;
        
        let IsFullTextSearch;
        if (inputFulltextSearch === "1") {
            IsFullTextSearch = false;
        } else {
            IsFullTextSearch = true;
        }

        try {
            const result = await searchElementaryFlows(
                inputDatabasePackId,
                data.search_text,
                page,
                pageSize,
                null,
                null,
                IsFullTextSearch,
                false
            )
            setData(result.results);
            setDataCount(result.count);
        } catch (error) {
            console.log(error);
        }
    };

    // API呼び出し
    useEffect(() => {
        if (inputDatabasePackId && isModalOpen) {
            handleSubmit(onSubmit)();
        }
    }, [page])

    // 表示切替時はページをリセット
    useEffect(() => {
        if (inputDatabasePackId && page === 1 && isModalOpen) {
            handleSubmit(onSubmit)();
        }
        setPage(1)
    }, [pageSize])

    // プルダウン変更
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        setInputDatabasePackId(Number(value));
    };

    return (
        <div className="app">
            <LinkMenu onClick={() => {
                openModal();
            }
            }>
                {t('基本フローマスタ')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('基本フロー（資源・排出物）の閲覧/検索')}</Title>
                    {searchElementaryFlowsError && <ErrorMsg>{searchElementaryFlowsError}</ErrorMsg>}
                    <SectionBody>
                        <SectionColumn>
                            <SectionTitle>{t('データベースパック')}</SectionTitle>
                            <form>
                                {databasePacks.length > 0 &&
                                    <>
                                        <SectionContent>
                                            <SelectPrimary>
                                                <SelectInner
                                                    id="database-pack-select"
                                                    value={inputDatabasePackId ?? ''}
                                                    onChange={(event) => handleInputChangeSelect(event)}
                                                >
                                                    {databasePacks.map((database_pack, index) => (
                                                        <option key={index} value={database_pack.id}>
                                                            {database_pack.name}
                                                        </option>
                                                    ))}
                                                </SelectInner>
                                            </SelectPrimary>
                                        </SectionContent>

                                    </>
                                }
                                <SectionTitle>{t('キーワードで検索')}</SectionTitle>
                                <SectionContent>

                                    <InputPrimary type="search" placeholder={t('キーワードを入力')} {...register("search_text")} />
                                    <StyledButton type='submit' onClick={handleSubmit(onSubmit)} disabled={isDisabled}>{t('検索')}</StyledButton>
                                </SectionContent>
                                <SectionContent>

                                    <Radio>
                                        <RadioLabel>
                                            <RadioInput
                                                type="radio"
                                                key="fulltext_search_off"
                                                value='1'
                                                checked={inputFulltextSearch === "1"}
                                                onChange={(event) => handleInputChange(event, 'is_fulltext_search')}
                                            />
                                            <RadioText>{t('基本フロー名で検索')}</RadioText>
                                        </RadioLabel>
                                        <RadioLabel>
                                            <RadioInput
                                                type="radio"
                                                key="fulltext_search_on"
                                                value='2'
                                                checked={inputFulltextSearch === "2"}
                                                onChange={(event) => handleInputChange(event, 'is_fulltext_search')}
                                            />
                                            <RadioText>{t('基本フロー情報に含まれる文言で検索')}</RadioText>
                                        </RadioLabel>
                                    </Radio>
                                </SectionContent>
                            </form>
                        </SectionColumn>

                    </SectionBody>
                    <SectionTable>
                        {searchElementaryFlowsLoading &&
                            <FadeLoader
                                color="#48bdbb"
                                height={10}
                                radius={2}
                                width={5}
                            />
                        }
                        {data && !searchElementaryFlowsLoading &&
                            <>
                                <TablePageNation
                                    count={searchElementaryFlowsData?.count || 0}
                                    page={page}
                                    setPage={setPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                />
                                <ElementaryFlowsSearchTable
                                    columns={columns}
                                    data={data}
                                />
                            </>
                        }
                    </SectionTable>
                </Inner>
            </Modal>
        </div>
    );
};

export default ElementaryFlowsModal;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`



const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionTable = styled.div`
    background-color: #fff;
    padding: 40px 20px 55px;
  
`

const SectionTitle = styled.h2`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionBody = styled.div`
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`


const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`



const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const SectionColumn = styled.dl`
    margin-bottom: 40px;
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;   
  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;
  }
`

const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`


const CheckBoxLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
  
`
const CheckBoxText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const CheckBoxInput = styled.input`
    accent-color: var(--color-site-secondary);
`
import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { useGetCategoriesList } from '@hooks/useBackendApi';
import CategoryValueSelectModal from '../category_select/CategoryValueSelectModal';
import { productCreateContext } from './ProductCreateModal';

import iconDelete from '@images/table/icon_delete.svg'
import iconAdd from '@images/product_system/icon_add.svg'
import { useTranslation } from 'react-i18next';

/**
 * 製品カテゴリ選択コンポーネント
 * @returns 
 */
const ProductCategorySelect = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const context = useContext(productCreateContext);
    const {
        inputDatabasePackId,
        categoryIds,
        ids,
        setIds,
        setCategoryIds,
    } = context;


    const {
        categoriesListData,
        categoriesListError,
        categoriesListLoading
    } = useGetCategoriesList();

    // 初期状態で1つの行を持つrowsステート
    const [rows, setRows] = useState([{ id: Date.now(), inputCategoryId: 1, inputCategoryName: categoriesListData && categoriesListData[0] ? categoriesListData[0].name : "", selectedCategoryValue: '', selectedCategoryValueId: 0 }]);
    useEffect(() => {
        setRows([{ id: Date.now(), inputCategoryId: 1, inputCategoryName: categoriesListData && categoriesListData[0] ? categoriesListData[0].name : "", selectedCategoryValue: '', selectedCategoryValueId: 0 }])
    }, [categoriesListData])

    // categoriesListDataが更新されたときにカテゴリIDをセット
    useEffect(() => {
        if (categoriesListData) {
            setCategoryIds(categoriesListData.map(category => ({ id: category.id, name: category.name })));
        }
    }, [categoriesListData]);

    // useImperativeHandleで外部からカテゴリ値IDを取得する関数を定義
    useImperativeHandle(ref, () => ({
        getCategoryValueIds: () => rows.map(row => String(row.selectedCategoryValueId))
    }));

    // セレクトボックスの選択が変更されたときのハンドラー
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, rowId: number) => {
        const selectedCategoryId = Number(event.target.value);
        const selectedCategory = categoriesListData?.find(category => category.id === selectedCategoryId);
        const newRows = rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    inputCategoryId: selectedCategoryId,
                    inputCategoName: selectedCategory ? selectedCategory.name : ''
                };
            }
            return row;
        });
        setRows(newRows);
    };

    // 行の削除ハンドラー
    const handleDelete = (rowId: number) => {
        const rowToDelete = rows.find(row => row.id === rowId);
        if (rowToDelete) {
            setIds((prevIds) => prevIds?.filter(id => id !== String(rowToDelete.selectedCategoryValueId)) ?? []);
        }
        setRows(rows.filter(row => row.id !== rowId));
    };

    // 行の追加ハンドラー
    const handleAddRow = () => {
        // デフォルトカテゴリの選択
        const defaultCategory = categoriesListData && categoriesListData[0];
        const newRow = {
            id: Date.now(),
            inputCategoryId: defaultCategory ? defaultCategory.id : 0,  // デフォルトのカテゴリID
            inputCategoryName: defaultCategory ? defaultCategory.name : '',
            selectedCategoryValue: '',
            selectedCategoryValueId: 0
        };

        setRows([...rows, newRow]);
        // setRows([...rows, { id: Date.now(), inputCategoryId: 1, selectedCategoryValue: '', selectedCategoryValueId: 0 }]);
    };

    // カテゴリ値選択ハンドラー
    const handleSelectCategoryValue = (rowId: number, categoryValueId: number, categoryValueName: string) => {
        // 行に対応するidを更新または追加
        setIds((prevIds) => {
            const newIds = [...(prevIds ?? [])];
            const index = newIds.findIndex(id => id === String(rowId));
            if (index !== -1) {
                newIds[index] = String(categoryValueId);
            } else {
                newIds.push(String(categoryValueId));
            }
            return newIds;
        });

        // 選択したカテゴリ値の更新
        const newRows = rows.map(row =>
            row.id === rowId ? { ...row, selectedCategoryValue: categoryValueName, selectedCategoryValueId: categoryValueId } : row
        );
        setRows(newRows);
    };

    return (
        <>
            <Dd >
                {rows.map(row => (
                    <React.Fragment key={row.id}>
                        <Label key={row.id}>
                            <Select
                                name="category"
                                id={`category-select-${row.id}`}
                                onChange={(event) => handleInputChangeSelect(event, row.id)}
                                value={row.inputCategoryId ?? ''}
                            >
                                {categoryIds?.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Select>
                        </Label>

                        <Input
                            type='text'
                            hidden
                            value={row.inputCategoryId}
                            id={`value-id-${row.inputCategoryId}`}
                            key={`value-id-${row.id}`}
                        />
                        <Input
                            type='text'
                            disabled
                            value={row.selectedCategoryValue}
                            key={`value-select-${row.id}-input`}
                        />

                        <CategoryValueSelectModal
                            key={`value-select-${row.id}-modal`}
                            rowId={row.id}
                            rowName={row.inputCategoryName}
                            categoryId={row.inputCategoryId}
                            onSelectCategoryValue={(categoryValueId, categoryValueName) => handleSelectCategoryValue(row.id, categoryValueId, categoryValueName)}
                        />

                        <IconButton type='button' onClick={() => handleDelete(row.id)}>
                            <Icon>
                            </Icon>
                        </IconButton>
                    </React.Fragment>
                ))}
                <AddWrapper>
                    <AddButton type='button' onClick={handleAddRow}>
                        <AddButtonIconWrapper>
                            <AddButtonIcon></AddButtonIcon>
                        </AddButtonIconWrapper>
                        <AddButtonInner>{t('製品カテゴリを追加する')}</AddButtonInner>
                    </AddButton>
                </AddWrapper>
            </Dd>
        </>
    );
});

export default ProductCategorySelect;


const Label = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`

const Select = styled.select`
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
// appearance: none;
`

const Input = styled.input`
width: min(100%, 340px);
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const Dd = styled.dd`
display: grid;
gap: 8px 12px;
grid-template-columns: 2fr 2fr 1fr auto;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
`

const IconButton = styled.button`
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const Icon = styled.span`
mask: url(${iconDelete}) no-repeat center center / contain;
-webkit-mask: url(${iconDelete}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`

const AddWrapper = styled.div`
margin-top: 20px;
grid-column: 1 / 5;
width: fit-content;
align-content: end;
margin: 0 0 0 auto;
`

const AddButton = styled.button`
padding: 6px 18px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    span {
        color: #fff;
    }
}
`

const AddButtonInner = styled.span`
font-size: 12px;
font-weight: 500;
`

const AddButtonIconWrapper = styled.div`
width: 16px;
height: 16px;
border: none;
background-color: var(--color-site-secondary);
display: grid;
place-content: center;
border-radius: 50%;
`

const AddButtonIcon = styled.span`
width: 7.44px;
height: 7.44px;
background-color: #fff;
position: relative;
mask: url(${iconAdd}) no-repeat center center / contain;
-webkit-mask: url(${iconAdd}) no-repeat center center / contain;
display: block;
`
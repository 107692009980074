import React, { useState, useCallback, useRef, ChangeEvent, useContext, useEffect } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    createColumnHelper,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { AllocationOut, AlternativeOut, Cutoff, DisposalOut, UpdateProcess } from '@typeList/types';
import DisposalSelectBox from '@specific/process/process_allocation/DisposalSelectBox';

import iconArrowDown from '@images/table/icon_arrow_down.svg'
import iconArrowUp from '@images/table/icon_arrow_up.svg'
import InputIntermediateFlowProductSearchModal from '@specific/process/product_search/input_Intermediate_flow/InputIntermediateFlowProductSearchModal';
import { useForm } from 'react-hook-form';
import { useGetProcessForProcessView } from '@hooks/useBackendApi';
import SelectedAlternativeInfo from '@specific/process/process_allocation/SelectedAlternativeInfo';

const initialPageIndex = 0;
const initialPageSize = 50;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <StyledTh ref={ref} onClick={header.column.getToggleSortingHandler()}>
            <StyledThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown/> : <IconArrowUp/>) : ''}
            </StyledThInner>
        </StyledTh>
    );
};


const ProsessAllocationInputDataTable = () => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const { outProduct, setOutProduct, setChangeCheckFlow } = context;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const previousTreatmentTypesRef = useRef<{ [key: number]: string }>({});

    const allocationList = [
        { value: "allocation", label: t('配分') },
        { value: "disposal", label: t('廃棄') },
        // { value: "alternative", label: t('代替') },
        { value: "cutoff", label: t('カットオフ') },
    ];

    // 型ガード関数
	const isAllocationOut = (treatment: AllocationOut | Cutoff | DisposalOut | AlternativeOut | null): treatment is AllocationOut => {
		return treatment !== null && treatment.type === 'allocation';
	}

    // 更新フラグ
    const handleChange = async(event: ChangeEvent<HTMLSelectElement>, rowIndex: number) => {
        const newValue = event.target.value;
        if (outProduct) {
            const updatedOutProduct = [...outProduct];
            let treatment = updatedOutProduct[rowIndex]?.treatment;
            if (!treatment) {
                treatment = { type: newValue, allocation_rate: 1 };
            } else {
                treatment.type = newValue;
                if(isAllocationOut(treatment)){
                    treatment.allocation_rate = 1;
                }
            }
            updatedOutProduct[rowIndex].treatment = treatment;
            await setOutProduct(updatedOutProduct);

            previousTreatmentTypesRef.current[rowIndex] = newValue;

            setChangeCheckFlow(true);
        }
    };

    const handleProductSelect = (product: { id: number, name: string }, rowIndex: number) => {
        if (outProduct) {
            const updatedOutProduct = [...outProduct];
            if (updatedOutProduct[rowIndex].treatment?.type === 'alternative') {
                const treatment = updatedOutProduct[rowIndex].treatment as any;
                treatment.alternative_process_input = {
                    upper_process_io_id: product.id,
                    upper_process_name: product.name,
                };
                setOutProduct(updatedOutProduct);
            }
        }
    };

    useEffect(() => {
        if (outProduct) {
            outProduct.forEach((product, index) => {
                previousTreatmentTypesRef.current[index] = product.treatment?.type ?? '';
            });
        }
    }, [outProduct]);

    const columnHelper = createColumnHelper<any>();
    const initialColumns = [
        columnHelper.accessor('exchange.name', {
            header: () => t('出力製品名'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('treatment.type', {
            header: () => t('種別'),
            cell: info => {
                const value = info.renderValue() ?? '';
                const rowIndex = info.row.index;
                return (
                    <span>
                        <SelectLabel>
                            <Select
                                id="allocation"
                                value={outProduct ? outProduct[rowIndex]?.treatment?.type ?? '' : ''}
                                onChange={(event) => handleChange(event, rowIndex)}
                            >
                                {allocationList.map(item => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </SelectLabel>
                    </span>
                );
            },
        }),
        columnHelper.accessor('additionalInfo', {
            header: () => t('追加情報'),
            cell: info => {
                const rowIndex = info.row.index;
                const typeValue = info.row.original.treatment?.type ?? '';
                const exchangeId = info.row.original.exchange.id;
                return (
                    <>
                        {typeValue === 'disposal' && (
                            <Wrap>
                                <DisposalSelectBox exchangeId={exchangeId} rowIndex={rowIndex} />
                                <InputIntermediateFlowProductSearchModal type={"disposal"}/>
                            </Wrap>
                        )}
                        {typeValue === 'alternative' && (
                            <Wrap>
                                <SelectedAlternativeInfo
                                    isModalOpen={isModalOpen}
                                    exchangeId={exchangeId}
                                    rowIndex={rowIndex}
                                    setIsModalOpen={setIsModalOpen}
                                    onProductSelect={handleProductSelect}
                                />
                            </Wrap>
                        )}
                    </>
                );
            },
        }),
    ];

    const [columns, setColumns] = useState(initialColumns);
    const [sorting, setSorting] = useState<SortingState>([]);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: outProduct ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <TableWrapper>
                <StyledTable>
                <colgroup>
                    <col width="304px" />

                    <col width="300px" />

                    <col width="703px" />
                </colgroup>
                    <StyledThead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <StyledThTr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader
                                        key={header.id}
                                        header={header}
                                        index={index}
                                        moveColumn={moveColumn}
                                    />
                                ))}
                            </StyledThTr>
                        ))}
                    </StyledThead>
                    <StyledTbody>
                        {table.getRowModel().rows.map(row => (
                            <StyledTr key={row.id} $isEven={row.index % 2 === 0}>
                                {row.getVisibleCells().map(cell => (
                                    <StyledTd key={cell.id}>
                                        {/* <StyledTdText> */}
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        {/* </StyledTdText> */}
                                    </StyledTd>
                                ))}
                            </StyledTr>
                        ))}
                    </StyledTbody>
                </StyledTable>
            <div className="h-4" />
        </TableWrapper>
    );
};

export default ProsessAllocationInputDataTable;

const TableWrapper = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
width: 100%;
margin: 0 auto 32px;
min-width: 100%;
border-collapse: collapse;
background-color: #fff;
border-spacing: 0;
`;

const StyledThead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTbody = styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const StyledTh = styled.th`
cursor: grab;

&:active {
cursor: grabbing;
}
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
}
`;

const StyledThInner = styled.div`
width: fit-content;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`

const StyledTd = styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
}
`;

const StyledTdText = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
text-align: left;
`


const StyledThTr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const StyledTr = styled.tr<{ $isEven: boolean }>`
    border-top: 1px solid var(--color-line-primary);
    border-bottom: 1px solid var(--color-line-primary);
    ${(props) => props.$isEven && css`
        background-color: #f9f9f9;
    `}
`;

const PaginationWrapper = styled.div`
    margin: 5px;
`;

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`
const ButtonInner = styled.div``;

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`
const Select = styled.select`
color: rgb(85, 85, 85);
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
font: inherit;
outline: none;
`

const Wrap = styled.div`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 24px;
`;
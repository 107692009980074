import React, { useContext, useState } from 'react'
import Modal from '@common/modal/Modal'
import styled, { css } from 'styled-components';
import ProductCreateModal from '../../product_create/ProductCreateModal';
import InputIntermediateFlowProductSearch from './InputIntermediateFlowProductSearch';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { useTranslation } from 'react-i18next';
import ProductTextTreeCategories from '../ProductTextTreeCategories';

import AddButton from '@common/button/AddButton';
import ModalButton from '@common/button/ModalButton';

interface InputIntermediateFlowProductSearchModalProps {
  type: string;
}

/**
 * 製品（中間フロー）検索　入力中間フロー　
 * @returns 
 */
const InputIntermediateFlowProductSearchModal: React.FC<InputIntermediateFlowProductSearchModalProps> = ({ type }) => {
  const { t } = useTranslation();
  const { databasePacks } = useContext(ProcessViewContext)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // 選択されたタブを管理するステート
  const [activeTab, setActiveTab] = useState<string>('tab1');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      {type === "io_info" &&
        <SectionButtonAdd>
          <AddButton onClick={openModal} text={t('入力中間フロー（原料・エネルギー等）の追加')} />
        </SectionButtonAdd>
      }
      {type === "disposal" &&
        <>
          <ModalButton type='button' onClick={openModal} text={t('新規追加')} />
        </>
      }
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <Inner>
          <SectionResult>
            <SectionResultTitle>{t('検索結果')}</SectionResultTitle>
            {/* <ProductCreateModal selectted_database_pack_id={null} /> */}
          </SectionResult>

          <SectionBody>
            <SectionTab>
              {/* タブのボタン */}
              <TabButton type='button' onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
                <SectionResultTitleText>{t('検索して選ぶ')}</SectionResultTitleText>
              </TabButton>
              <TabButton type='button' onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
                <SectionResultTitleText>{t('カテゴリーツリーから選ぶ')}</SectionResultTitleText>
              </TabButton>


              {/* タブの内容 */}
              {activeTab === 'tab1' &&
                <SectionTabContent>
                  <InputIntermediateFlowProductSearch
                    selectted_database_pack_id={1}
                    setIsSearchModalOpen={setIsModalOpen}
                  />
                </SectionTabContent>
              }
              {activeTab === 'tab2' &&
                <SectionTabContent>
                  <ProductTextTreeCategories
                    type='select'
                    modal_type={"in"}
                    setIsSearchModalOpen={setIsModalOpen}
                    onSelectProduct={() => { }}
                  />
                </SectionTabContent>
              }
            </SectionTab>
          </SectionBody>
        </Inner>
      </Modal>
    </>
  )
}

export default InputIntermediateFlowProductSearchModal

const TabButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
    background-color:  var(--color-gray--01);
    padding: 7px 16px;  
    border-radius: 20px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--color-site-secondary);
      color: white;
    `
  }

  &:hover {
    background-color:  var(--color-gray--01);
    ${(props) =>
    props.isActive &&
    css`
        background-color: var(--color-site-secondary);
      `
  }
  }
`

const TabPanel = styled.div`
`

const CreateButton = styled.button`
    background-color: #46A3B3;
    color: white;
    border: none ;
    margin-bottom: 5px ;
    cursor: pointer;
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionBody = styled.div`

`

const SectionTab = styled.div`
    gap: 24px 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const SectionResultTitleText = styled.span`
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    white-space: nowrap;
`

const SectionTabContent = styled.div`
    display: block;
    z-index: 5;
    width: 100%;
    order: 2;
    position: relative;    
`

const SectionButtonAdd = styled.div`
    margin-top: 20px;
    text-align: right;
`

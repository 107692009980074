import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView'
import { UpdateCaseStudy } from '@typeList/types';
import React, { useContext, useEffect } from 'react'
import { Control, Controller, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import iconImg from '@images/file/icon_file_upload.svg'
import iconAdd from '@images/text_tree/icon_add.svg'
import iconCross from '@images/table/icon_cross.svg';

import ReviewLevelTypeTable from '@common/table/CaseStudyReviewLevelTypeTable';
import { createColumnHelper } from '@tanstack/react-table';
import SubsystemInputDataTable from '@common/table/SubsystemInputDataTable';
import DateInput from '@common/date_picker/DateInput';

interface FormProps {
    register: UseFormRegister<UpdateCaseStudy>;
    errors: FieldErrors<UpdateCaseStudy>;
    control: Control<UpdateCaseStudy, any>;
    watch: UseFormWatch<UpdateCaseStudy>;
}

/**
 * 042　ケーススタディ閲覧画面（報告）
 * @returns 
 */
const Report: React.FC<FormProps> = ({ watch, control, register, errors }) => {
    const context = useContext(CaseStudyViewContext);
    const { t } = useTranslation();
    const {
        userData,
        caseStudyData,
        reviewLevelTypeData,
        selectReviewLevelData,
        reviewLevelsDataList,
        setSelectReviewLevelData,
        setReviewLevelsDataList,
        // 画像
        previewImage,
        setPreviewImage,
        setImageFile,
        imageSrc,
        setImageSrc,
        imageName,
        setImageName,
        // システム境界
        previewSystemBoundaryImage,
        setPreviewSystemBoundaryImage,
        systemBoundaryimageSrc,
        setSystemBoundaryImageSrc,
        systemBoundaryimageName,
        setSystemBoundaryImageName,
        systemBoundaryimageFile,
        setSystemBoundaryImageFile,
        hasComparisonClaim,
        setHasComparisonClaim,
        flowState,
        setFlowState,
        setchangeCheckReport
    } = context;

    // 画像
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        console.log("file", file);
        if (file) {
            setImageName(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result as string);
                setImageFile(file);
            };
            reader.readAsDataURL(file);
            setchangeCheckReport(true);
        }
    };

    // 画像の削除ハンドラー
    const handleImageDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // デフォルト動作を防止
        event.stopPropagation(); // イベントの伝播を防止
        setImageSrc(null);
        setPreviewImage(null);
        setImageName(null);
        setImageFile(undefined);
        setchangeCheckReport(true);
        // ファイル入力フィールドをリセット
        const fileInput = document.getElementById('fileImg') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // ファイル選択のリセット
        }
    };

    // システム境界画像
    const handleSystemBoundaryImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSystemBoundaryImageName(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewSystemBoundaryImage(reader.result as string);
                setSystemBoundaryImageFile(file);
            };
            reader.readAsDataURL(file);
            setchangeCheckReport(true);
        }
    };

    // システム境界画像の削除ハンドラー
    const handleSystemBoundaryImageDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // デフォルト動作を防止
        event.stopPropagation(); // イベントの伝播を防止
        setSystemBoundaryImageSrc(null);
        setPreviewSystemBoundaryImage(null);
        setSystemBoundaryImageName(null);
        setSystemBoundaryImageFile(undefined);
        setchangeCheckReport(true);
        // ファイル入力フィールドをリセット
        const fileInput = document.getElementById('systemBoundaryImg') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // ファイル選択のリセット
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileImg')?.click();
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value === '0'; // valueが'0'ならtrue、'1'ならfalseにする
        setHasComparisonClaim(value);
        // 必要に応じて、ここでcaseStudyDataの更新処理を行う
    };

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor('data.name', {
            id: "name",
            header: () => t('対象サブシステム'),
            cell: info => info.renderValue(),
            meta: { editable: false },
        }),
        columnHelper.accessor('data.sampling_procedure', {
            id: "sampling_procedure",
            header: () => t('収集方法'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
        columnHelper.accessor('data.criteria_for_excluding_intermediate_flow', {
            id: "criteria_for_excluding_intermediate_flow",
            header: () => t('カットオフした項目'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
        columnHelper.accessor('data.public_comment', {
            id: "public_comment",
            header: () => t('その他（公開コメント）'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
        columnHelper.accessor('data.private_comment', {
            id: "private_comment",
            header: () => t('その他（プライベートコメント）'),
            cell: info => info.renderValue(),
            meta: { editable: true },
        }),
    ];

    const handleTableDataChange = async (updatedData: any[]) => {
        setFlowState((prevState) => {
            const newState = {
                ...prevState,
                nodes: updatedData,
            };
            console.log("New Flow State: ", newState);  // 反映された状態を確認
            return newState;
        });
        setchangeCheckReport(true);
    };
    

    // 変更監視
    const watchedName = watch('name', caseStudyData?.name || '');
    const watchedGoal = watch('goal', caseStudyData?.goal || '');
    const watchedReportCreatorName = watch('report_creator_name', caseStudyData?.report_creator_name || '');
    const watchedLcaConductorName = watch('lca_conductor_name', caseStudyData?.lca_conductor_name || '');
    const watchedLcaRequesterName = watch('lca_requester_name', caseStudyData?.lca_requester_name || '');
    const watchedReportCreatedDateAt = watch('report_created_date_at', caseStudyData?.report_created_date_at || '');
    const watchedProductSystemSummaryDescription = watch('product_system_summary_description', caseStudyData?.product_system_summary_description || '');
    const watchedProductSystemFunction = watch('product_system_function', caseStudyData?.product_system_function || '');
    const watchedFunctionalUnit = watch('functional_unit', caseStudyData?.functional_unit || '');
    const watchedCriteriaForCutoff = watch('criteria_for_cutoff', caseStudyData?.criteria_for_cutoff || '');
    const watchedTemporalCorrelation = watch('temporal_correlation', caseStudyData?.temporal_correlation || '');
    const watchedGeographicalCorrelation = watch('geographical_correlation', caseStudyData?.geographical_correlation || '');
    const watchedTechnologicalCorrelation = watch('technological_correlation', caseStudyData?.technological_correlation || '');
    const watchedAccuracy = watch('accuracy', caseStudyData?.accuracy || '');
    const watchedCompleteness = watch('completeness', caseStudyData?.completeness || '');
    const watchedRepresentativeness = watch('representativeness', caseStudyData?.representativeness || '');
    const watchedConsistency = watch('consistency', caseStudyData?.consistency || '');
    const watchedReproducibility = watch('reproducibility', caseStudyData?.reproducibility || '');
    const watchedDataSource = watch('data_source', caseStudyData?.data_source || '');
    const watchedUncertainty = watch('uncertainty', caseStudyData?.uncertainty || '');
    const watchedTextOfCharacterizationResults = watch('text_of_characterization_results', caseStudyData?.text_of_characterization_results || '');
    const watchedTextOfDamageAssessmentResults = watch('text_of_damage_assessment_results', caseStudyData?.text_of_damage_assessment_results || '');
    const watchedTextOfIntegrationResults = watch('text_of_integration_results', caseStudyData?.text_of_integration_results || '');
    const watchedSensitivityAnalysis = watch('sensitivity_analysis', caseStudyData?.sensitivity_analysis || '');
    const watchedDataQualityEvaluation = watch('data_quality_evaluation', caseStudyData?.data_quality_evaluation || '');
    const watchedInterpretationOfCharacterizationResults = watch('interpretation_of_characterization_results', caseStudyData?.interpretation_of_characterization_results || '');
    const watchedInterpretationOfIntegrationResults = watch('interpretation_of_integration_results', caseStudyData?.interpretation_of_integration_results || '');
    const watchedLimit = watch('limit', caseStudyData?.limit || '');
    const watchedConclusion = watch('conclusion', caseStudyData?.conclusion || '');
    const watchedProposal = watch('proposal', caseStudyData?.proposal || '');
    const watchedReviewByUser = watch('review_by_user', caseStudyData?.review_by_user || '');
    const watchedReviewByReviewer = watch('review_by_reviewer', caseStudyData?.review_by_reviewer || '');
    const watchedSystemBoundaryImageName = watch('system_boundary_image_name', caseStudyData?.system_boundary_image_name || '');
    const watchedSystemBoundaryDescription = watch('system_boundary_description', caseStudyData?.system_boundary_description || '');

    useEffect(() => {
        if (
            watchedName !== (caseStudyData?.name ?? '')  ||
            watchedGoal !== (caseStudyData?.goal ?? '') ||
            watchedReportCreatorName !== (caseStudyData?.report_creator_name ?? '') ||
            watchedLcaConductorName !== (caseStudyData?.lca_conductor_name ?? '') ||
            watchedLcaRequesterName !== (caseStudyData?.lca_requester_name ?? '') ||
            watchedReportCreatedDateAt !== (caseStudyData?.report_created_date_at ?? '') ||
            hasComparisonClaim !== caseStudyData?.has_comparison_claim ||
            watchedProductSystemSummaryDescription !== (caseStudyData?.product_system_summary_description ?? '') ||
            watchedProductSystemFunction !== (caseStudyData?.product_system_function ?? '') ||
            watchedFunctionalUnit !== (caseStudyData?.functional_unit ?? '') ||
            watchedCriteriaForCutoff !== (caseStudyData?.criteria_for_cutoff ?? '') ||
            watchedTemporalCorrelation !== (caseStudyData?.temporal_correlation ?? '') ||
            watchedGeographicalCorrelation !== (caseStudyData?.geographical_correlation ?? '') ||
            watchedTechnologicalCorrelation !== (caseStudyData?.technological_correlation ?? '') ||
            watchedAccuracy !== (caseStudyData?.accuracy ?? '') ||
            watchedCompleteness !== (caseStudyData?.completeness ?? '') ||
            watchedRepresentativeness !== (caseStudyData?.representativeness ?? '') ||
            watchedConsistency !== (caseStudyData?.consistency ?? '') ||
            watchedReproducibility !== (caseStudyData?.reproducibility ?? '') ||
            watchedDataSource !== (caseStudyData?.data_source ?? '') ||
            watchedUncertainty !== (caseStudyData?.uncertainty ?? '') ||
            watchedTextOfCharacterizationResults !== (caseStudyData?.text_of_characterization_results ?? '') ||
            watchedTextOfDamageAssessmentResults !== (caseStudyData?.text_of_damage_assessment_results ?? '') ||
            watchedTextOfIntegrationResults !== (caseStudyData?.text_of_integration_results ?? '') ||
            watchedSensitivityAnalysis !== (caseStudyData?.sensitivity_analysis ?? '') ||
            watchedDataQualityEvaluation !== (caseStudyData?.data_quality_evaluation ?? '') ||
            watchedInterpretationOfCharacterizationResults !== (caseStudyData?.interpretation_of_characterization_results ?? '') ||
            watchedInterpretationOfIntegrationResults !== (caseStudyData?.interpretation_of_integration_results ?? '') ||
            watchedLimit !== (caseStudyData?.limit ?? '') ||
            watchedConclusion !== (caseStudyData?.conclusion ?? '') ||
            watchedProposal !== (caseStudyData?.proposal ?? '') ||
            watchedReviewByUser !== (caseStudyData?.review_by_user ?? '') ||
            watchedReviewByReviewer !== (caseStudyData?.review_by_reviewer ?? '') ||
            watchedSystemBoundaryImageName !== (caseStudyData?.system_boundary_image_name ?? '') ||
            watchedSystemBoundaryDescription !== (caseStudyData?.system_boundary_description ?? '')
        ) {
            setchangeCheckReport(true);
        }
    }, [
        caseStudyData,
        watchedName,
        watchedGoal,
        watchedReportCreatorName,
        watchedLcaConductorName,
        watchedLcaRequesterName,
        watchedReportCreatedDateAt,
        watchedProductSystemSummaryDescription,
        watchedProductSystemFunction,
        watchedFunctionalUnit,
        watchedCriteriaForCutoff,
        watchedTemporalCorrelation,
        watchedGeographicalCorrelation,
        watchedTechnologicalCorrelation,
        watchedAccuracy,
        watchedCompleteness,
        watchedRepresentativeness,
        watchedConsistency,
        watchedReproducibility,
        watchedDataSource,
        watchedUncertainty,
        watchedTextOfCharacterizationResults,
        watchedTextOfDamageAssessmentResults,
        watchedTextOfIntegrationResults,
        watchedSensitivityAnalysis,
        watchedDataQualityEvaluation,
        watchedInterpretationOfCharacterizationResults,
        watchedInterpretationOfIntegrationResults,
        watchedLimit,
        watchedConclusion,
        watchedProposal,
        watchedReviewByUser,
        watchedReviewByReviewer,
        hasComparisonClaim,
        watchedSystemBoundaryImageName,
        watchedSystemBoundaryDescription
    ])


    return (
        <>
            <Section>
                <ButtonSection>
                {userData?.is_admin && 
                    <StyledExportButton type='button'><StyledExportButtonText>[SystemTest]{t('報告書ファイル出力')}</StyledExportButtonText></StyledExportButton>
                }
                {userData?.is_admin && 
                    <StyledExportButton type='button'><StyledExportButtonText>[SystemTest]{t('SuMPO EPDで出力')}</StyledExportButtonText></StyledExportButton>
                }
                </ButtonSection>
                <SectionTitle>{t('一般情報')}</SectionTitle>
                <Dl>
                    <Dt>{t('ケーススタディ')}</Dt>
                    <Dd>
                        <Controller
                            name="name"
                            disabled
                            control={control}
                            rules={{ required: 'ケーススタディ名は必須です' }}
                            defaultValue={caseStudyData?.name || ''}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('報告書作成者名')}</Dt>
                    <Dd>
                        <Controller
                            name="report_creator_name"
                            control={control}
                            defaultValue={caseStudyData?.report_creator_name || ''}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('LCA実施者（団体）')}</Dt>
                    <Dd>
                        <Controller
                            name="lca_conductor_name"
                            control={control}
                            defaultValue={caseStudyData?.lca_conductor_name || ''}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('LCA実施依頼者')}</Dt>
                    <Dd>
                        <Controller
                            name="lca_requester_name"
                            control={control}
                            defaultValue={caseStudyData?.lca_requester_name || ''}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('報告書作成日')}</Dt>
                    <Dd>
                        <Controller
                            name="report_created_date_at"
                            // disabled
                            control={control}
                            defaultValue={caseStudyData?.report_created_date_at || ''}
                            render={({ field }) => (
                                <DateInput
                                    value={field.value}
                                    onChange={field.onChange} // field.onChange を DateInput に渡す
                                />
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('目的の設定')}</SectionTitle>
                <Dl>
                    <Dt>{t('LCAの実施目的')}</Dt>
                    <Dd>
                        <Controller
                            name="goal"
                            control={control}
                            defaultValue={caseStudyData?.goal || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('比較主張の有無')}</Dt>
                    <Dd>
                        <Controller
                            name="has_comparison_claim"
                            disabled
                            control={control}
                            // rules={{ required: 'ケーススタディ名は必須です' }}
                            // defaultValue={caseStudyData?.name || ''}
                            render={({ field }) => (
                                <>
                                    <RadioWrap>
                                        <RadioInput
                                            type="radio"
                                            name="grouping"
                                            id="1"
                                            value="0"
                                            checked={hasComparisonClaim === true}
                                            onChange={handleRadioChange}
                                        />
                                        <RadioLabel htmlFor="1">
                                            <RadioText>
                                                {t('有')}
                                            </RadioText>
                                        </RadioLabel>

                                        <RadioInput
                                            type="radio"
                                            name="grouping"
                                            id="2"
                                            value="1"
                                            checked={hasComparisonClaim === false}
                                            onChange={handleRadioChange}
                                        />
                                        <RadioLabel htmlFor="2">
                                            <RadioText>
                                                {t('無')}
                                            </RadioText>
                                        </RadioLabel>
                                    </RadioWrap>
                                </>
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('調査範囲の設定')}</SectionTitle>
                <Dl>
                    <Dt>{t('記述')}</Dt>
                    <Dd>
                        <Controller
                            name="product_system_summary_description"
                            control={control}
                            defaultValue={caseStudyData?.product_system_summary_description || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>
                    <Dt>{t('画像名称')}</Dt>
                    <Dd>
                        <DisableInput type="text" value={imageName || ''} readOnly />
                    </Dd>

                    <Dt>{t('画像')}</Dt>
                    <Dd>
                        <FileDiv>
                            <FileWrap>
                                <FileInput type="file" id="fileImg" onChange={handleFileChange} />
                                <FileLabel htmlFor="fileImg" onClick={(e) => e.stopPropagation()}>
                                    {previewImage ? (
                                        <ImgWrapper>
                                            <Img src={previewImage} width="100%" height="382px" alt={t('調査範囲の設定の画像')} />
                                            <DeleteButton onClick={handleImageDelete}>
                                                <FileDeleteIcon />
                                            </DeleteButton>
                                        </ImgWrapper>
                                    ) : imageSrc ? (
                                        <ImgWrapper>
                                            <Img src={`data:image/png;base64,${imageSrc}`} width="100%" height="380px" alt={t('調査範囲の設定の画像')} />
                                            <DeleteButton onClick={handleImageDelete}>
                                                <FileDeleteIcon />
                                            </DeleteButton>
                                        </ImgWrapper>
                                    ) : (
                                        <Img src={iconImg} alt={t('調査範囲の設定の画像')} />
                                    )}
                                </FileLabel>
                            </FileWrap>
                            {/* <p>{t('画像名')}</p>
                            <Input type="text" value={imageName || ''} readOnly />
                            <label>
                                <FileAddButton type="button" onClick={triggerFileInput}>
                                    <FileAddDiv>
                                        <FileAddIcon></FileAddIcon>
                                    </FileAddDiv>
                                    <FileAddText>{t('画像を追加')}</FileAddText>
                                </FileAddButton>
                            </label> */}
                        </FileDiv>
                    </Dd>

                    <Dt>{t('製品システムの機能')}</Dt>
                    <Dd>
                        <Controller
                            name="product_system_function"
                            control={control}
                            defaultValue={caseStudyData?.product_system_function || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('機能単位')}</Dt>
                    <Dd>
                        <Controller
                            name="functional_unit"
                            defaultValue={caseStudyData?.functional_unit || ''}
                            control={control}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('システム境界 記述')}</Dt>
                    <Dd>
                        <Controller
                            name="system_boundary_description"
                            defaultValue={caseStudyData?.system_boundary_description || ''}
                            control={control}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('システム境界 名称')}</Dt>
                    <Dd>
                        <Controller
                            name="system_boundary_image_name"
                            defaultValue={caseStudyData?.system_boundary_image_name || ''}
                            control={control}
                            render={({ field }) => (
                                <DisableInput 
                                    type="text" 
                                    value={systemBoundaryimageName || ''} 
                                    readOnly />
                            )}
                        />
                    </Dd>

                    <Dt>{t('システム境界 図')}</Dt>
                    <Dd>
                        <FileDiv>
                            <FileWrap>
                                <FileInput type="file" id="systemBoundaryImg" onChange={handleSystemBoundaryImageChange} />
                                <FileLabel htmlFor="systemBoundaryImg" onClick={(e) => e.stopPropagation()}>
                                    {previewSystemBoundaryImage ? (
                                        <ImgWrapper>
                                            <Img src={previewSystemBoundaryImage} width="100%" height="382px" alt={t('システム境界')} />
                                            <DeleteButton onClick={handleSystemBoundaryImageDelete}>
                                                <FileDeleteIcon />
                                            </DeleteButton>
                                        </ImgWrapper>
                                    ) : systemBoundaryimageSrc ? (
                                        <ImgWrapper>
                                            <Img src={`data:image/png;base64,${systemBoundaryimageSrc}`} width="100%" height="380px" alt={t('システム境界')} />
                                            <DeleteButton onClick={handleSystemBoundaryImageDelete}>
                                                <FileDeleteIcon />
                                            </DeleteButton>
                                        </ImgWrapper>
                                    ) : (
                                        <Img src={iconImg} alt={t('システム境界')} />
                                    )}
                                </FileLabel>
                            </FileWrap>
                            {/* <p>{t('画像名')}</p>
                            <Input type="text" value={imageName || ''} readOnly /> */}
                            {/* <label>
                                <FileAddButton type="button" onClick={triggerFileInput}>
                                    <FileAddDiv>
                                        <FileAddIcon></FileAddIcon>
                                    </FileAddDiv>
                                    <FileAddText>{t('画像を追加')}</FileAddText>
                                </FileAddButton>
                            </label> */}
                        </FileDiv>
                    </Dd>

                    <Dt>{t('カットオフ基準')}</Dt>
                    <Dd>
                        <Controller
                            name="criteria_for_cutoff"
                            control={control}
                            defaultValue={caseStudyData?.criteria_for_cutoff || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('データ品質')}</SectionTitle>
                <Dl>
                    <Dt>{t('時間的有効範囲')}</Dt>
                    <Dd>
                        <Controller
                            name="temporal_correlation"
                            control={control}
                            defaultValue={caseStudyData?.temporal_correlation || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('地理的有効範囲')}</Dt>
                    <Dd>
                        <Controller
                            name="geographical_correlation"
                            control={control}
                            defaultValue={caseStudyData?.geographical_correlation || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('技術的有効範囲')}</Dt>
                    <Dd>
                        <Controller
                            name="technological_correlation"
                            control={control}
                            defaultValue={caseStudyData?.technological_correlation || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('精度')}</Dt>
                    <Dd>
                        <Controller
                            name="accuracy"
                            control={control}
                            defaultValue={caseStudyData?.accuracy || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('完全性')}</Dt>
                    <Dd>
                        <Controller
                            name="completeness"
                            control={control}
                            defaultValue={caseStudyData?.completeness || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('代表性')}</Dt>
                    <Dd>
                        <Controller
                            name="representativeness"
                            control={control}
                            defaultValue={caseStudyData?.representativeness || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('整合性')}</Dt>
                    <Dd>
                        <Controller
                            name="consistency"
                            control={control}
                            defaultValue={caseStudyData?.consistency || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('再現性')}</Dt>
                    <Dd>
                        <Controller
                            name="reproducibility"
                            control={control}
                            // rules={{ required: 'ケーススタディ名は必須です' }}
                            defaultValue={caseStudyData?.reproducibility || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('データ源')}</Dt>
                    <Dd>
                        <Controller
                            name="data_source"
                            control={control}
                            defaultValue={caseStudyData?.data_source || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('品質')}</Dt>
                    <Dd>
                        <Controller
                            name="uncertainty"
                            control={control}
                            defaultValue={caseStudyData?.uncertainty || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('データ収集段階')}</SectionTitle>
                {/* <Dl> */}
                <SubsystemInputDataTable columns={columns} data={flowState.nodes} paging_flag={false} onDataChange={handleTableDataChange} />
                {/* </Dl> */}
            </Section>

            <Section>
                <SectionTitle>{t('影響評価（特性化）')}</SectionTitle>
                <Dl>
                    <Dt>{t('特性化結果')}</Dt>
                    <Dd>
                        <Controller
                            name="text_of_characterization_results"
                            control={control}
                            defaultValue={caseStudyData?.text_of_characterization_results || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('影響評価（被害評価・統合化）')}</SectionTitle>
                <Dl>
                    <Dt>{t('被害評価結果')}</Dt>
                    <Dd>
                        <Controller
                            name="text_of_damage_assessment_results"
                            control={control}
                            // rules={{ required: 'ケーススタディ名は必須です' }}
                            defaultValue={caseStudyData?.text_of_damage_assessment_results || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('統合化結果')}</Dt>
                    <Dd>
                        <Controller
                            name="text_of_integration_results"
                            control={control}
                            defaultValue={caseStudyData?.text_of_integration_results || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('結果の解釈')}</SectionTitle>
                <Dl>
                    <Dt>{t('感度分析')}</Dt>
                    <Dd>
                        <Controller
                            name="sensitivity_analysis"
                            control={control}
                            defaultValue={caseStudyData?.sensitivity_analysis || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('データ品質の評価')}</Dt>
                    <Dd>
                        <Controller
                            name="data_quality_evaluation"
                            control={control}
                            defaultValue={caseStudyData?.data_quality_evaluation || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>


                    <Dt>{t('特性化結果の解釈')}</Dt>
                    <Dd>
                        <Controller
                            name="interpretation_of_characterization_results"
                            control={control}
                            defaultValue={caseStudyData?.interpretation_of_characterization_results || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('統合化結果の解釈')}</Dt>
                    <Dd>
                        <Controller
                            name="interpretation_of_integration_results"
                            control={control}
                            defaultValue={caseStudyData?.interpretation_of_integration_results || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('限界')}</Dt>
                    <Dd>
                        <Controller
                            name="limit"
                            control={control}
                            defaultValue={caseStudyData?.limit || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('結論')}</Dt>
                    <Dd>
                        <Controller
                            name="conclusion"
                            control={control}
                            defaultValue={caseStudyData?.conclusion || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('提言')}</Dt>
                    <Dd>
                        <Controller
                            name="proposal"
                            control={control}
                            defaultValue={caseStudyData?.proposal || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>

            <Section>
                <SectionTitle>{t('クリティカルレビュー')}</SectionTitle>
                <Dl>
                    {userData?.is_admin &&
                    <>
                        <Dt>[SystemTest]{t('プロセスレビューレベル')}</Dt>
                        <Dd>
                            {reviewLevelTypeData && (
                                <ReviewLevelTypeTable
                                    selectReviewLevelData={selectReviewLevelData}
                                    setSelectReviewLevelData={setSelectReviewLevelData}
                                    reviewLevelsDataList={reviewLevelsDataList}
                                    setReviewLevelsDataList={setReviewLevelsDataList}
                                />
                            )}
                        </Dd>
                    </>
                    }

                    <Dt>{t('レビュー内容(自由記述)')}</Dt>
                    <Dd>
                        <Controller
                            name="review_by_user"
                            control={control}
                            // rules={{ required: 'ケーススタディ名は必須です' }}
                            defaultValue={caseStudyData?.review_by_user || ''}
                            render={({ field }) => (
                                <TextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>

                    <Dt>{t('レビュー内容(レビュアー記述)')}</Dt>
                    <Dd>
                        <Controller
                            name="review_by_reviewer"
                            control={control}
                            disabled
                            defaultValue={caseStudyData?.review_by_reviewer || ''}
                            render={({ field }) => (
                                <DisableTextArea
                                    cols={100}
                                    rows={3}
                                    {...field}
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </Dd>
                </Dl>
            </Section>
        </>
    )
}

export default Report

const StyledPageNavButton = styled.p`
`

const StyledExportButton = styled.button`
background-color: var(--color-site-secondary);
border-radius: 4px;
border: 1px solid var(--color-site-secondary);
padding: 7px 16px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
gap: 0 4px;

font: inherit;
outline: none;
margin-right: 10px;

&:hover {
    background-color: white;
    span {
        color: var(--color-site-secondary);
    }
}
`

const StyledExportButtonText = styled.span`
color: #fff;
font-size: 13px;
font-weight: 500;
`

const Section = styled.section`
margin-bottom: 80px;
width: max(53.47222222vw, 770px);
`

const SectionTitle = styled.h2`
padding: 0 0 20px;
margin-bottom: 20px;
border-bottom: 1px solid var(--color-line-primary);
font-size: 16px;
font-weight: 500

line-height: 1.25;
`

const Dl = styled.dl`
display: grid;
gap: 8px 40px;
grid-template-columns: 25% 70%;
`

const Dt = styled.dt`
font-size: 12px;
font-weight: 500;
padding: 12px 0;
`

const Dd = styled.dd`
font-size: 12px;
font-weight: 500;
// padding: 12px 0;
`

const DisableInput = styled.input`
background-color: var(--color-bg-primary);
width: 100%;
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
}
`
const Input = styled.input`
background-color: #fff;
width: 100%;
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
}
`

const DateColumn = styled.div`
display: grid;
grid-template-columns: 62.264% 1fr;
gap: 20px;
`

const Textarea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`

const Select = styled.select`
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
// color: var(--color-line-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
// background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
// appearance: none;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
cursor: pointe
`

const ButtonSection = styled.div`
margin-bottom: 20px;
`

const RadioWrap = styled.div`
margin-bottom: 32px;
gap: 24px;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
justify-content: flex-start;
`

const RadioInput = styled.input`
  display: none;

  &:checked + label::before {
    border-color: var(--color-site-secondary);
    background-color: var(--color-site-secondary);
  }

  &:checked + label::after {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 4.5px;
    height: 4.5px;
    content: "";
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
  }

  &:not(:checked) + label .c-input,
  &:not(:checked) + label .c-select,
  &:not(:checked) + label .c-select__inner {
    pointer-events: none;
    color: var(--color-line-primary) !important;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px;
  width: fit-content;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--color-txt-primary);
    border-radius: 50%;
    background-color: #fff;
  }
`;

const RadioText = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 0 20px;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
`;

const FileDiv = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`
const FileWrap = styled.div`
grid-column: 1 / 4;
width: 100%;
height: 382px;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: var(--color-bg-primary);
position: relative;
`

const FileInput = styled.input`
display: none;
`

const FileLabel = styled.label`
width: 100%;
height: 100%;
cursor: pointer;
display: grid;
place-items: center;
`
const Img = styled.img`
`

const FileAddButton = styled.button`
padding: 6px 18px;
border-radius: 20px !important;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;
}

`

const FileAddDiv = styled.div`
width: 16px;
height: 16px;
border: none;
background-color: var(--color-site-secondary);

display: grid;
place-content: center;
border-radius: 50%;
`

const FileAddIcon = styled.span`
width: 7.44px;
height: 7.44px;
background-color: #fff !important;;
position: relative;
mask: url(${iconAdd}) no-repeat center center / contain;
-webkit-mask: url(${iconAdd}) no-repeat center center / contain;
display: block;
background: var(--color-txt-primary);
`
const FileAddText = styled.span`
font-size: 12px;
font-weight: 500;
`

const TextArea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`

const DisableTextArea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: var(--color-bg-primary);
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`

// 画像削除ボタン用のスタイル
const ImgWrapper = styled.div`
    position: relative;
`;

const DeleteButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
`;

const FileDeleteIcon = styled.span`
    display: block;
    width: 40px;
    height: 40px;
    mask: url(${iconCross}) no-repeat center center / contain;
    -webkit-mask: url(${iconCross}) no-repeat center center / contain;
    background-color: var(--color-txt-primary);
`;
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';

import { useDeleteProduct, useGetCategoryValuesForProsess, useGetProductByCategory } from '@hooks/useBackendApi';
import { CategoriesList, CategoryValues, GetUserDetail } from '@typeList/types';

import styled from 'styled-components';
import { css } from '@emotion/react';
import iconEdit from '@images/table/icon_edit.svg';
import iconDelete from '@images/table/icon_delete.svg';
import iconAdd from '@images/text_tree/icon_add.svg';
import iconClose from '@images/text_tree/icon_close.svg';
import iconSelect from '@images/table/icon_arrow_back.svg';
import iconFolder from '@images/text_tree/icon_folder.svg';

import { useTranslation } from 'react-i18next';
import Modal from '@common/modal/Modal';
import ProductEditModal from '../product_edit/ProductEditModal';
import InputIntermediateFlowCreateModal from '../input_Intermediate_flow/InputIntermediateFlowCreateModal';
import OutputIntermediateFlowCreateModal from '../output_Intermediate_flow/OutputIntermediateFlowCreateModal';
import { FadeLoader } from 'react-spinners';
import SubsystemInputProductCreateModal from '@specific/case_study/subsystem_input_product/SubsystemInputProductCreateModal';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { EventDataNode } from 'rc-tree/lib/interface';


// TreeNodeの型を定義
interface TreeNode {
    key: string;
    icon?: JSX.Element;
    title: string | JSX.Element;
    children?: TreeNode[];
    isLeaf?: boolean;
    categoryId?: string; // カスタムプロパティ - category.id
    valueId?: string;    // カスタムプロパティ - value.id
    productId?: string; // カスタムプロパティ - product.id
}

interface ProductTextTreeSearchProps {
    categoriesListData: CategoriesList[] | null;
    modal_type: string; // in or out or allocation
    type: string; // Select or Edit
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>;
    onSelectProduct: (product: { id: number, name: string, unit: { id: number, name: string, unit_group_id: number }, category_values: CategoryValues[]}) => void;
    currentNodeData?:any;
    userData?: GetUserDetail | null
}

const ProductTextTreeSearch: React.FC<ProductTextTreeSearchProps> = ({
    categoriesListData,
    modal_type, 
    type,
    setIsSearchModalOpen, 
    onSelectProduct,
    currentNodeData,
    userData
}) => {
    const {t} = useTranslation();

    const prodcessContext = useContext(ProcessViewContext)
    const {processData} = prodcessContext
    const caseStudyContext = useContext(CaseStudyViewContext)
    const {caseStudyData} = caseStudyContext
     
    // データベースパック
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    useEffect(() =>{
        console.log("inputDatabasePackId", inputDatabasePackId)
    },[inputDatabasePackId])
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    useEffect(() => {
        if (userData && userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            setInputDatabasePackId(userData.default_database_pack_id || uniqueDatabasePacks[0].id);
        }
    }, [userData]);


    // プロセスからの導線
    useEffect(() => {
        if (processData && processData.database_pack) {
            setInputDatabasePackId(processData.database_pack.id)
        }
    }, [processData]);

    // ケーススタディからの導線
    useEffect(() => {
        if (caseStudyData && caseStudyData.database_pack) {
            setInputDatabasePackId(caseStudyData.database_pack.id)
        }
    }, [caseStudyData]);

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                resetProductsData(); // Reset products data
                collapseTree(); // Collapse the tree
                break;
        }
    };

    // 製品のツリーをリセット
    const resetProductsData = () => {
        setTreeData((prevData) =>
            prevData.map(node => {
                if (node.key.startsWith("category-")) {
                    return {
                        ...node,
                        children: node.children?.map(child => ({
                            ...child,
                            children: [] // Clear product children under category values
                        }))
                    };
                }
                return node;
            })
        );
    };
    
    // Collapse all nodes by clearing expanded keys
    const collapseTree = () => {
        setExpandedKeys([]);
    };

    // 展開中のツリー
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const handleExpand = (expandedKeys: React.Key[], info: { node: EventDataNode<TreeNode>; expanded: boolean; nativeEvent: MouseEvent }) => {
        setExpandedKeys(expandedKeys.map(String)); // Convert all keys to strings if necessary
    };

    // 初期データの設定
    const [treeData, setTreeData] = useState<TreeNode[]>([]);

    // カテゴリ値API
    const { getCategoryValuesForProsess, categoryValuesData, categoryValuesLoading } = useGetCategoryValuesForProsess();

    // カテゴリ値に紐づく製品
    const { getProductByCategory, productByCategoryData, productByCategoryLoading } = useGetProductByCategory();

    useEffect(() => {
        const fetchInitialTreeData = async () => {
            if (categoriesListData) {
                const initialData: TreeNode[] = await Promise.all(
                    categoriesListData.map(async (category) => {
                        // カテゴリ値を取得
                        const categoryValues = await getCategoryValuesForProsess(category.id);

                        const children: TreeNode[] = categoryValues.map((value: any) => ({
                            key: `category_values-${value.id.toString()}`,
                            icon: <Icon />,
                            title: value.name,
                            children: [], // 初期は空配列
                            isLeaf: false, // 子ノードとして設定
                            categoryId: category.id.toString(), // カスタムプロパティに category.id を設定
                            valueId: value.id.toString()
                        }));

                        return {
                            key: `category-${category.id.toString()}`,
                            icon: <Icon />,
                            title: category.name,
                            children: children, // 取得したデータをchildrenに設定
                            isLeaf: children.length === 0
                        };
                    })
                );
                setTreeData(initialData);
            }
        };

        fetchInitialTreeData();
    }, [categoriesListData]);

    const [loadingKeys, setLoadingKeys] = useState<string[]>([]); // ロード中のノードを管理

    // ノード展開時に製品データを取得
    const onLoadData = async (treeNode: TreeNode) => {
        // すでにロード済みの子ノードがある場合、処理をスキップ
        if (treeNode.children && treeNode.children.length > 0) {
            return;
        }

        // ノードが `category_values-` で始まる場合に製品データを取得
        if (treeNode.key.startsWith("category_values-")) {
            setLoadingKeys((prev) => [...prev, treeNode.key]); // ローディング開始

            // 一時的にローディングインジケータを表示するためにノードを更新
            setTreeData((prevData) =>
                updateTreeData(prevData, treeNode.key, [
                    { 
                        key: `category_-${treeNode.categoryId}_values-${treeNode.valueId}_${treeNode.key}-loading`, 
                        title:
                            <FadeLoader
                                color="#48bdbb"
                                height={5}
                                radius={1}
                                width={3}
                                margin={-5}
                                cssOverride={css`
                                    top: 18px
                                `}
                            />,
                        isLeaf: true 
                    }
                ])
            );

            // 製品データを取得
            try {                
                const products = await getProductByCategory(inputDatabasePackId, Number(treeNode.categoryId)!, Number(treeNode.valueId)!);
    
                // 製品データをツリー構造に変換
                const children: TreeNode[] = products.map((product: any) => ({
                    key: `category_-${treeNode.categoryId}_values-${treeNode.valueId}_product-${product.id.toString()}`,
                    title: (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Span>
                                {type === "select" && (modal_type !== "allocation" && modal_type !== "subsystem") && (
                                    <SelectButton onClick={() => handleProductClick(product)} style={{ margin: '0 5px' }}>
                                        <SelectButtonIcon />
                                    </SelectButton>
                                )}
                                {type === "select" && modal_type === "allocation" && (
                                    <SelectButton onClick={() => handleSelectProductForAllocation(product)} style={{ margin: '0 5px' }}>
                                        <SelectButtonIcon />
                                    </SelectButton>
                                )}
                                {type === "select" && modal_type === "subsystem" && (
                                    <SelectButton onClick={() => handleSelectProductForSubsystem(product)} style={{ margin: '0 5px' }}>
                                        <SelectButtonIcon />
                                    </SelectButton>
                                )}
                                <EditButton onClick={() => handleEdit(product.id)} style={{ margin: '0 5px' }}>
                                    <EditButtonIcon />
                                </EditButton>
                                {!product.is_database_pack_master &&
                                    <DeleteButton onClick={() => handleDelete(product.id)}>
                                        <DeleteButtonIcon />
                                    </DeleteButton>
                                }
                            </Span>
                            <span onClick={() => handleProductClick(product)} className="product-name">
                                {product.name}
                            </span>
                        </div>
                    ),
                    children: [],
                    isLeaf: true,
                    productId: product.id
                }));
    
                // ツリーに取得したデータを更新
                setTreeData((prevData) =>
                    updateTreeData(prevData, treeNode.key, children)
                );
            } finally {
                setLoadingKeys((prev) => prev.filter((key) => key !== treeNode.key)); // ローディング終了
            }
        }
    };

    // ツリーデータの更新関数
    const updateTreeData = (list: TreeNode[], key: string, children: TreeNode[]): TreeNode[] =>
        list.map((node) => {
            if (node.key === key) {
                return { ...node, children, isLeaf: children.length === 0 ? false : undefined };
            }
            if (node.children) {
                return { ...node, children: updateTreeData(node.children, key, children) };
            }
            return node;
        });


    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingProductId, setEditingProductId] = useState<number | null>(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [inputCategoryValueId, setInputCategoryValueId] = useState<number | null>(null);

    // 製品選択
    const handleProductClick = (product: { id: number, name: string, unit: { id: number, name: string, unit_group_id: number }, category_values: CategoryValues[]}) => {
        setSelectedProduct(product);
        onSelectProduct(product)
        setIsCreateModalOpen(true);
    };

    // 製品選択　配分
    const handleSelectProductForAllocation = (product: { id: number, name: string, unit: { id: number, name: string, unit_group_id: number }, category_values: CategoryValues[]}) => {
        onSelectProduct(product);
        setIsSearchModalOpen(false);
    };

    // 製品選択　サブシステム
    const handleSelectProductForSubsystem = (product: { id: number, name: string, unit: { id: number, name: string, unit_group_id: number }, category_values: CategoryValues[]}) => {
        setSelectedProduct(product);
        setIsSearchModalOpen(false);
        setIsCreateModalOpen(true);
    };

    const handleSelectCategoryValue = (categoryValueId: number, categoryValueName: string) => {
        setInputCategoryValueId(categoryValueId);
    };
    
    const onSelect = (selectedKeys: any, info: any) => {
        const selectedNode = info.node;
        handleSelectCategoryValue(parseInt(selectedNode.key), selectedNode.title);
    };


    // 製品編集
    const handleEdit = (productId: number) => {
        setEditingProductId(productId);
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setIsUpdate(true)
    };
    

    // 製品削除
    const { deleteProduct } = useDeleteProduct();
    const handleDelete = async (productId: number) => {
        const isConfirmed = window.confirm(t('削除しますか？'));
        if (isConfirmed) {
            try {
                await deleteProduct(productId);
    
                // 削除が成功した後にツリーデータを更新
                setTreeData((prevData) => removeNodeByProductId(prevData, productId));
            } catch (error: any) {
                if(error.response && error.response.data.detail){
                    alert(error.response.data.detail);
                }else {
                    alert(error);
                }
            }
        }
    };
    
    // productIdを基にノードを削除する関数
    const removeNodeByProductId = (nodes: TreeNode[], productId: number): TreeNode[] => {
        return nodes.reduce<TreeNode[]>((acc, node) => {
            // productIdが一致する場合は削除（結果に追加しない）
            if (Number(node.productId) === productId) return acc;
    
            // 子ノードがある場合は再帰的に削除
            const updatedNode = { ...node };
            if (updatedNode.children) {
                updatedNode.children = removeNodeByProductId(updatedNode.children, productId);
            }
            acc.push(updatedNode);
            return acc;
        }, []);
    };

    const closeCreateModal = () => {
        setIsCreateModalOpen(false);
    };


    return (
        <>
            {userData &&
            <>
                <SectionTitle>{t('データベースパック')}</SectionTitle>
                <SectionContent>
                    <SelectPrimary>
                        <SelectInner
                            name="database-pack"
                            id="database-pack-select"
                            value={inputDatabasePackId}
                            onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                            disabled={productByCategoryLoading}
                        >
                            {databasePacks.map((database_pack: any) => (
                                <option key={database_pack.id} value={database_pack.id}>
                                    {database_pack.name}
                                </option>
                            ))}
                        </SelectInner>
                    </SelectPrimary>
                </SectionContent>
            </>
            }
            <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
                <ProductEditModal
                    Id={editingProductId ?? 0}
                    onClose={handleCloseEditModal}
                />
            </Modal>
            {modal_type === "in" &&
                <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal}>
                    {selectedProduct && (
                        <InputIntermediateFlowCreateModal
                            data={selectedProduct}
                            setIsSearchModalOpen={setIsSearchModalOpen}
                            onClose={closeCreateModal}
                        />
                    )}
                </Modal>
            }
            {modal_type === "out" &&
                <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal}>
                    {selectedProduct && (
                        <OutputIntermediateFlowCreateModal
                            data={selectedProduct}
                            setIsSearchModalOpen={setIsSearchModalOpen}
                            onClose={closeCreateModal}
                        />
                    )}
                </Modal>
            }
            {modal_type === "subsystem" &&
                <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal}>
                    <Inner>
                        <SubsystemInputProductCreateModal
                            data={selectedProduct}
                            onClose={closeCreateModal}
                            setIsSearchModalOpen={setIsSearchModalOpen}
                            currentNodeData={currentNodeData}
                        />
                    </Inner>
                </Modal>
            }
            
            <Tree
                treeData={treeData}
                loadData={onLoadData}
                loadedKeys={loadingKeys}
                selectedKeys={[inputCategoryValueId?.toString() || '']}
                onSelect={onSelect}
                expandedKeys={expandedKeys}
                onExpand={handleExpand}
                showIcon={true}
                className="product-select_category-tree"
            />
        </>
    );
};

export default ProductTextTreeSearch;

const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const SectionTitle = styled.h2`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const Span = styled.span`
    margin-bottom: -4px;
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`


const Icon = styled.span`
    background-color: var(--color-green-01);
    mask: url(${iconFolder}) no-repeat center center / contain;
    -webkit-mask: url(${iconFolder}) no-repeat center center / contain;
    display: table-caption;
    width: 100%;
    height: 100%;
    width: 24px;
    height: 24px;
`

const SelectButtonIcon = styled.span`
    mask: url(${iconSelect}) no-repeat center center / contain;
    -webkit-mask: url(${iconSelect}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
`

const SelectButton = styled.button`
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
    margin: 0 !important;
    padding: 0 !important;
`

const EditButtonIcon = styled.span`
    mask: url(${iconEdit}) no-repeat center center / contain;
    -webkit-mask: url(${iconEdit}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
`

const EditButton = styled.button`
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
    margin: 0 !important;
    padding: 0 !important;
`

const DeleteButtonIcon = styled.span`
    mask: url(${iconDelete}) no-repeat center center / contain;
    -webkit-mask: url(${iconDelete}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
`

const DeleteButton = styled.button`
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
    margin: 0 !important;
    padding: 0 !important;
`

const styles = `
    .product-select_category-tree .rc-tree-switcher_close:before {
        width: 10px;
        height: 10px;
        background-image: url(${iconAdd});
        background-size: 10px;
        content: "";
    }
    .product-select_category-tree .rc-tree-switcher_open:before {
        width: 10px;
        height: 2px;
        background-image: url(${iconClose});
        background-size: 10px 2px;
        content: "";
    }
    .rc-tree .rc-tree-treenode span.rc-tree-switcher, 
    .rc-tree .rc-tree-treenode span.rc-tree-checkbox, 
    .rc-tree .rc-tree-treenode span.rc-tree-iconEle {
        // background-image: none;
    }

    .product-select_category-tree .rc-tree-switcher_open {
        display: grid !important;
        width: 20px !important;
        height: 20px !important;
        margin-right: 8px !important;
        background-image: none !important;
        place-content: center !important;
        border: 2px solid var(--color-line-primary) !important;
        background-color: #fff !important;
        border-radius: 50% !important;
        background-position: -75px -56px;
    }

    .product-select_category-tree .rc-tree-switcher_close {
        display: grid !important;
        width: 20px !important;
        height: 20px !important;
        margin-right: 8px !important;
        background-image: none !important;
        place-content: center !important;
        border: 2px solid var(--color-line-primary) !important;
        background-color: #fff !important;
        border-radius: 50% !important;
    }

    .rc-tree .rc-tree-treenode {
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .rc-tree-title {
        display: inline-block;
        margin-left: 10px;
    }

    .rc-tree-iconEle {
        margin-bottom: 4px;
    }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGetCategoryValuesForProsess } from '@hooks/useBackendApi';
import Modal from '@common/modal/Modal';
import Tree from 'rc-tree';
import { useTranslation } from 'react-i18next';

import iconAdd from '@images/text_tree/icon_add.svg'
import iconClose from '@images/text_tree/icon_close.svg'
import iconFolder from '@images/text_tree/icon_folder.svg'

interface CategoryValueSelectModalType {
    rowId: number;
    rowName: string;
    onSelectCategoryValue: (categoryValueId: number, categoryValueName: string) => void;
    categoryId: number;
}

interface CustomTreeNode {
    key: string;
    title: string;
    children: CustomTreeNode[];
}


/**
 *  分類選択
 * 
 * @param param0 
 * @returns 
 */
const CategoryValueSelectModal: React.FC<CategoryValueSelectModalType> = ({ rowId, rowName, onSelectCategoryValue, categoryId }) => {
    const { t } = useTranslation();
    const [inputCategoryValueId, setInputCategoryValueId] = useState<number | null>(null);

    const {
        getCategoryValuesForProsess,
        categoryValuesData,
        categoryValuesLoading,
        categoryValuesError,
    } = useGetCategoryValuesForProsess();

    useEffect(() =>{
        if(categoryId && categoryId !== 0){
            getCategoryValuesForProsess(categoryId)
        }
    },[categoryId])

    // const [categoryValueIds, setCategoryValueIds] = useState<Array<{ id: number, name: string }> | null | undefined>();
    const [data, setData] = useState<CustomTreeNode[]>([]);

    useEffect(() => {
        if (categoryValuesData) {
            // setCategoryValueIds(categoryValuesData.map(categoryValue => ({ id: categoryValue.id, name: categoryValue.name })));
            // console.log("categoryValuesData", categoryValuesData);

            const transformToTreeData = (data: any): CustomTreeNode[] => {
                return data.map((item: any) => ({
                    key: item.id.toString(),
                    title: (
                        <span>
                            <Icon />
                            {item.name}
                        </span>
                    ),
                    children: item.children ? transformToTreeData(item.children) : [],
                    // children: item.children.map((child: any) => ({
                    //     key: child.id.toString(),
                    //     title: child.name,
                    // }))
                }));
            };

            const transformedData = transformToTreeData(categoryValuesData);
            // console.log("transformedData", transformedData);
            setData(transformedData);
        }
    }, [categoryValuesData]);

    const handleSelectCategoryValue = (categoryValueId: number, categoryValueName: string) => {
        setInputCategoryValueId(categoryValueId);
        onSelectCategoryValue(categoryValueId, categoryValueName);
        closeModal();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleExpand = () => { /* handle expand logic if needed */ };
    const onSelect = (selectedKeys: any, info: any) => {
        const selectedNode = info.node;
    
        // title.props.children から文字列部分を取得
        const titleText = selectedNode.title.props.children.find((child:any) => typeof child === 'string');
    
        handleSelectCategoryValue(parseInt(selectedNode.key), titleText);
    };

    return (
        <>
            <Button type='button' onClick={openModal} key={`category-value-select-${rowId}`}>
                <ButtonInner>
                    {t('参照')}
                </ButtonInner>
            </Button>
            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={closeModal} key={`category-value-modal-${rowId}`}>
                    <ModalInner>
                        <H2>{rowName}</H2>
                        <div>
                            <TreeWrap>
                                <Tree
                                    treeData={data}
                                    onExpand={handleExpand}
                                    showLine={true}
                                    showIcon={true}
                                    selectedKeys={[inputCategoryValueId?.toString() || '']}
                                    onSelect={onSelect}
                                    className="custom-tree-category"
                                />
                            </TreeWrap>
                        </div>
                    </ModalInner>
                </Modal>
            )}
        </>
    );
};

export default CategoryValueSelectModal;


const Button = styled.button`
padding: 14px 30px;
border-radius: 4px;
white-space: nowrap;
background-color: var(--color-site-secondary);
display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
gap: 0 4px;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-gray--01);

    span {
        color: var(--color-txt-primary);
    }
}
`

const ButtonInner = styled.span`
color: #fff;
font-weight: 500;
`

const TreeWrap = styled.section`
background-color: #fff;
padding: 20px;
display: block;
`


// 追加のCSSスタイル
const styles = `
.custom-tree-category .rc-tree-switcher_close:before {
    width: 10px;
    height: 10px;
    background-image: url(${iconAdd});
    background-size: 10px;
    content: "";
}
.custom-tree-category .rc-tree-switcher_open:before {
    width: 10px;
    height: 2px;
    background-image: url(${iconClose});
    background-size: 10px 2px;
    content: "";
}
.rc-tree .rc-tree-treenode span.rc-tree-switcher, 
.rc-tree .rc-tree-treenode span.rc-tree-checkbox, 
.rc-tree .rc-tree-treenode span.rc-tree-iconEle {
    background-image: none;
}

.custom-tree-category  .rc-tree-switcher_open {
    display: grid !important;
    width: 24px !important;
    height: 24px !important;
    margin-right: 8px !important;
    background-image: none !important;
    place-content: center !important;
    border: 2px solid var(--color-line-primary) !important;
    background-color: #fff !important;
    border-radius: 50% !important;

    background-position: -75px -56px;
}

.custom-tree-category .rc-tree-switcher_close {
    display: grid !important;
    width: 24px !important;
    height: 24px !important;
    margin-right: 8px !important;
    background-image: none !important;
    place-content: center !important;
    border: 2px solid var(--color-line-primary) !important;
    background-color: #fff !important;
    border-radius: 50% !important;
}

.rc-tree .rc-tree-treenode {
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.rc-tree-title {
    display: inline-block;
}

.rc-tree-iconEle .rc-tree-icon__customize {
    padding-bottom: 20px;
}

`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const ModalInner = styled.div`
width: 100%;
padding: 120px 10%;
margin: 0 auto;
`

const H2 = styled.h2`
font-size: 18px;
padding: 0 0 20px;
margin-bottom: 20px;
border-bottom: 1px solid var(--color-line-primary);
font-weight: 500;
line-height: 1.25;
`

const Icon = styled.span`
background-color: var(--color-green-01);
mask: url(${iconFolder}) no-repeat center center / contain;
-webkit-mask: url(${iconFolder}) no-repeat center center / contain;
background-size: cover;
display: inline-block;
width: 100%;
height: 100%;
width: 24px;
height: 24px;
vertical-align: bottom;
margin-right: 4px;
`
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '@common/modal/Modal';
import SubsystemDivisionEditTable from '@common/table/SubsystemDivisionEditTable';
import { SubsystemCategories } from '@typeList/types';
import AddButton from '@common/button/AddButton'
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { useTranslation } from 'react-i18next';
import ReloadButton from '@common/button/ReloadButton';
import SubsystemDivisionCreateTable from '@common/table/SubsystemDivisionCerateTable';
import { useCreateSubsystemCategorySet } from '@hooks/useBackendApi';

// interface CategoryValueSelectModalType {
//     rowId: number;
//     subsystemCategories: SubsystemCategories[];
// }

interface CustomTreeNode {
    key: string;
    title: string;
    children: CustomTreeNode[];
}

/**
 * 068 サブシステムカテゴリセット作成
 * @returns 
 */
const SubsystemDivisionCreateModal = () => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { 
        caseStudyData,
        categorySetRows,
        setCategorySetRows,
        resetCaseStudyData
    } = context;
    const [inputCategoryValueId, setInputCategoryValueId] = useState<number | null>(null);
    const [inputCategorySetName, setInputCategorySetName] = useState<string | null>(null);
    const handleInputCategorySetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputCategorySetName(event.target.value);
    }

    const handleSelectCategoryValue = (categoryValueId: number, categoryValueName: string) => {
        setInputCategoryValueId(categoryValueId);
        // onSelectCategoryValue(categoryValueId, categoryValueName);
        closeModal();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [inputCategorySetNameError, setInputCategorySetNameError] = useState<any>(null);
    const [inputCategoryNameError, setInputCategoryNameError] = useState<any>(null);

    const validateCheck = (): boolean => {
        setInputCategorySetNameError(null);
        if (!inputCategorySetName) {
            setInputCategorySetNameError(t('カテゴリセット名は必須です'));
            return false;
        }
        return true;
    }

    const { createSubsystemCategorySet, createSubsystemCategorySetLoading } = useCreateSubsystemCategorySet();

    // ボタン連打制御
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() =>{
        if(isModalOpen){
            setIsDisabled(false)
        }
    },[isModalOpen])
    useEffect(() => {
        if(createSubsystemCategorySetLoading){
            setIsDisabled(true);
        }
    },[createSubsystemCategorySetLoading])

    const handleCreate = async (tableData: any[]) => {
        console.log("tableData", tableData)
        if (isDisabled) return;
        const param = {
            locale: "ja",
            name: inputCategorySetName,
            public_comment: "",
            private_comment: "",
            id: null,
            case_study_id: caseStudyData?.id,
            subsystem_categories: tableData
        }

        if(!validateCheck()){
            return
        }

        const invalidEntries = tableData.filter(item => !item.name || item.name.trim() === "");
        if (invalidEntries.length > 0) {
            setInputCategoryNameError(t('カテゴリ名が空欄のデータがあります。'));
            return;
        }

        // トークンリフレッシュのタイミングと被ると失敗するのでURLからもIDを取得
        const getIdFromUrl = () => {
            const pathArray = window.location.pathname.split('/');
            const idFromUrl = pathArray[pathArray.length - 1]; // URLの最後の部分がIDと仮定
            return parseInt(idFromUrl, 10) || 0; // 数値として取得、エラーがあれば0を返す
        };

        try {
            await createSubsystemCategorySet(caseStudyData?.id || getIdFromUrl() , param)
            // window.location.reload()
            await resetCaseStudyData();
            setIsModalOpen(false)
        } catch (error) {
            console.log(error)
        } 
    };

    return (
        <>
            <ButtonWrap>
                <AddButton type='button' onClick={openModal} text={t('サブシステムカテゴリを追加')} />
            </ButtonWrap>
            {/* <Button type='button' onClick={openModal} key={`category-value-select-${rowId}`}><ButtonText>変更</ButtonText></Button> */}
            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <Inner>
                        <Span>{t('カテゴリセット名')}<SectionRequired>※</SectionRequired></Span>
                        <Input 
                            type="text"
                            value={inputCategorySetName ?? ""}
                            onChange={handleInputCategorySetNameChange}
                        />
                        {inputCategorySetNameError && <ErrorMsg>{inputCategorySetNameError}</ErrorMsg>}
                        {inputCategoryNameError && <ErrorMsg>{inputCategoryNameError}</ErrorMsg>}
                        <SubsystemDivisionCreateTable data={[]} paging_flag={false}　onCreate={handleCreate}/>
                    </Inner>
                </Modal>
            )}
        </>
    );
};

export default SubsystemDivisionCreateModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const StyledSelect = styled.select`
    display: flex;
    gap: 10px;
`;

// 追加のCSSスタイル
const styles = `
.custom-tree-category .rc-tree-switcher_close:before {
    content: "";
}
.custom-tree-category .rc-tree-switcher_open:before {
    content: "";
}
.rc-tree .rc-tree-treenode span.rc-tree-switcher, 
.rc-tree .rc-tree-treenode span.rc-tree-checkbox, 
.rc-tree .rc-tree-treenode span.rc-tree-iconEle {
    background-image: none;
}
`;

// スタイルタグを追加してCSSを適用
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const Button = styled.button`
padding: 14px 30px;
border-radius: 4px;
white-space: nowrap;
background-color: var(--color-site-secondary);
display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
gap: 0 4px;
border: none;

&:hover {
    background-color: var(--color-gray--01);
    span {
        color: black;
    }
}
`

const ButtonText = styled.span`
font-size: 15px;
color: #fff;
ont-weight: 500;
`

const ButtonWrap = styled.div`
text-align: right;
`

const ButtonReloadSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const Input = styled.input`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
margin-bottom: 10px;
appearance: none;
font: inherit;
outline: none;
}
`

const Span = styled.span`
font-size: 16px;
margin-right: 10px;
`

const ErrorMsg = styled.span`
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    gap: 5px;
`

const SectionRequired = styled.span`
    font-size: 0.7em;
    vertical-align: top;
    position: relative;
    top: 0.1em;
    color: var(--color-red--01);
`
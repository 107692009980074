import Modal from '@common/modal/Modal';
import { useGetReferredIo, useGetUnitGroups } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { CategoryValues, DataQualityIndicators, ProcessIoDataQualityValues, ProcessIosType, UnitGroupList } from '@typeList/types';
import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import ProductEditModal from '../product_edit/ProductEditModal';
import ProcessIoDataQualityValueTable from '@common/table/ProcessIoDataQualityValueTable';
import FlowParameterSettingListModal from '../flow_parameter_setting/FlowParameterSettingListModal';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';
import { formatNumber } from '@utils/utils';
import IntermediateFlowProductEditSearchModal from '../product_search/Intermediate_flow_edit/IntermediateFlowProductEditSearchModal';
import { t } from 'i18next';
import { checkFormula } from '@utils/utils';

interface OutputIntermediateFlowEditType {
    Id: number;
    onClose: () => void;
}

interface OutputIntermediateFlowEditContextType {
    Id: number;
    onClose: () => void;
    setInputProductName: Dispatch<SetStateAction<string>>;
}

const defaulutContextvalue = {
    Id: 0,
    onClose: () => { },
    setInputProductName: () => { },
}

export const OutputIntermediateFlowEditContext = createContext<OutputIntermediateFlowEditContextType>(defaulutContextvalue);

const allocationList = [
    { value: "allocation", label: t('配分') },
    { value: "disposal", label: t('廃棄') },
    { value: "alternative", label: t('代替') },
    { value: "cutoff", label: t('カットオフ') },
];

/**
 * 021　出力中間フローの編集
 * @returns 
 */
const OutputIntermediateFlowEditModal: React.FC<OutputIntermediateFlowEditType> = ({ Id, onClose }) => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    // テーブル追加用
    const { unitGroupsData, setOutProduct, outProduct, databasePacks, dataQualityIndicatorsData, userData, setChangeCheckFlow } = context;
    const data = outProduct && outProduct.length > 0 ? outProduct[Id] : null;

    const [inputProductId, setInputProductId] = useState<number>(data?.exchange.id ?? 0);
    const [inputProductName, setInputProductName] = useState<string>(data?.exchange.name ?? "");
    const [inputAmount, setInputAmount] = useState<number | string | null>(
        formatNumber(
            data?.amount || 0,
            userData?.significant_figures ?? 2,
            userData?.use_decimal_notation ?? false,
            userData?.decimal_places ?? 2
        ) ?? null // 初期値を null として管理
    );
    const [inputAmountFormula, setInputAmountFormula] = useState<string | undefined>(data?.formula ?? "");
    const [inputPublicComment, setInputPublicComment] = useState<string>(data?.public_comment ?? "");
    const [inputPrivateComment, setInputPrivateComment] = useState<string>(data?.private_comment ?? "");
    const [inputUnitId, setInputUnitId] = useState<number>(data?.exchange.unit.id ?? 0);
    const [inputUnitName, setInputUnitName] = useState<string>(data?.exchange.unit.name ?? "");
    const [inputUnitCode, setInputUnitCode] = useState<string>(data?.exchange.unit.code ?? "");
    const [inputUnitLocale, setInputUnitLocale] = useState<string>(data?.exchange.unit.locale ?? "");
    const [inputUnitPrivateComment, setInputUnitPrivateComment] = useState<string | null>(data?.exchange.unit.private_comment ?? "");
    const [inputUnitPublicComment, setInputUnitPublicComment] = useState<string | null>(data?.exchange.unit.public_comment ?? "");
    const [inputCompatibleProductName, setInputCompatibleProductName] = useState<string>(data?.compatible_product_name ?? "");
    const [inputAmountComment, setInputAmountComment] = useState<string>(data?.amount_comment ?? "");
    const [inputInformationSources, setInputInformationSources] = useState<string>(data?.information_sources ?? "");
    const [inputCompatibilityLevel, setInputCompatibilityLevel] = useState<number>(data?.compatibility_level ?? 1);

    // エラーチェック
    const [productNameError, setProductNameError] = useState<any>(null);
    const [amountError, setAmountError] = useState<any>(null);
    const [amountFormulaError, setAmountFormulaError] = useState<any>(null);
    const validateCheck = (): boolean => {
        setProductNameError(null);
        setAmountError(null)
        if (!inputProductName) {
            setProductNameError(t('製品名は必須です'));
        }
        if (inputAmount === null || inputAmount === undefined) {
            setAmountError(t('流量は必須です'));
        }
        if (!checkFormula(inputAmountFormula)) {
            setAmountFormulaError(t('数式に使えない文字が混ざっています。使える文字は、英数と._+-*/()です'));
        }
        if (!inputProductName || inputAmount === null || inputAmount === undefined || !checkFormula(inputAmountFormula)) {
            console.log("validateCheck false")
            return false;
        }
        return true;
    }

    // 単位マスタから取得
    const [unitList, setUnitList] = useState<UnitGroupList[]>();

    // フローの単位が所属するグループでフィルター
    const getParentIdByUnitId = (unitGroups: UnitGroupList[], unitId: number): number | undefined => {
        for (const group of unitGroups) {
            for (const unit of group.units) {
                if (unit.id === unitId) {
                    return group.id;
                }
            }
        }
        return undefined;
    };
    useEffect(() => {
        if (unitGroupsData) {
            const list = unitGroupsData?.filter(units => units.id === getParentIdByUnitId(unitGroupsData, inputUnitId ?? 0))
            setUnitList(list)
        }
    }, [unitGroupsData, inputUnitId])

    // 製品を参照しているプロセス入出力
    // const [inputReferredIoId, setInputReferredIoId] = useState<number>(data?.upper_output?.process.id ?? 0);
    // const [inputReferredIoName, setInputReferredIoName] = useState<string>(data?.upper_output?.process.name ?? "");
    // const { getReferredIo, referredIoData, referredIoLoading, referredIoError } = useGetReferredIo();
    // useEffect(() => {
    //     if (inputProductId) {
    //         getReferredIo(inputProductId);
    //     }
    // }, [inputProductId]);

    // 分類
    const [categoryName, setCategoryName] = useState<string>("");
    useEffect(() => {
        if (Array.isArray(data?.exchange.category_values)) {
            const names = data?.exchange.category_values.map((categrys: any) => categrys.category.name + " " + categrys?.name).join(", ");
            setCategoryName(names ?? '');
        } else {
            console.warn(t("category_valuesは配列ではないか、undefinedです"));
        }
    }, [data]);

    // 製品詳細
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // 品質
    const [selectQualityIndicatorsData, setSelectQualityIndicatorsData] = useState<ProcessIoDataQualityValues[] | undefined>(); // 選択された品質（更新用）
    const [qualityIndicatorsDataList, setQualityIndicatorsDataList] = useState<any[] | undefined>(); // テーブルでの表示用 
    // 品質の初期設定
    useEffect(() => {
        if (data?.process_io_data_quality_values
            && dataQualityIndicatorsData
            && !selectQualityIndicatorsData
            && !qualityIndicatorsDataList
        ) {
            const matchedData = data.process_io_data_quality_values.map((qualityValue) => {
                return dataQualityIndicatorsData.find((indicator) => indicator.id === qualityValue.indicator_id);
            }).filter(Boolean);
            setSelectQualityIndicatorsData(data?.process_io_data_quality_values);
            setQualityIndicatorsDataList(matchedData as DataQualityIndicators[]);
        }
    }, [data, dataQualityIndicatorsData, setQualityIndicatorsDataList]);

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = event.target.value;
        switch (key) {
            case "name":
                setInputProductName(value);
                break;
            case "amount":
                setInputAmount(value === '' ? null : Number(value));
                break;
            case "amount_formula":
                setInputAmountFormula(value);
                break;
            case "compatible_product_name":
                setInputCompatibleProductName(value);
                break;
        }
    }
    const handleInputChangeTextArea = async (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "public_comment":
                setInputPublicComment(event.target.value);
                break;
            case "private_comment":
                setInputPrivateComment(event.target.value);
                break;
            case "amount_comment":
                setInputAmountComment(event.target.value);
                break;
            case "information_sources":
                setInputInformationSources(event.target.value);
                break;
        }
    }
    const handleInputChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case "unit":
                const selectedValue = event.target.value;
                setInputUnitId(Number(selectedValue));
                // unitListの中のunitsから選択されたユニットを探す
                for (const group of unitList ?? []) {
                    const selectedUnit = group.units.find(unit => unit.id === Number(selectedValue));
                    if (selectedUnit) {
                        setInputUnitName(selectedUnit.name);
                        setInputUnitCode(selectedUnit.code);
                        setInputUnitLocale(selectedUnit.locale);
                        setInputUnitPrivateComment(selectedUnit.private_comment);
                        setInputUnitPublicComment(selectedUnit.public_comment);
                        break;
                    }
                }
                break;
            // case "process_name":
            //     // setInputReferredIoName(event.target.value);
            //     setInputReferredIoId(Number(event.target.value));
            //     const selectedOption = event.target.selectedOptions[0];
            //     const text = selectedOption.text;
            //     if (text !== t("選択しない")) {
            //         setInputReferredIoName(text);
            //     }
            //     break;
            case "compatibility_level":
                setInputCompatibilityLevel(Number(event.target.value));
                break;
        }
    }

    // 指定されたインデックスの値を更新する関数
    const updateInProductAtIndex = (index: number, newValue: ProcessIosType) => {
        if (outProduct) {
            // 現在の inProduct 配列をコピー
            const updatedInProduct = [...outProduct];

            // 指定されたインデックスの値を更新
            if (index >= 0 && index < updatedInProduct.length) {
                updatedInProduct[index] = newValue;
                setOutProduct(updatedInProduct);
            } else {
                console.error("Index out of bounds");
            }
        } else {
            console.error("inProduct is undefined");
        }
    };

    const handleClickOK = async () => {
        if (!validateCheck()) {
            return;
        }
        setChangeCheckFlow(true)
        updateInProductAtIndex(Id, param);
        onClose();
    }

    // テーブル追加データ
    const param: ProcessIosType = {
        amount: Number(inputAmount) ?? 0,
        amount_comment: inputAmountComment,
        compatible_product_name: inputCompatibleProductName,
        direction: 'out',
        exchange: {
            public_comment: "",
            private_comment: "",
            cas_number: "",
            // id: data?.exchange.id ?? 0,
            id: inputProductId ?? data?.exchange.id ?? 0,
            locale: "",
            name: inputProductName,
            synonyms_text: data?.exchange.synonyms_text ?? "",
            include_items_text: data?.exchange.include_items_text ?? "",
            related_items_text: data?.exchange.related_items_text ?? "",
            global_id: "",
            unit: {
                id: Number(inputUnitId) ?? 0,
                locale: inputUnitLocale,
                name: inputUnitName,
                private_comment: inputUnitPrivateComment,
                public_comment: inputUnitPublicComment,
                code: inputUnitCode,
            },
            identifier_scheme_values: [],
            type: "product",
            created_username: "",
            category_values: data?.exchange.category_values ?? [],
            category1_code: null,
            category2_code: null,
            category3_code: null,
            category1_name: data?.exchange.category1_name ?? "",
            category2_name: data?.exchange.category2_name ?? "",
            category3_name: data?.exchange.category3_name ?? "",
            is_lcia_result_flow: null,
        },
        process_io_no: data?.process_io_no ?? null,
        formula: inputAmountFormula ?? "",
        global_id: data?.global_id ?? "",
        id: data?.id || null,
        information_sources: inputInformationSources ?? "",
        locale: data?.locale ?? "",
        private_comment: inputPrivateComment ?? "",
        public_comment: inputPublicComment ?? "",
        unit: {
            locale: "",
            name: data?.unit.name ?? "",
            private_comment: data?.unit.private_comment ?? "",
            public_comment: data?.unit.public_comment ?? "",
            code: data?.unit.code ?? ""
        },
        treatment: data?.treatment || null,
        upper_output: null,
        // upper_output: {
        //     id: inputReferredIoId,
        //     amount: 0,
        //     unit: {
        //         locale: "",
        //         name: "",
        //         code: "",
        //         private_comment: "",
        //         public_comment: ","
        //     },
        //     process: {
        //         name: inputReferredIoName ?? "",
        //         id: inputReferredIoId ?? 0,
        //         is_database_pack_master: data?.upper_output?.process.is_database_pack_master ?? false,
        //         global_id: data?.upper_output?.process.global_id ?? "",
        //     }
        // },
        process_io_data_quality_values: selectQualityIndicatorsData ?? [],
        compatibility_level: inputCompatibilityLevel
    }

    const onSelectProduct = async (
        product: {
            id: number,
            name: string,
            unit: {
                id: number,
                name: string,
                unit_group_id: number
            },
            category_values: CategoryValues[]
        }
    ) => {
        const names = product?.category_values.map((categrys: any) => categrys.category.name + " " + categrys?.name).join(", ");
        setCategoryName(names ?? '');
        setInputProductId(product.id);
        setInputProductName(product.name);
        // setIsModalOpen(false);
        setInputUnitName(product.unit.name);
        setInputUnitId(product.unit.id);
    }

    return (
        <OutputIntermediateFlowEditContext.Provider value={{ Id, onClose, setInputProductName }}>
            <Inner>
                <Title>{t('出力中間フロー（製品等）の編集')}</Title>
                <SectionBody>
                    <SectionColumn>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('製品')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <DisabledInput
                                    type="text"
                                    onChange={(event) => handleInputChange(event, 'name')}
                                    value={inputProductName ?? ''}
                                    disabled
                                />
                                <ModalButton type='button' onClick={() => { setIsModalOpen(true) }} text={t('製品詳細')} />
                                <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                                    <ProductEditModal Id={data?.exchange.id ?? 0} onClose={onClose} />
                                </Modal>
                                {/* <ModalButton type='button' onClick={onClose} text={'変更する'}/> */}
                                <IntermediateFlowProductEditSearchModal onSelectProduct={onSelectProduct} />
                                {productNameError && <ErrorMsg>{productNameError}</ErrorMsg>}
                            </SectionColumnContent>
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('分類')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <InputPrimary
                                    type="text"
                                    disabled
                                    value={categoryName ?? ''}
                                />
                            </SectionColumnContent>
                        </SectionColumnWrap>
                        {/* <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('プロセス')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <SelectPrimary>
                                    <SelectInner
                                        onChange={(event) => handleInputChangeSelect(event, 'process_name')}
                                        value={inputReferredIoId ?? ''}
                                    >
                                        <option>{t('選択しない')}</option>
                                        {referredIoData?.filter((referredIo) => referredIo.direction === 'out' && referredIo.process_output_treatment_type === "allocation")?.map((referredIo) => (
                                            <option key={referredIo?.process_id} value={referredIo?.process_id}>
                                                {referredIo?.process_name}
                                            </option>
                                        ))}
                                    </SelectInner>
                                </SelectPrimary>
                            </SectionColumnContent>
                        </SectionColumnWrap> */}
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('流量の数式')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <InputPrimary
                                    type="text"
                                    value={inputAmountFormula ?? ""}
                                    onChange={(event) => handleInputChange(event, 'amount_formula')}
                                />
                                <FlowParameterSettingListModal inputAmountFormula={inputAmountFormula} setInputAmountFormula={setInputAmountFormula} />
                            </SectionColumnContent>
                            {amountFormulaError && <><SectionColumnContent></SectionColumnContent><ErrorMsg>{amountFormulaError}</ErrorMsg></>}
                        </SectionColumnWrap>

                        <SectionColumnWrap>
                            <SectionColumnWrapTitle></SectionColumnWrapTitle>
                            <SectionColumnContent>
                            {t('*数式はプロセス保存時に評価され流量に反映されます')}
                            </SectionColumnContent>
                        </SectionColumnWrap>

                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('流量')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
                            <SectionColumnContentShort>
                                <InputPrimaryShort
                                    type="number"
                                    value={inputAmount !== null && inputAmount !== undefined ? inputAmount : ''}
                                    min={"0"}
                                    onChange={(event) => handleInputChange(event, 'amount')}
                                    disabled={!!inputAmountFormula}
                                />
                                <SelectPrimaryShort>
                                    <SelectInner
                                        value={inputUnitId ?? ''}
                                        onChange={(event) => handleInputChangeSelect(event, 'unit')}
                                    >
                                        <option value={data?.exchange.unit.id}>{data?.exchange.unit.code}</option>
                                        {unitList?.map((units, index) => (
                                            units.units.map((unit) => (
                                                <option key={unit.name} value={unit.id}>
                                                    {unit.code}
                                                </option>
                                            ))
                                        ))}
                                    </SelectInner>
                                </SelectPrimaryShort>
                                {amountError && <ErrorMsg>{amountError}</ErrorMsg>}
                            </SectionColumnContentShort>
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('取扱方法')}</SectionColumnWrapTitle>
                            <SectionColumnContentRow>
                                <DisabledInput
                                    type="text"
                                    disabled
                                    value={allocationList.find(option => option.value === data?.treatment?.type)?.label || ""}
                                />
                                <SectionColumnContentNote>
                                    <SectionColumnContentNoteItem>{t('＊取扱方法変更する場合配分のタブで設定してください。')}</SectionColumnContentNoteItem>
                                </SectionColumnContentNote>
                            </SectionColumnContentRow>
                        </SectionColumnWrap>
                    </SectionColumn>
                </SectionBody>

                <Title>{t('適合性')}</Title>
                <SectionBody>
                    <SectionColumn>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('情報源のフロー名')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <InputPrimary
                                    type="text"
                                    value={inputCompatibleProductName ?? ''}
                                    onChange={(event) => handleInputChange(event, 'compatible_product_name')}
                                />
                            </SectionColumnContent>
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('適合度')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <SelectPrimary>
                                    <SelectInner
                                        onChange={(event) => handleInputChangeSelect(event, 'compatibility_level')}
                                        value={inputCompatibilityLevel ?? "1"}
                                    >
                                        <option value="1">{t('よい')}</option>
                                        <option value="2">{t('普通')}</option>
                                        <option value="3">{t('悪い')}</option>
                                    </SelectInner>
                                </SelectPrimary>
                            </SectionColumnContent>

                        </SectionColumnWrap>
                    </SectionColumn>
                </SectionBody>

                <Title>{t('品質')}</Title>
                <SectionBody>
                    <SectionColumn>
                        <ProcessIoDataQualityValueTable
                            selectQualityIndicatorsData={selectQualityIndicatorsData}
                            setSelectQualityIndicatorsData={setSelectQualityIndicatorsData}
                            qualityIndicatorsDataList={qualityIndicatorsDataList}
                            setQualityIndicatorsDataList={setQualityIndicatorsDataList}
                        />
                    </SectionColumn>
                </SectionBody>

                <Title>{t('コメント')}</Title>
                <SectionBody>
                    <SectionColumn>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('プライベートコメント')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'private_comment')}
                                    value={inputPrivateComment ?? ''}
                                />
                            </SectionColumnContent>
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('公開コメント')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'public_comment')}
                                    value={inputPublicComment ?? ''}
                                />
                            </SectionColumnContent>
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('データの根拠')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'information_sources')}
                                    value={inputInformationSources ?? ''}
                                />
                            </SectionColumnContent>
                        </SectionColumnWrap>
                        <SectionColumnWrap>
                            <SectionColumnWrapTitle>{t('流量に関するコメント')}</SectionColumnWrapTitle>
                            <SectionColumnContent>
                                <TextArea
                                    name=""
                                    id=""
                                    cols={50}
                                    rows={2}
                                    onChange={(event) => handleInputChangeTextArea(event, 'amount_comment')}
                                    value={inputAmountComment ?? ''}
                                />
                            </SectionColumnContent>
                        </SectionColumnWrap>
                    </SectionColumn>
                </SectionBody>
                <ButtonCreateSectionTabel>
                    <ModalButton onClick={handleClickOK} text={t('変更')} />
                </ButtonCreateSectionTabel>
            </Inner>
        </OutputIntermediateFlowEditContext.Provider>
    )
}

export default OutputIntermediateFlowEditModal

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const SectionBodyLast = styled.div`
`

const SectionColumn = styled.dl`
`

const SectionColumnContent = styled.dd`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const SectionColumnContentRow = styled.dd`
    flex-direction: column;
    gap: 8px 0;
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
`


const SectionColumnContentShort = styled.dd`
    width: min(100%, 340px);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`


const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`

const SectionColumnWrapTitle = styled.dt`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const InputPrimary = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DisabledInput = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const InputPrimaryShort = styled.input<{ disabled?: boolean }>`
    width: 50%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    
    background-color: ${(props) => (props.disabled ? 'var(--color-bg-primary)' : '#fff')};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    &:disabled {
        pointer-events: none;
    }
`


const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectPrimaryShort = styled.label`
    width: 50%;
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 10px 32px 10px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const TextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const SectionColumnContentNote = styled.ul`
`

const SectionColumnContentNoteItem = styled.li`
    position: relative;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`;
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Modal from '@common/modal/Modal';
import SubsystemDivisionEditTable from '@common/table/SubsystemDivisionEditTable';
import { SubsystemCategories } from '@typeList/types';
import ReloadButton from '@common/button/ReloadButton';
import { useTranslation } from 'react-i18next';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { useUpdateSubsystemCategorySet } from '@hooks/useBackendApi';

interface CategoryValueSelectModalType {
    rowId: number;
    rowName: string;
    subsystemCategories: SubsystemCategories[];
}

interface CustomTreeNode {
    key: string;
    title: string;
    children: CustomTreeNode[];
}

/**
 * 068 サブシステムカテゴリセット編集
 * @returns 
 */
const SubsystemDivisionEditModal: React.FC<CategoryValueSelectModalType> = ({ 
    rowId,
    rowName,
    subsystemCategories
}) => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext)
    const {subsystemCategorySetData, setSubsystemCategorySetData, caseStudyData, categorySetRows, setCategorySetRows, resetCaseStudyData} = context;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const { updateSubsystemCategorySet } = useUpdateSubsystemCategorySet();

    const [inputCategorySetName, setInputCategorySetName] = useState<string | null>(rowName);
    const handleInputCategorySetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputCategorySetName(event.target.value);
    }

    const [inputCategoryNameError, setInputCategoryNameError] = useState<any>(null);

    const handleUpdate = async (tableData: any[]) => {
        // rowIdに対応するデータを見つける
        const updatedData = subsystemCategorySetData?.filter(item => item.id === rowId).map((item) => {
            return {
                ...item,
                name: inputCategorySetName,
                case_study_id: caseStudyData?.id,
                subsystem_categories: tableData // ここでsubsystem_categoriesを更新
            };
        });

        setInputCategoryNameError(null)
        const invalidEntries = tableData.filter(item => !item.name || item.name.trim() === "");
        if (invalidEntries.length > 0) {
            setInputCategoryNameError(t('カテゴリ名が空欄のデータがあります。'));
            return;
        }
        
        if (updatedData && updatedData.length > 0) {
           
            try {
                // rowIdと一致するデータのみをサーバーに送信
                await updateSubsystemCategorySet(caseStudyData?.id || 0, updatedData[0])
                // // 更新されたデータをセットする
                // const updatedCategorySetRows = categorySetRows.map(row => {
                //     if (row.inputCategoryId === rowId) {
                //         // Update selectedCategoryValue with subsystem_categories.name joined by commas
                //         const newSelectedCategoryValue = updatedData[0].subsystem_categories
                //             .map(category => category.name)
                //             .join(', ');
                //         return { ...row, selectedCategoryValue: newSelectedCategoryValue };
                //     }

                //     return row;
                // });

                // setCategorySetRows(updatedCategorySetRows);
                setIsModalOpen(false)
                await resetCaseStudyData();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            <Button type='button' onClick={openModal} key={`category-value-select-${rowId}`}><ButtonText>{t('変更')}</ButtonText></Button>
            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={closeModal} key={`category-value-modal-${rowId}`}>
                    <Inner>
                        <Span>{t('カテゴリセット名')}</Span>
                        <Input
                            type="text"
                            disabled
                            value={inputCategorySetName ?? ""}
                            onChange={handleInputCategorySetNameChange}
                        />
                        {inputCategoryNameError && <ErrorMsg>{inputCategoryNameError}</ErrorMsg>}
                        <SubsystemDivisionEditTable data={subsystemCategories} paging_flag={false} onUpdate={handleUpdate}/>
                        {/* <ButtonReloadSectionTabel>
                            <ReloadButton onClick={handleUpdate} text={t('更新する')} />
                        </ButtonReloadSectionTabel>   */}
                    </Inner>
                </Modal>
            )}
        </>
    );
};

export default SubsystemDivisionEditModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const StyledSelect = styled.select`
    display: flex;
    gap: 10px;
`;

// 追加のCSSスタイル
const styles = `
.custom-tree-category .rc-tree-switcher_close:before {
    content: "";
}
.custom-tree-category .rc-tree-switcher_open:before {
    content: "";
}
.rc-tree .rc-tree-treenode span.rc-tree-switcher, 
.rc-tree .rc-tree-treenode span.rc-tree-checkbox, 
.rc-tree .rc-tree-treenode span.rc-tree-iconEle {
    background-image: none;
}
`;

// スタイルタグを追加してCSSを適用
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const Button = styled.button`
padding: 14px 30px;
border-radius: 4px;
white-space: nowrap;
background-color: var(--color-site-secondary);
display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
gap: 0 4px;
border: none;

&:hover {
    background-color: var(--color-gray--01);
    span {
        color: black;
    }
}
`

const ButtonText = styled.span`
font-size: 15px;
color: #fff;
ont-weight: 500;
`

const ButtonReloadSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const Input = styled.input`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
margin-bottom: 10px;
background: transparent;
appearance: none;
font: inherit;
outline: none;
}
`

const Span = styled.span`
font-size: 16px;
margin-right: 10px;
`

const ErrorMsg = styled.span`
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    gap: 5px;
`
import ReviewLevelTypeTable from '@common/table/CaseStudyReviewLevelTypeTable';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView'
import { ReviewLevelType, UpdateCaseStudy } from '@typeList/types';
import React, { useContext, useEffect } from 'react'
import { Control, Controller, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import SubsystemCategorySelect from '../case_study_info/SubsystemCategorySelect';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface FormProps {
    register: UseFormRegister<UpdateCaseStudy>;
    errors: FieldErrors<UpdateCaseStudy>;
    control: Control<UpdateCaseStudy, any>;
    watch: UseFormWatch<UpdateCaseStudy>;
}

/**
 * 033　ケーススタディ情報入力画面
 * @returns 
 */
const CaseStudyInfo: React.FC<FormProps> = ({ watch, control, register, errors }) => {
    const {t} = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const {
        caseStudyData,
        reviewLevelTypeData,
        selectReviewLevelData,
        reviewLevelsDataList,
        setSelectReviewLevelData,
        setReviewLevelsDataList,
        setChangeCheckInfo,
        userData
    } = context;


    // レビュータイプの初期設定
    useEffect(() => {
        if (caseStudyData?.case_study_review_level_values
            && reviewLevelTypeData
            && !selectReviewLevelData
            && !reviewLevelsDataList
        ) {
            const matchedData = caseStudyData.case_study_review_level_values.map((leviewValue) => {
                return reviewLevelTypeData.find((review_type) => review_type.id === leviewValue.review_type_id);
            }).filter(Boolean);
            setSelectReviewLevelData(caseStudyData?.case_study_review_level_values);
            setReviewLevelsDataList(matchedData as ReviewLevelType[]);
        }
    }, [caseStudyData, reviewLevelTypeData, setReviewLevelsDataList]);

    // 変更監視
    const watchedName = watch('name', caseStudyData?.name || '');
    const watchedTags = watch('tags', caseStudyData?.tags || '');
    const watchedConductorName = watch('conductor_name', caseStudyData?.conductor_name || '');
    const watchedGoal = watch('goal', caseStudyData?.goal || '');
    const watchedFunctionalUnit = watch('functional_unit', caseStudyData?.functional_unit || '');
    const watchedSummary = watch('summary', caseStudyData?.summary || '');

    useEffect(() => {
        if (
            watchedName !== (caseStudyData?.name ?? '') 
            || watchedTags !== (caseStudyData?.tags ?? '') 
            || watchedConductorName !== (caseStudyData?.conductor_name ?? '')
            || watchedGoal !== (caseStudyData?.goal ?? '')
            || watchedFunctionalUnit  !== (caseStudyData?.functional_unit ?? '')
            || watchedSummary  !== (caseStudyData?.summary ?? '')
        ) {
            setChangeCheckInfo(true);
        }
    }, [
        caseStudyData, watchedName, watchedTags, watchedConductorName, watchedGoal,
        watchedFunctionalUnit, watchedSummary
    ])

    return (
        <SectionMiddle>
            <DataSecondary>
                <DataSecondaryTitle>{t('UUID')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <DisableInput type="text" disabled value={caseStudyData?.global_id} />
                </DataSecondaryContent>
                <DataSecondaryTitle>{t('ケーススタディ名')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: t('ケーススタディ名は必須です') }}
                        defaultValue={caseStudyData?.name || ''}
                        render={({ field }) => (
                            <InputPrimary
                                type="text"
                                {...field}
                            />
                        )}
                    />
                </DataSecondaryContent>
                <DataSecondaryTitle>{t('チームUUID')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <DisableInput disabled type="text" />
                </DataSecondaryContent>
                <DataSecondaryTitle>{t('実施者')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <Controller
                        name="conductor_name"
                        control={control}
                        defaultValue={caseStudyData?.conductor_name || ''}
                        render={({ field }) => (
                            <InputPrimary
                                type="text"
                                {...field}
                                value={field.value || ''}
                            />
                        )}
                    />
                </DataSecondaryContent>
                <DataSecondaryTitle>{t('LCAの目的')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <Controller
                        name="goal"
                        control={control}
                        defaultValue={caseStudyData?.goal || ''}
                        render={({ field }) => (
                            <TextArea
                                cols={100}
                                rows={3}
                                {...field}
                                value={field.value || ''}
                            />
                        )}
                    />
                </DataSecondaryContent>
                <DataSecondaryTitle>{t('機能単位')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <Controller
                        name="functional_unit"
                        control={control}
                        defaultValue={caseStudyData?.functional_unit || ''}
                        render={({ field }) => (
                            <TextArea
                                cols={100}
                                rows={3}
                                {...field}
                                value={field.value || ''}
                            />
                        )}
                    />
                </DataSecondaryContent>
                <DataSecondaryTitle>{t('備考')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <Controller
                        name="summary"
                        control={control}
                        defaultValue={caseStudyData?.summary || ''}
                        render={({ field }) => (
                            <Textarea
                                cols={100}
                                rows={5}
                                {...field}
                                value={field.value || ''}
                            />
                        )}
                    />
                </DataSecondaryContent>
            </DataSecondary>
            {userData?.is_admin &&
                <DataSecondary>
                    <DataSecondaryTitle>[SystemTest]{t('レビューレベル')}</DataSecondaryTitle>
                    {reviewLevelTypeData && (
                        <DataSecondaryContent>
                            <ReviewLevelTypeTable
                                selectReviewLevelData={selectReviewLevelData}
                                setSelectReviewLevelData={setSelectReviewLevelData}
                                reviewLevelsDataList={reviewLevelsDataList}
                                setReviewLevelsDataList={setReviewLevelsDataList}
                            />
                        </DataSecondaryContent>
                    )}
                </DataSecondary>
            }
            <DataSecondary>
                <DataSecondaryTitle>{t('サブシステムカテゴリ')}</DataSecondaryTitle>
                <DataSecondaryContent>
                    <SubsystemCategorySelect/>
                </DataSecondaryContent>
            </DataSecondary>
            <DataSecondaryLast>
                <DataSecondaryTitle>{t('タグ')}</DataSecondaryTitle>
                <DataSecondaryContent>
                <Controller
                    name="tags"
                    control={control}
                    defaultValue={caseStudyData?.tags || ''}
                    render={({ field }) => (
                        <InputPrimary
                            type="text"
                            {...field}
                            value={field.value || ''}
                        />
                    )}
                    />
                </DataSecondaryContent>
            </DataSecondaryLast>
        </SectionMiddle>
    )
}

export default CaseStudyInfo

const SectionMiddle = styled.section`
        width: max(53.47222222vw, 770px);
`

const DataSecondary = styled.dl`
    margin-bottom: 40px;
    display: grid;
    gap: 8px 40px;
    grid-template-columns: 1fr 69%;
`

const DataSecondaryLast = styled.dl`
    margin-bottom: 40px;
    display: grid;
    gap: 8px 40px;
    grid-template-columns: 1fr 69%;
`

const DataSecondaryTitle = styled.dt`
    font-size: 12px;
    font-weight: 500;
    padding: 12px 0;
`

const DataSecondaryContent = styled.dd`
`

const InputPrimary = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DisableInput = styled.input`
    background-color: var(--color-bg-primary);
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const Textarea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const TextArea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`
import React, { useContext, useEffect, useState } from 'react';
import Tree, { TreeProps, BasicDataNode } from 'rc-tree';
import { useGetUpperProcessById } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { getRequest } from '@services/api/api';
import { UpperProcess } from '@typeList/types';
import 'rc-tree/assets/index.css';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InsertDriveFile } from '@mui/icons-material';

import iconAdd from '@images/text_tree/icon_add.svg'
import iconClose from '@images/text_tree/icon_close.svg'

import iconArea from '@images/text_tree/icon_area.svg'
import iconFile from '@images/text_tree/icon_file.svg'
import iconRemainder from '@images/text_tree/icon_remainder.svg'
import iconRoop from '@images/text_tree/icon_roop.svg'
import { useTranslation } from 'react-i18next';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';


interface CustomTreeNode extends BasicDataNode {
    key: string;
    title: React.ReactNode;
    children: CustomTreeNode[] | null;
    id?: number;
    name?: string;
}


/**
 * ノードのタイトル
 * @param param0 
 * @returns 
 */
const CustomTitle = ({ title, icon, hasChildren }: { title: string; icon: React.ReactNode | null; hasChildren: boolean }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{title}</span>
        {hasChildren ? null : <span style={{ marginLeft: '8px' }}>
            <IconRemainder />
        </span>}
        {icon && <span style={{ marginLeft: '8px' }}>{icon}</span>}
    </div>
);


/**
 * ツリー内既出
 * @param nodes 
 * @param id 
 * @returns 
 */
const checkIfNodeExistsInTree = (nodes: CustomTreeNode[], title: string): boolean => {
    let count = 0;

    const countMatchingTitles = (nodes: CustomTreeNode[]): void => {
        for (const node of nodes) {
            if (node.name === title) {
                count++;
            }
            if (node.children && node.children.length > 0) {
                countMatchingTitles(node.children); // 再帰的に子ノードをチェック
            }
            if (count >= 2) return; // 2件以上見つかった場合は早期終了
        }
    };

    countMatchingTitles(nodes);
    return count >= 2;
};

// const checkIfNodeExistsInTree = (nodes: CustomTreeNode[], id: string): boolean => {
//     for (const node of nodes) {
//         console.log("id", id)
//         console.log("node", node)
//         if (node.key === id) return true;
//         if (node.children.length > 0 && checkIfNodeExistsInTree(node.children, id)) return true;
//     }
//     return false;
// };

/**
 * エンドレスループチェック
 * @param id 
 * @param inputs 
 * @returns 
 */
const checkIfNodeExistsInChildren = (id: number | undefined, inputs: any[]): boolean => {
    if (!id) return false;
    for (const input of inputs) {
        if (input.upper_output?.process.id === id) return true;
        if (input.upper_output?.process.process_inputs && checkIfNodeExistsInChildren(id, input.upper_output?.process.process_inputs)) return true;
    }
    return false;
};

/**
 * // 展開済みのノードキーを保存するSet
 */
const expandedNodeKeys = new Set<string>();

const TextTreeDisplay_old = () => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const processId = context.processData?.id || 0;

    const { getUpperProcess, upperProcessData, upperProcessLoading, upperProcessError } = useGetUpperProcessById();
    const [data, setData] = useState<CustomTreeNode[]>([]);
    useEffect(() => {
        console.log("data", data)
    }, [data])

    useEffect(() => {
        try {
            getUpperProcess(processId);
        } catch (error) {
            console.log(error);
        }
    }, [processId]);

    useEffect(() => {
        if (upperProcessData) {

            const childrenData = upperProcessData.process_inputs
                .filter((input) => input.upper_output !== null) // upper_outputがnullの場合は除外
                .map((input) => {
                    const childId = input.upper_output?.process.id.toString() || '';
                    const childIcons: React.ReactNode[] = [];

                    // 現在のノード自身を除外して、子孫に同じIDのプロセスがあるか確認
                    const filteredInputs = upperProcessData.process_inputs.filter(
                        (item) => item.upper_output?.process.id !== input.upper_output?.process.id
                    );

                    if (checkIfNodeExistsInChildren(input.upper_output?.process.id, filteredInputs)) {
                        childIcons.push(<IconRoop />);
                    }

                    const title_name = input.upper_output?.process.name ?? "";
                    const childNode = {
                        key: childId,
                        title: (
                            <CustomTitle
                                title={title_name + '(' + input.upper_output?.amount + input.upper_output?.unit.name + ')'}
                                icon={childIcons}
                                hasChildren={true} // デフォルトは子要素あり、展開時に再度判定される
                            />
                        ),
                        children: [],
                        id: input.upper_output?.process.id,
                        name: input.upper_output?.process.name
                    };
                    return childNode;
                });


            const initialData: CustomTreeNode = {
                key: upperProcessData.id.toString(),
                title: (
                    <CustomTitle
                        title={upperProcessData.name}
                        icon={null}
                        hasChildren={true} // 子要素があるかを判定
                    />
                ),
                children: childrenData
            };

            setData([initialData]);
        }
    }, [upperProcessData]);

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const handleExpand: TreeProps<CustomTreeNode>['onExpand'] = async (newExpandedKeys, { node }) => {
        // const isExpanding = newExpandedKeys.length > expandedKeys.length;

        // 展開が増加している場合のみデータを取得
        // if (isExpanding 
        //     && node.key 
        //     && !expandedNodeKeys.has(node.key)
        // ) {
            expandedNodeKeys.add(node.key);

            if (node.children && node.children.length !== 0) {
                const updatedChildrenPromises = node.children.map(async (child) => {
                    try {
                        const response = await getRequest<UpperProcess>(`/processes/${child.id}/upper_process`);
                        if (response) {
                            const newChildren: CustomTreeNode[] = response.process_inputs
                                .filter((input) => input.upper_output !== null)
                                .map((input, index) => {
                                    // const childId = input.upper_output?.process.id.toString() || '';
                                    const childId = `${response.id}-${input.upper_output?.process.id}-${index}`;
                                    const childIcons: React.ReactNode[] = [];

                                    const filteredInputs = response.process_inputs.filter(
                                        (item) => item.upper_output?.process.id !== input.upper_output?.process.id
                                    );

                                    if (checkIfNodeExistsInChildren(input.upper_output?.process.id, filteredInputs)) {
                                        childIcons.push(<IconRoop />);
                                    }

                                    const title_name = input.upper_output?.process.name ?? "";
                                    if (checkIfNodeExistsInTree(data, title_name)) {
                                        childIcons.push(<IconFile />);
                                    }

                                    const hasChildren = !input.upper_output;

                                    return {
                                        key: childId,
                                        title: (
                                            <CustomTitle
                                                title={`${title_name} (${input.upper_output!.amount}${input.upper_output!.unit.name})`}
                                                icon={childIcons}
                                                hasChildren={hasChildren}
                                            />
                                        ),
                                        children: [],
                                        id: input.upper_output?.process.id,
                                        name: input.upper_output?.process.name
                                    };
                                });
                            return { ...child, children: newChildren };
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    return child;
                });

                const updatedChildren = await Promise.all(updatedChildrenPromises);

                const updateData = (nodes: CustomTreeNode[]): CustomTreeNode[] => {
                    return nodes.map((item) => {
                        if (item.key === node.key && item.children) {
                            const titleText =
                                item.name || (React.isValidElement(item.title) && item.title.props.title) || '';
                            const hasChildren = item.children.length > 0;
                            console.log("item", item)

                            const childIcons: React.ReactNode[] = [];
                            const filteredInputs = item.children.filter(
                                (child) => child.id !== item.id
                            );

                            if (checkIfNodeExistsInChildren(item.id, filteredInputs)) {
                                childIcons.push(<IconRoop />);
                            }

                            if (checkIfNodeExistsInTree(data, titleText)) {
                                childIcons.push(<IconFile />);
                            }

                            return {
                                ...item,
                                children: updatedChildren,
                                title: (
                                    <CustomTitle
                                        title={`${titleText}`}
                                        icon={childIcons}
                                        hasChildren={hasChildren}
                                    />
                                ),
                            };
                        }
                        if (item.children && item.children.length > 0) {
                            return { ...item, children: updateData(item.children) };
                        }
                        return item;
                    });
                };

                const updatedData = updateData(data);
                setData(updatedData);
            }
        // } else if (!isExpanding) {
        //     // ノードが閉じられたときは子ノードを `null` にして展開アイコンを維持
        //     const updateDataToClearChildren = (nodes: CustomTreeNode[]): CustomTreeNode[] => {
        //         return nodes.map((item) => {
        //             if (item.key === node.key) {
        //                 return { ...item, children: null };  // ここで children を null にする
        //             }
        //             if (item.children && item.children.length > 0) {
        //                 return { ...item, children: updateDataToClearChildren(item.children) };
        //             }
        //             return item;
        //         });
        //     };

        //     const clearedData = updateDataToClearChildren(data);
        //     setData(clearedData);
        // }

        // expandedKeys を更新
        setExpandedKeys(newExpandedKeys.map(key => key.toString()));
    };



    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [selectedKeysError, setSelectedKeysError] = useState<any>(null);
    const onSelect = (selectedKeys: any, info: any) => {
        setSelectedKeys(selectedKeys);
    };

    const navigate = useNavigate();
    const handleClickSelectProcess = async () => {
        if (selectedKeys.length > 0) {
            navigate(`/process/process_view/${selectedKeys[0]}`);
        }
    };

    return (
        <Section>
            <Wrap>
                <Grid>
                    <TreeMain>
                        {upperProcessLoading &&
                            <FadeLoader
                                color="#48bdbb"
                                height={10}
                                radius={2}
                                width={5}
                            />
                        }
                        {upperProcessError && <ErrorMsg> {upperProcessError}</ErrorMsg>}
                        {selectedKeysError && <ErrorMsg>{selectedKeysError}</ErrorMsg>}
                        <Tree
                            treeData={data}
                            onExpand={handleExpand}
                            showLine={true}
                            selectedKeys={selectedKeys}
                            onSelect={onSelect}
                            className="custom-tree-prosess"
                        />
                    </TreeMain>
                    <SelectButton
                        type='button'
                        onClick={handleClickSelectProcess}
                        disabled={selectedKeys.length === 0}
                    >
                        {t('選択プロセスを表示')}
                    </SelectButton>
                    <TreeSide>
                        <Ul>
                            <LiRoop> {t('エンドレスループ検出')}</LiRoop>
                            <LiRemainder> {t('リマインダーフロー')}</LiRemainder>
                            <LiFile> {t('ツリー内既出')}</LiFile>
                        </Ul>
                    </TreeSide>

                </Grid>
            </Wrap>
        </Section>
    );
};

export default TextTreeDisplay_old;

const Ul = styled.ul`
    list-style: none;
`;

const LiRoop = styled.li`
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-left: 24px;

    &:before {
    iconRoop
        mask: url(${iconRoop}) no-repeat center center / contain;
        -webkit-mask: url(${iconRoop}) no-repeat center center / contain;
        background: #1AA11F;

        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
    }
`;

const LiRemainder = styled.li`
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-left: 24px;

    &:before {
    iconRoop
        mask: url(${iconRemainder}) no-repeat center center / contain;
        -webkit-mask: url(${iconRemainder}) no-repeat center center / contain;
        background-color: var(--color-orange-01);

        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
    }
`;

const LiArea = styled.li`
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-left: 24px;

    &:before {
        mask: url(${iconArea}) no-repeat center center / contain;
        -webkit-mask: url(${iconArea}) no-repeat center center / contain;
        background: var(--color-blue-01);

        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
    }
`;

const LiFile = styled.li`
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-left: 24px;

    &:before {
        background: url(${iconFile}) no-repeat center center / contain;

        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
    }
`;



const styles = `
    .custom-tree-prosess .rc-tree-switcher_close:before {
        width: 10px;
        height: 10px;
        background-image: url(${iconAdd});
        background-size: 10px;
        content: "";
    }
    .custom-tree-prosess .rc-tree-switcher_open:before {
        width: 10px;
        height: 2px;
        background-image: url(${iconClose});
        background-size: 10px 2px;
        content: "";
    }
    .rc-tree .rc-tree-treenode span.rc-tree-switcher, 
    .rc-tree .rc-tree-treenode span.rc-tree-checkbox, 
    .rc-tree .rc-tree-treenode span.rc-tree-iconEle {
        background-image: none;
    }

    .custom-tree-prosess  .rc-tree-switcher_open {
        display: grid !important;
        width: 20px !important;
        height: 20px !important;
        margin-right: 8px !important;
        background-image: none !important;
        place-content: center !important;
        border: 2px solid var(--color-line-primary) !important;
        background-color: #fff !important;
        border-radius: 50% !important;

        background-position: -75px -56px;
    }

    .custom-tree-prosess .rc-tree-switcher_close {
        display: grid !important;
        width: 20px !important;
        height: 20px !important;
        margin-right: 8px !important;
        background-image: none !important;
        place-content: center !important;
        border: 2px solid var(--color-line-primary) !important;
        background-color: #fff !important;
        border-radius: 50% !important;
    }

    .rc-tree .rc-tree-treenode {
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .custom-tree-prosess .rc-tree-iconEle {
        display: none !important;
    }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);


const Section = styled.section`
`

const Wrap = styled.div`
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: max(63.008%, 700px) 1fr;
    grid-template-rows: 27px 1fr;
    gap: 24px 40px;
`

const TreeMain = styled.div`
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    min-height: 60vh;
    padding: 40px;
    background-color: #fff;
    overflow-y: auto;
`

const SelectButton = styled.button`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-size: 12px;
    font-weight: 500;
    width: 140px;
    height: 27px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-gray--01);
    gap: 0 4px;
    padding: 8px 24px;
    border-radius: 20px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;

    &:hover {
        color: #fff;
        background-color: var(--color-site-secondary);
}
`

const TreeSide = styled.div`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: start;
    width: fit-content;
    padding: 24px;
    background-color: #fff;
`

const IconRemainder = styled.span`
    mask: url(${iconRemainder}) no-repeat center center / contain;
    -webkit-mask: url(${iconRemainder}) no-repeat center center / contain;
    background-color: var(--color-orange-01);
    display: block;
    width: 20px;
    height: 20px;
`;

const IconRoop = styled.span`
    mask: url(${iconRoop}) no-repeat center center / contain;
    -webkit-mask: url(${iconRoop}) no-repeat center center / contain;
    background-color: #1AA11F;
    display: block;
    width: 20px;
    height: 20px;
`;

const IconFile = styled.span`
    mask: url(${iconFile}) no-repeat center center / contain;
    -webkit-mask: url(${iconFile}) no-repeat center center / contain;
    background-color: var(--color-gray--01);
    display: block;
    width: 20px;
    height: 20px;
`;
import { Subsystems } from '@typeList/types';
import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { NodeProps, Handle, Position } from 'reactflow';
import styled from 'styled-components';
import SubsystemEdit from './SubsystemEdit';
import SubsystemList from './SubsystemList';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import SubsystemInputProductSearchModal from '../product_search/input_Intermediate_flow/SubsystemInputProductSearchModal';
import SubsystemCaseStudyProductCreateModal from '../subsystem_case_study_product/SubsystemCaseStudyProductCreateModal';
import SubsystemCaseStudyProductEditModal from '../subsystem_case_study_product/SubsystemCaseStudyProductEditModal';
import SubsystemInputProductEditModal from '../subsystem_input_product/SubsystemInputProductEditModal';
import SubsystemInputElementaryFlowSearchModal from '../elementary_flow_search/SubsystemInputElementaryFlowSearchModal';
import SubsystemOutputElementaryFlowSearchModal from '../elementary_flow_search/SubsystemOutputElementaryFlowSearchModal';
import SubsystemElementaryFlowEditModal from '../subsystem_input_elementary_flow/SubsystemElementaryFlowEditModal';
import UpstreamSubsystemModal from './UpstreamSubsystemModal';
import DownstreamSubsystemModal from './DownstreamSubsystemModal';

import iconAdd from '@images/product_system/icon_add.svg'
import iconArrowTop from '@images/product_system/icon_arrow_top_right.svg'
import iconEdit from '@images/product_system/icon_edit.svg'
import iconCancel from '@images/product_system/icon_cancel.svg'
import iconArrowBack from '@images/product_system/icon_arrow_back.svg'
import UpperOutputUpstreamSubsystem from './UpperOutputUpstreamSubsystem';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@utils/utils';

interface CustomNodeSubsystems extends Subsystems {
  borderBottomColor: string;
}

interface CustomNodeProps extends NodeProps {
  data: CustomNodeSubsystems;
}

const CustomNodeComponent: React.FC<CustomNodeProps> = ({ data }) => {
  const {t} = useTranslation();
  const content = useContext(CaseStudyViewContext);
  const { userData } = content;
  const { nodes, setNodes, flowState, setFlowState, edges, setEdges, selectCategorySet, subsystemCategorySetData } = useContext(CaseStudyViewContext);

  const [menuVisible, setMenuVisible] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [flowListDialogVisible, setFlowListDialogVisible] = useState(false);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [outputContextMenuVisible, setOutputContextMenuVisible] = useState(false);

  const [inputProductSearchModalVisible, setInputProductSearchModalVisible] = useState(false);
  const [createInputModalVisible, setCreateInputModalVisible] = useState(false);
  const [createOutputModalVisible, setCreateOutputModalVisible] = useState(false);
  const [elementaryFlowSearchModalVisible, setElementaryFlowSearchModalVisible] = useState(false);
  const [outputElementaryFlowSearchModalVisible, setOutputElementaryFlowSearchModalVisible] = useState(false);


  // 上流・下流接続モーダルの開閉ステート
  const [upstreamSubsystemModalVisible, setUpstreamSubsystemModalVisible] = useState(false);
  const [downstreamSubsystemModalVisible, setDownstreamSubsystemModalVisible] = useState(false);

  const [showAllInputs, setShowAllInputs] = useState(false);
  const [showAllOutputs, setShowAllOutputs] = useState(false);

  const [currentNodeData, setCurrentNodeData] = useState<any>()
  // const currentNodeData = useMemo(() => flowState.nodes.find(node => node.data.id === String(data.id))?.data, [flowState.nodes, data.id]);
  
  useEffect(() => {
    if (flowState.nodes.length > 0) {
        const currentNode = flowState.nodes.find(node => String(node.data.id) === String(data.id));
        if (currentNode) {
            setCurrentNodeData(currentNode.data);
        }
    }
  }, [flowState.nodes]);

  const contextMenuRef = useRef<HTMLDivElement | null>(null);
  const outputContextMenuRef = useRef<HTMLDivElement | null>(null);
  const nodeBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // handleClickOutside関数をuseEffect内で定義する
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      // NodeBoxの外側をクリックした場合にメニューを閉じる
      if (
        !contextMenuRef.current?.contains(target) &&
        !outputContextMenuRef.current?.contains(target) &&
        !nodeBoxRef.current?.contains(target)
      ) {
        setMenuVisible(false);
        setContextMenuVisible(false);
        setOutputContextMenuVisible(false);
      }
    };

    // NodeBoxごとに個別のリスナーを追加
    document.addEventListener('click', handleClickOutside);

    // クリーンアップ関数としてリスナーを削除
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [nodeBoxRef, contextMenuRef, outputContextMenuRef]); // 依存配列にリファレンスを追加

  const inputs = useMemo(() => currentNodeData?.subsystem_ios.filter((io: any) => io.direction === 'in') || [], [currentNodeData]);
  const outputs = useMemo(() => currentNodeData?.subsystem_ios.filter((io: any) => io.direction === 'out') || [], [currentNodeData]);

  const toggleMenu = () => { setMenuVisible(prev => !prev);  setContextMenuVisible(false); setOutputContextMenuVisible(false)};
  const toggleContextMenu = () => { setContextMenuVisible(prev => !prev); setMenuVisible(false); setOutputContextMenuVisible(false); };
  const toggleOutputContextMenu = () => { setOutputContextMenuVisible(prev => !prev); setMenuVisible(false);  setContextMenuVisible(false); }

  // サブシステム編集
  const openEditDialog = () => {
    setEditDialogVisible(true);
    setMenuVisible(false);
  };

  // サブシステムのフロー一覧
  const openFlowListDialog = () => {
    setFlowListDialogVisible(true);
    setMenuVisible(false);
  };

  // サブシステム削除
  const deleteSubsystem = () => {
    const updatedNodes = flowState.nodes.filter(node => String(node.data.id) !== String(data.id));
    setNodes(updatedNodes);
    setFlowState(prev => ({
      ...prev,
      nodes: updatedNodes,
    }));
    setMenuVisible(false);
  };

  // サブシステムの保存
  const handleSave = useCallback((updatedData: Subsystems) => {
    const updatedNodes = flowState.nodes.map(node =>
      String(node.data.id) === String(data.id) ? { ...node, data: updatedData } : node
    );
    setFlowState(prev => ({
      ...prev,
      nodes: updatedNodes,
    }));
  }, [flowState.nodes, data.id, setFlowState]);

  // サブシステムのフローの編集
  const handleEditSave = useCallback((updatedIO: any) => {
    // console.log("updatedIO", updatedIO)
    const updatedNodes = flowState.nodes.map(node => {
      if (String(node.data.id) === String(data.id)) {
        const updatedSubsystemIos = node.data.subsystem_ios.map((io: any) =>
          io.process_io_no === updatedIO.process_io_no ? updatedIO : io
        );
        return {
          ...node,
          data: {
            ...node.data,
            subsystem_ios: updatedSubsystemIos
          }
        }
      }
      return node;
    });
    setFlowState(prev => ({
      ...prev,
      nodes: updatedNodes,
    }));
  }, [flowState.nodes, data.id, setFlowState]);


  // サブシステムのフローの削除
  const handleDeleteClick = (io: any) => {
    const updatedNodes = flowState.nodes.map(node => {
      if (String(node.data.id) === String(data.id)) {
        const updatedSubsystemIos = node.data.subsystem_ios.filter((item: any) => item.process_io_no !== io.process_io_no);
        return {
          ...node,
          data: {
            ...node.data,
            subsystem_ios: updatedSubsystemIos
          }
        }
      }
      return node;
    });

    // エッジが結ばれていたら削除
    const updatedEdges = flowState.edges.filter(edge => {
      if (io.direction === 'in') {
        return edge.targetHandle !== `input-${io.process_io_no}`;
      } else if (io.direction === 'out') {
        return edge.sourceHandle !== `output-${io.process_io_no}`;
      }
      return false;
    });

    setNodes(updatedNodes);
    setEdges(updatedEdges);
    setFlowState(prev => ({
      ...prev,
      nodes: updatedNodes,
      edges: updatedEdges,
    }));
  };

  // 上流接続のモーダル表示
  const openUpstreamSubsystemModal = () => {
    setUpstreamSubsystemModalVisible(true);
    setContextMenuVisible(false);
  };
  // 下流接続のモーダル表示
  const openDownstreamSubsystemModal = () => {
    setDownstreamSubsystemModalVisible(true);
    setOutputContextMenuVisible(false);
  };

  // 初期の color ステートを data.borderBottomColor で設定
  const [color, setColor] = useState<string>(data.borderBottomColor);

  // selectCategorySet の変化を監視し、borderBottomColor を変更
  useEffect(() => {
    if (selectCategorySet) {
      // selectCategorySet に一致するカテゴリセットを取得
      const matchingCategorySet = subsystemCategorySetData?.find(
        (categorySet) => categorySet.id === selectCategorySet
      );

      // 選択されているカテゴリのカラーを取得
      const selectedCategory = matchingCategorySet?.subsystem_categories.find(
        (category) => data.subsystem_category_ids.includes(category.id)
      );

      // カラーが見つかった場合は color を更新
      if (selectedCategory && selectedCategory.color) {
        setColor(selectedCategory.color);
      } else {
        if(matchingCategorySet && matchingCategorySet.subsystem_categories.length > 0) {
          setColor(matchingCategorySet?.subsystem_categories[0].color)
        }
      }
    }
  }, [selectCategorySet, subsystemCategorySetData, data.subsystem_category_ids]);

  if (!currentNodeData) return null;


  return (
    <NodeBox ref={nodeBoxRef}>
      {/* <NodeBoxInner borderBottomColor={color}> */}
      <NodeBoxInner $borderColor={color}>
        <SubSystemName>
          <NodeName>
            {currentNodeData.name}
          </NodeName>

          <NodeBoxEditButton type='button' onClick={toggleMenu}>
            <div>
              <NodeBoxEditButtonIcon></NodeBoxEditButtonIcon>
            </div>
          </NodeBoxEditButton>
          {menuVisible && (
            <Menu ref={contextMenuRef}>
              <MenuItem onClick={openEditDialog}>{t('このサブシステムを編集')}</MenuItem>
              <MenuItem onClick={openFlowListDialog}>{t('このサブシステムのフロー一覧')}</MenuItem>
              <MenuItem onClick={deleteSubsystem}>{t('このサブシステムを削除')}</MenuItem>
            </Menu>
          )}
        </SubSystemName>

        <StyledTable>
          <thead>
            <NodeBoxFlowTitle>
              <Th>
                {t('入力')}
                <AddButton type='button' onClick={toggleContextMenu}>
                  <NodeBoxFlowButtonIconDiv>
                    <NodeBoxFlowButtonIcon></NodeBoxFlowButtonIcon>
                  </NodeBoxFlowButtonIconDiv>
                </AddButton>
                </Th>
                {contextMenuVisible && (
                  <ContextMenu ref={contextMenuRef}>
                  <ContextMenuItem onClick={() => { setInputProductSearchModalVisible(true); setContextMenuVisible(false); }}>
                    {t('製品（DB登録済み）を入力として追加')}
                    </ContextMenuItem>
                    <ContextMenuItem onClick={() => { setCreateInputModalVisible(true); setContextMenuVisible(false); }}>
                    {t('製品（DB未登録）を入力として追加')}
                    </ContextMenuItem>
                    <ContextMenuItem onClick={openUpstreamSubsystemModal}>{t('上流のサブシステムと接続して追加')}</ContextMenuItem>
                    <ContextMenuItem onClick={() => { setElementaryFlowSearchModalVisible(true); setContextMenuVisible(false); }}>
                      {t('入力基本フロー（天然資源）を追加')}
                    </ContextMenuItem>
                    </ContextMenu>
                )}
            </NodeBoxFlowTitle>
          </thead>


          <tbody>
            {(showAllInputs ? inputs : inputs.slice(0, 10)).map((input: any, index: any) => (
              <NodeBoxContent key={index}>
                <StyledLeftTd>
                    {input.type === 'defined_product' && 
                      <UpperOutputUpstreamSubsystem input={input} currentNodeData={currentNodeData} nodeBoxRef={nodeBoxRef} type={"node"}/>
                    }
                    {input.type === 'case_study_product' &&
                    <NodeBoxDlButton type='button'>
                      <NodeBoxDirectionButtonIcon></NodeBoxDirectionButtonIcon>
                    </NodeBoxDlButton> 
                    }
                    {input.type === 'elementary_flow' &&
                    <NodeBoxDlButton type='button'>
                    </NodeBoxDlButton> 
                    }
                  <Handle type="target" position={Position.Left} id={`input-${input.process_io_no}`} />
                </StyledLeftTd>

                <StyledNameTd>
                  <NodeBoxDlText>{input.exchange.name}</NodeBoxDlText>
                </StyledNameTd>
                <StyledUnitTd>
                  <NodeBoxDlUnit>
                    {formatNumber(
                      input.amount,  
                      userData?.significant_figures ?? 2,
                      userData?.use_decimal_notation ?? false,
                      userData?.decimal_places ?? 2
                      ) || null
                    }
                    {input.unit.name}
                  </NodeBoxDlUnit>
                </StyledUnitTd>
                <StyledTd>
                  {input.type === 'case_study_product' &&
                    <SubsystemCaseStudyProductEditModal
                      subsystemName={currentNodeData.name}
                      currentNodeData={input}
                      direction={"in"}
                      onSave={handleEditSave}
                    />
                  }
                  {input.type === 'defined_product' &&
                    <SubsystemInputProductEditModal
                      subsystemName={currentNodeData.name}
                      currentNodeData={input}
                      onSave={handleEditSave}
                    />
                  }
                  {input.type === 'elementary_flow' &&
                    <SubsystemElementaryFlowEditModal
                      currentNodeData={input}
                      direction={"in"}
                      onSave={handleEditSave}
                    />
                  }
                  <NodeBoxDlButton type='button' onClick={() => handleDeleteClick(input)} >
                    <div>
                      <NodeBoxCancelButtonIcon></NodeBoxCancelButtonIcon>
                    </div>
                  </NodeBoxDlButton>
                </StyledTd>
                <StyledTd>
                  <Handle type="source" position={Position.Right} id={`output-${input.process_io_no}`} />
                </StyledTd>
              </NodeBoxContent>
            ))}
            {inputs.length > 10 && (
              <tr>
                <StyledTd colSpan={5}>
                  <AddButton type='button' onClick={openFlowListDialog}>
                    {/* {showAllInputs ? "Show less" : "Show more"} */}
                    ...
                  </AddButton>
                </StyledTd>
              </tr>
            )}
          </tbody>
        </StyledTable>

        <StyledTable>
          <thead>
            <NodeBoxFlowTitle>
              <Th>
                {t('出力')}
                <AddButton type='button' onClick={toggleOutputContextMenu}>
                  <NodeBoxFlowButtonIconDiv>
                    <NodeBoxFlowButtonIcon></NodeBoxFlowButtonIcon>
                  </NodeBoxFlowButtonIconDiv>
                </AddButton>
              </Th>
                {outputContextMenuVisible && (
                  <ContextMenu ref={outputContextMenuRef}>
                    <ContextMenuItem onClick={() => { setOutputElementaryFlowSearchModalVisible(true); setOutputContextMenuVisible(false); }}>
                      {t('出力基本フロー（排出物）を追加')}
                    </ContextMenuItem>
                    <ContextMenuItem onClick={() => { setCreateOutputModalVisible(true); setOutputContextMenuVisible(false); }}>
                      {t('製品（DB未登録）を出力として追加')}
                    </ContextMenuItem>
                    <ContextMenuItem onClick={openDownstreamSubsystemModal}>{t('下流のサブシステムと接続して追加')}</ContextMenuItem>
                  </ContextMenu>
                )}
            </NodeBoxFlowTitle>
          </thead>

          <tbody>
            {(showAllOutputs ? outputs : outputs.slice(0, 10)).map((output: any, index: any) => (
              <NodeBoxContent key={index}>
                <StyledLeftTd>
                  <NodeBoxDlButton type='button'>
                    {/* {output.type === 'defined_product' && <NodeBoxDirectionTopButtonIcon></NodeBoxDirectionTopButtonIcon>}
                    {output.type === 'case_study_product' && <NodeBoxDirectionButtonIcon></NodeBoxDirectionButtonIcon>} */}
                  </NodeBoxDlButton>
                  <Handle type="target" position={Position.Left} id={`input-${output.process_io_no}`} />
                </StyledLeftTd>

                <StyledNameTd>
                  <NodeBoxDlText>{output.exchange.name}</NodeBoxDlText>
                </StyledNameTd>
                <StyledUnitTd>
                  <NodeBoxDlUnit>
                  {formatNumber(
                      output.amount,  
                      userData?.significant_figures ?? 2,
                      userData?.use_decimal_notation ?? false,
                      userData?.decimal_places ?? 2
                      ) || null
                    }
                    {output.unit.name}
                  </NodeBoxDlUnit>
                </StyledUnitTd>
                <StyledTd>
                  {output.type === 'case_study_product' &&
                    <SubsystemCaseStudyProductEditModal
                      subsystemName={currentNodeData.name}
                      currentNodeData={output}
                      direction={"out"}
                      onSave={handleEditSave}
                    />
                  }
                  {output.type === 'elementary_flow' &&
                    <SubsystemElementaryFlowEditModal
                      currentNodeData={output}
                      direction={"out"}
                      onSave={handleEditSave}
                    />
                  }
                  <NodeBoxDlButton type='button' onClick={() => handleDeleteClick(output)} >
                    <div>
                      <NodeBoxCancelButtonIcon></NodeBoxCancelButtonIcon>
                    </div>
                  </NodeBoxDlButton>
                </StyledTd>
                <StyledTd>
                  <Handle type="source" position={Position.Right} id={`output-${output.process_io_no}`} />
                </StyledTd>
              </NodeBoxContent>
            ))}
            {outputs.length > 10 && (
              <tr>
                <StyledTd colSpan={5}>
                  <AddButton type='button' onClick={openFlowListDialog}>
                    {/* {showAllOutputs ? "Show less" : "Show more"} */}
                    ...
                  </AddButton>
                </StyledTd>
              </tr>
            )}
          </tbody>
        </StyledTable>

        <SubsystemEdit
          isOpen={editDialogVisible}
          onClose={() => setEditDialogVisible(false)}
          data={currentNodeData}
          onSave={handleSave}
        />
        <SubsystemList
          currentNodeData={currentNodeData}
          isOpen={flowListDialogVisible}
          onClose={() => setFlowListDialogVisible(false)}
        />

        <SubsystemInputProductSearchModal
          currentNodeData={currentNodeData}
          isOpen={inputProductSearchModalVisible}
          onClose={() => setInputProductSearchModalVisible(false)}
        />
        <SubsystemCaseStudyProductCreateModal
          currentNodeData={currentNodeData}
          direction={"in"}
          isOpen={createInputModalVisible}
          onClose={() => setCreateInputModalVisible(false)}
        />
        <SubsystemCaseStudyProductCreateModal
          currentNodeData={currentNodeData}
          direction={"out"}
          isOpen={createOutputModalVisible}
          onClose={() => setCreateOutputModalVisible(false)}
        />
        <SubsystemInputElementaryFlowSearchModal
          currentNodeData={currentNodeData}
          isOpen={elementaryFlowSearchModalVisible}
          onClose={() => setElementaryFlowSearchModalVisible(false)}
        />
        <SubsystemOutputElementaryFlowSearchModal
          currentNodeData={currentNodeData}
          isOpen={outputElementaryFlowSearchModalVisible}
          onClose={() => setOutputElementaryFlowSearchModalVisible(false)}
        />

        <UpstreamSubsystemModal
          currentNodeData={currentNodeData}
          nodes={nodes.filter(node => node.id !== String(data.id))}
          isOpen={upstreamSubsystemModalVisible}
          onClose={() => setUpstreamSubsystemModalVisible(false)}
        />

        <DownstreamSubsystemModal
          currentNodeData={currentNodeData}
          nodes={nodes.filter(node => node.id !== String(data.id))}
          isOpen={downstreamSubsystemModalVisible}
          onClose={() => setDownstreamSubsystemModalVisible(false)}
        />
      </NodeBoxInner>
    </NodeBox>
  );
};

export default CustomNodeComponent;

const StyledTable = styled.table`
  width: 100%;
  border-right: 1px solid var(--color-line-primary);
  border-left: 1px solid var(--color-line-primary);
`

const StyledTd = styled.td`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledLeftTd = styled.td`
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  max-width: 20px;
`;

const StyledNameTd = styled.td`
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  max-width: 150px;
`;


const StyledUnitTd = styled.td`
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  max-width: 70px;
`;




const SubSystemName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  background-color: #fff;
  border: 1px solid var(--color-line-primary);
  border-radius: 4px 4px 0 0;
  padding: 0 0 0 7px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NodeBox = styled.div`
  border-radius: 10px;
  background: rgb(255, 255, 255);
`;

const NodeBoxInner = styled.div<{ $borderColor: string }>`
  width: 100%;
  border: none !important;
  border-bottom: 10px solid ${props => props.$borderColor} !important;
  border-radius: 4px;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.05));
`;


const NodeBoxFlowTitle = styled.tr`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 12px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  background-color: #F3F3F3;
  padding: 3px 7px;
  border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 12px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  background-color: #F3F3F3;
`

const NodeBoxFlowButtonIconDiv = styled.div`
  width: 16px;
  height: 16px;
  background-color: #F3F3F3;

  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
  border: 2px solid var(--color-line-primary);
  background-color: #fff;
  border-radius: 50%;
`

const NodeBoxFlowButtonIcon = styled.span`
  width: 7.5px;
  height: 7.5px;

  position: relative;
  mask: url(${iconAdd}) no-repeat center center / contain;
  -webkit-mask: url(${iconAdd}) no-repeat center center / contain;
  display: block;
  width: 12px;
  height: 12px;
  background: var(--color-txt-primary);
`

const NodeBoxEditButton = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
  cursor: pointer;
`

const NodeBoxEditButtonIcon = styled.span`
  mask: url(${iconEdit}) no-repeat center center / contain;
  -webkit-mask: url(${iconEdit}) no-repeat center center / contain;
  display: block;
  width: 24px;
  height: 24px;
  background: var(--color-txt-primary);
`

const NodeBoxContent = styled.tr`
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 3px 0px 3px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 8px;
  border-bottom: 1px solid var(--color-line-primary);
`

const NodeBoxDlButton = styled.button`
  white-space: nowrap;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
`

const NodeBoxDirectionButtonIcon = styled.span`
  mask: url(${iconArrowBack}) no-repeat center center / contain;
  -webkit-mask: url(${iconArrowBack}) no-repeat center center / contain;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--color-txt-primary);
`

const NodeBoxDlText = styled.p`
  margin-left: 4px;
  word-wrap: break-word; /* 自動的に単語を折り返す */
  white-space: normal;   /* 自動で改行を有効にする */
  max-width: 150px; 
`

const NodeBoxDlUnit = styled.p`
  margin-left: 4px;
  margin-right: auto;
`

const NodeBoxCancelButtonIcon = styled.span`
  mask: url(${iconCancel}) no-repeat center center / contain;
  -webkit-mask: url(${iconCancel}) no-repeat center center / contain;
  display: block;
  width: 100%;
  height: 100%;
  width: 16px;
  height: 16px;
  background: var(--color-txt-primary);
`


const Menu = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  white-space: nowrap;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1;

`;

const MenuItem = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  font-size: 11px;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin: 0;
`;

const ContextMenu = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  white-space: nowrap;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1;
`;

const ContextMenuItem = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`

const NodeName = styled.div`
  word-wrap: break-word; 
  word-break: break-all;
  white-space: normal;
  max-width: 290px; 
`
import React, { useRef, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';
import { Chart as ReactChart } from 'react-chartjs-2';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables, SankeyController, Flow);

interface SankeyDiagramProps {
  data: any;
  options?: any;
  width?: number;
  height?: number;
}

const SankeyDiagram: React.FC<SankeyDiagramProps> = ({ data, options, width = 100, height = 100 }) => {
  const chartRef = useRef<any>(null);
  const [chartInstance, setChartInstance] = useState<any>(null);
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const {t} = useTranslation()

  // チャートインスタンスを保持
  useEffect(() => {
    if (chartRef.current && !chartInstance) {
      const chart = chartRef.current;
      setChartInstance(chart);
    }
  }, [chartRef, chartInstance]);

  // 描画が問題なければエクスポートを許可
  useEffect(() => {
    if (chartInstance) {
      setExportEnabled(true);
    }
  }, [chartInstance]);

  // サンキーダイアグラムのエクスポート
  const exportChart = () => {
    if (chartInstance) {
      chartInstance.canvas.toBlob((blob: any) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'sankey_chart.png';
        link.click();
        URL.revokeObjectURL(url);
      });
    }
  };

  return (
    <Section>
      {/* {data && */}
      <>
        <div style={{ width: `${width}%`, height: `${height}%` }}>
        <ReactChart
              ref={chartRef}
              type="sankey"
              data={{
                ...data,
                datasets: data.databasePacks.map((databasePack: any) => ({
                  ...databasePack,
                  data: databasePack.data.map((flow: any) => ({
                    ...flow,
                  })),
                  // backgroundColorとしてcolorFromを設定
                  colorFrom: databasePack.data.map((flow: any) => flow.colorFrom),
                  colorTo: databasePack.data.map((flow: any) => flow.colorTo),
                })),
              }}
              options={options}
            />
        </div>
        <Button onClick={exportChart}  disabled={!exportEnabled}>
          <ButtonInnerText>
            {t('エクスポートする')}
          </ButtonInnerText>
        </Button>
      </>
      {/* } */}
    </Section>
  );
};

export default SankeyDiagram;

const Section = styled.div`
  margin-top: 10px;
`

const Button = styled.button`
background-color: var(--color-site-secondary);
border-radius: 4px;
border: 1px solid var(--color-site-secondary);
padding: 7px 16px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
gap: 0 4px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font: inherit;
outline: none;

&:hover {
  background-color: #fff;
  text-decoration: none;

  span {
    color: var(--color-site-secondary);
  }
}
`

const ButtonInnerText = styled.span`
color: #fff;
font-size: 13px;
font-weight: 500;
`

import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css'; // デフォルトテーマ
import styled from 'styled-components'
import { Japanese } from 'flatpickr/dist/l10n/ja';  // 日本語ロケールのインポート
import { use } from 'i18next';
import { getCookies } from '@services/Cookies';

interface DateInputProps {
    value: string | Date | null;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange }) => {

    const [locale, setLocale] = useState<any>(Japanese)
    useEffect(()=>{
        const lng = getCookies("i18next")
        if(lng === 'ja'){
            setLocale(Japanese)
        } else{
            setLocale('en')
        }
    },[getCookies("i18next")])

    // value が null の場合は undefined を渡す
    const parsedDate = value ? new Date(value) : undefined;

    // 日付を 'YYYY-MM-DD' フォーマットに変換（ローカルタイムゾーン対応）
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は 0-11 なので +1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Flatpickr の onChange をラップして ChangeEvent に変換
    const handleDateChange = (selectedDates: Date[]) => {
        const selectedDate = selectedDates[0] || null;

        if (selectedDate) {
            // ローカルタイムゾーンでフォーマット
            const formattedDate = formatDate(selectedDate);

            // 擬似的な event オブジェクトを作成
            const event = {
                target: {
                    value: formattedDate, // 'YYYY-MM-DD' 形式の文字列を生成
                },
            } as React.ChangeEvent<HTMLInputElement>;

            // 親コンポーネントに擬似 event を渡す
            onChange(event);
        } else {
            // 日付が未選択の場合は空の event を渡す
            const event = {
                target: {
                    value: "",
                },
            } as React.ChangeEvent<HTMLInputElement>;

            onChange(event);
        }
    };

    return (
        <Input
            data-enable-time
            value={parsedDate}
            onChange={handleDateChange}
            placeholder='yyyy-mm-dd'
            options={{
                locale: locale, // 英語のロケールを設定
                dateFormat: "Y-m-d", // 日付フォーマットを設定
                enableTime: false, // 時間を非表示にする
            }}
        />
    );
};

export default DateInput;


const Input = styled(Flatpickr)`
    width: min(100%, 340px);
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

import React, { useState, useEffect, createContext, useRef } from 'react';
import Modal from '@common/modal/Modal';
import styled from 'styled-components';
import { RequiredSpan, LinkMenu } from '@styles/Common';

import { CreateProcess, CreateProcessContext, GetUserDetail } from '@typeList/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateProcess, useCreateProcessAndProduct, useGetUserDetail } from '@hooks/useBackendApi';
import { getCurrentDefaultDatabasePackId, getCurrentUserInfo } from '@services/Cookies';
import { getValueByKey } from '@utils/utils';
import UnitSelectModal from '@common/UnitSelectModal';
import ProcessProductCategrySelect from './ProcessProductCategrySelect';
import { useForm } from 'react-hook-form';
import ProcessProductSearchModal from '../product_search/process_create/ProcessProductSearchModal';
import { useTranslation } from 'react-i18next';
import iconEdit from '@images/button/icon_edit.svg'
import ModalButton from '@common/button/ModalButton';
import DateInput from '@common/date_picker/DateInput';


const defaulutContextvalue: CreateProcessContext = {
    inputDatabasePackId: 1,
    databasePacks: [],
    inputPuroductRadioValue: "1",
    inputDatabasePackName: "",
    inputProductId: 0,
    inputProductName: "",
    inputUnitId: 0,
    inputUnitName: "",
    inputCategoryId: 0,
    inputCategoryValueId: 0,
    categoryIds: null,
    categoryValueIds: null,
    ids: [""],
    setIds: () => { },
    setInputCategoryId: () => { },
    setInputCategoryValueId: () => { },
    setCategoryIds: () => { },
    setCategoryValueIds: () => { },
    setInputDatabasePackName: () => { },
    setInputProductId: () => { },
    setInputUnitName: () => { },
    setInputUnitId: () => { },
}

export const processCreateContext = createContext<CreateProcessContext>(defaulutContextvalue);

interface ProcessCreateModalProps {
    userData: GetUserDetail | null;
}

/**
 * 011 プロセス新規作成画面
 * @returns 
 */
const ProcessCreateModal: React.FC<ProcessCreateModalProps> = ({ userData }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm<CreateProcess>();

    // ユーザーのライセンスロールに紐づくデータベースパックの一覧
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    const [inputDatabasePackName, setInputDatabasePackName] = useState("")

    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            // デフォルト設定のデータベースパック取得
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
        }
    }, [userData]);

    useEffect(() => {
        if (inputDatabasePackId) {
            setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(inputDatabasePackId), 'name'))
        }
    }, [databasePacks, inputDatabasePackId])

    useEffect(() => {
        if(getCurrentDefaultDatabasePackId()){
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    // モーダル操作
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // 入力操作
    const [inputProcessName, setInputProcessName] = useState<string>("");
    const [inputProductId, setInputProductId] = useState<number>(0); // 既存製品ID
    const [inputProductName, setInputProductName] = useState<string>("");
    const [inputAmount, setInputAmount] = useState<number>(1);
    const [inputUnitId, setInputUnitId] = useState<number>(0);
    const [inputUnitName, setInputUnitName] = useState<string>("");
    const [inputPuroductRadioValue, setInputPuroductRadioValue] = useState<string>("1");
    const [inputDataRetrievalStartAt, setInputDataRetrievalStartAt] = useState<string>("");
    const [inputDataRetrievalEndAt, setInputDataRetrievalEndAt] = useState<string>("");
    const [inputCategoryId, setInputCategoryId] = useState<number | undefined>(1);
    const [inputCategoryValueId, setInputCategoryValueId] = useState<number | undefined>(1);
    const [categoryIds, setCategoryIds] = useState<Array<{ id: number, name: string }> | null | undefined>();
    const [categoryValueIds, setCategoryValueIds] = useState<Array<{ id: number, name: string }> | null | undefined>();
    const [ids, setIds] = useState<Array<string> | null>([String(inputCategoryValueId)]);

    const [inputProcessNameError, setInputProcessNameError] = useState<any>(null);
    const [inputProductNameError, setInputProductNameError] = useState<any>(null);
    const [inputAmountError, setInputAmountError] = useState<any>(null);
    const [inputUnitIdError, setInputUnitIdError] = useState<any>(null);

    const validateCheck = (): boolean => {
        setInputProcessNameError(null);
        setInputProductNameError(null);
        setInputAmountError(null);
        setInputUnitIdError(null);
        if (!inputProcessName) {
            setInputProcessNameError(t('プロセス名は必須です'));
        }
        if (!inputProductName) {
            setInputProductNameError(t('製品名は必須です'));
        }
        if (!inputAmount) {
            setInputAmountError(t('流量は必須です'));
        }
        if (!inputUnitId) {
            setInputUnitIdError(t('単位は必須です'));
        }
        if (!inputProcessName || !inputProductName || !inputAmount || !inputUnitId) {
            return false;
        }
        return true;
    }

    // セレクトボックス
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(event.target.value), 'name'))
                break;
        }
    };
    // テキストフィールド
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "process_name":
                setInputProcessName(event.target.value);
                break;
            case "product_name":
                setInputProductName(event.target.value);
                break;
            case "radio":
                setInputPuroductRadioValue(event.target.value);
                break;
            case "amount":
                setInputAmount(Number(event.target.value));
                break;
            case "unit":
                setInputUnitName(event.target.value);
                break;
            case "data_retrieval_start_at":
                setInputDataRetrievalStartAt(event.target.value);
                break;
            case "data_retrieval_end_at":
                setInputDataRetrievalEndAt(event.target.value);
                break;
        }
    };

    const navigate = useNavigate();
    const location = useLocation();

    const { createProcess, createProcessData, createProcessError, createProcessLoading } = useCreateProcess();
    const { createProcessAndProduct, createProcessAndProductData, createProcessAndProductLoading } = useCreateProcessAndProduct();

    // ProcessProductCategrySelectコンポーネントの参照を作成
    const processProductCategrySelectRef = useRef<{ getCategoryValueIds: () => number[] }>(null);

    const [updateError, setUpdateError] = useState<any>(null);

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() =>{
        if(isModalOpen){
            setIsDisabled(false)
        }
    },[isModalOpen])
    useEffect(() => {
        if(createProcessLoading || createProcessAndProductLoading){
            setIsDisabled(true);
        } 
    },[createProcessLoading, createProcessAndProductLoading])

    const handleOkButtonClick = async () => {
        if (isDisabled) return;

        // 画面から製品カテゴリを取得
        const categoryValueIds = processProductCategrySelectRef.current?.getCategoryValueIds();

        // 既存製品
        const existing_product_param = {
            name: inputProcessName,
            process_output: {
                amount: inputAmount,
                unit_id: inputUnitId,
                product_id: inputProductId
            }
        }
        // 新規製品
        const new_product_param = {
            name: inputProcessName,
            amount: inputAmount,
            product: {
                name: inputProductName,
                category_value_ids: categoryValueIds,
                unit_id: inputUnitId
            },
            data_retrieval_start_at: inputDataRetrievalStartAt === "" ? null : inputDataRetrievalStartAt,
            data_retrieval_end_at: inputDataRetrievalEndAt === "" ? null : inputDataRetrievalEndAt
        }

        if (validateCheck()) {
            let processData;
            try {
                // ProcessProductCategrySelectからhiddenのvalueを取得
                const categoryValueIds = processProductCategrySelectRef.current?.getCategoryValueIds();

                if (inputPuroductRadioValue === "1") {
                    const response = await createProcessAndProduct(inputDatabasePackId, new_product_param);
                    processData = response;
                }
                if (inputPuroductRadioValue === "2") {
                    const response = await createProcess(inputDatabasePackId, existing_product_param);
                    processData = response;
                }

                const id = processData?.id;

                if (location.pathname.includes('/process/process_view/')) {
                    // ページが同じであれば、強制的にリロードして遷移
                    window.location.href = `/process/process_view/${id}`;
                } else {
                    window.location.href = `/process/process_view/${id}`;
                }
                closeModal();

            } catch (error: any) {
                console.log(error.message);
            }
        }
    }

    return (
        <processCreateContext.Provider value={{
            inputDatabasePackId,
            databasePacks,
            inputPuroductRadioValue,
            inputDatabasePackName,
            inputProductId,
            inputProductName,
            inputUnitId,
            inputUnitName,
            inputCategoryId,
            inputCategoryValueId,
            categoryIds,
            categoryValueIds,
            ids,
            setIds,
            setInputCategoryId,
            setInputCategoryValueId,
            setCategoryIds,
            setCategoryValueIds,
            setInputDatabasePackName,
            setInputProductId,
            setInputUnitName,
            setInputUnitId
        }}>
            <LinkMenu onClick={openModal}>
                {t('新規作成')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('プロセス新規作成')}</Title>
                    {updateError && <ErrorMsg>{t('更新に失敗しました')}{updateError}</ErrorMsg>}


                    <SectionBody>
                        <SectionContent>
                            <Radio>
                                <RadioLabel>
                                    <RadioInput
                                        type="radio"
                                        name="new"
                                        id="1"
                                        value="1"
                                        checked={inputPuroductRadioValue === "1"}
                                        onChange={(event) => handleInputChange(event, "radio")}
                                    />
                                    <RadioText>{t('新規製品を作成する')}</RadioText>
                                </RadioLabel>
                                <RadioLabel>
                                    <RadioInput
                                        type="radio"
                                        name="existing"
                                        id="2"
                                        value="2"
                                        checked={inputPuroductRadioValue === "2"}
                                        onChange={(event) => handleInputChange(event, "radio")}
                                    />
                                    <RadioText>{t('登録済み製品を選択する')}</RadioText>
                                </RadioLabel>
                            </Radio>
                        </SectionContent>
                        <SectionTab>
                            <SectionTabContent>
                                <SectionColumn>
                                    {databasePacks.length > 0 &&
                                        <>
                                            <SectionTitle>{t('データベースパック')}</SectionTitle>
                                            <SectionContent>
                                                <SelectPrimary>
                                                    <SelectInner
                                                        name="database-pack"
                                                        id="database-pack-select"
                                                        onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                                        value={inputDatabasePackId ?? ''}
                                                    >
                                                        {databasePacks.map((database_pack, index) => (
                                                            <option key={index} value={database_pack.id}>
                                                                {database_pack.name}
                                                            </option>
                                                        ))}
                                                    </SelectInner>
                                                </SelectPrimary>
                                            </SectionContent>
                                        </>
                                    }
                                    <SectionTitle>{t('プロセス名')}<SectionRequired>※</SectionRequired></SectionTitle>
                                    <SectionContent>
                                        <InputPrimary
                                            type="text"
                                            value={inputProcessName}
                                            onChange={(event) => handleInputChange(event, "process_name")}
                                        />
                                        {inputProcessNameError && <ErrorMsg>{inputProcessNameError}</ErrorMsg>}
                                    </SectionContent>

                                    <SectionTitle>{t('製品名')}<SectionRequired>※</SectionRequired></SectionTitle>
                                    <SectionContent>
                                        <InputPrimary
                                            type="text"
                                            value={inputProductName}
                                            onChange={(event) => handleInputChange(event, "product_name")}
                                            disabled={inputPuroductRadioValue === "2"}
                                        />
                                        {inputPuroductRadioValue === "2" &&
                                            <ProcessProductSearchModal
                                                selectted_database_pack_id={inputDatabasePackId}
                                                setInputCategoryId={setInputCategoryId}
                                                setInputCategoryValueId={setInputCategoryValueId}
                                                setCategoryIds={setCategoryIds}
                                                setCategoryValueIds={setCategoryValueIds}
                                                setInputProductId={setInputProductId}
                                                setInputProductName={setInputProductName}
                                                setInputUnitId={setInputUnitId}
                                                setInputUnitName={setInputUnitName}
                                            />
                                        }
                                        {inputProductNameError && <ErrorMsg>{inputProductNameError}</ErrorMsg>}
                                    </SectionContent>
                                    <SectionTitle>{t('流量')}<SectionRequired>※</SectionRequired></SectionTitle>
                                    <SectionContent>
                                        <InputPrimary
                                            type="number"
                                            min="0"
                                            value={String(inputAmount)}
                                            //                                            value={String(inputAmount) === "0" ? 0 : inputAmount || ""}
                                            onChange={(event) => handleInputChange(event, "amount")}
                                        />
                                        {inputAmountError && <ErrorMsg>{inputAmountError}</ErrorMsg>}
                                    </SectionContent>
                                    <SectionTitle>{t('単位')}<SectionRequired>※</SectionRequired></SectionTitle>
                                    <SectionContent>
                                        <InputPrimary type="text" disabled value={inputUnitName} onChange={(event) => handleInputChange(event, "unit")} />
                                        {inputPuroductRadioValue === "1" &&
                                            <UnitSelectModal link_text={t('選択')}
                                                inputDatabasePackName={inputDatabasePackName}
                                                setInputUnitId={setInputUnitId}
                                                inputDatabasePackId={inputDatabasePackId}
                                                setInputUnitName={setInputUnitName}
                                                editMode={false}
                                            />
                                        }
                                        {inputUnitIdError && <ErrorMsg>{inputUnitIdError}</ErrorMsg>}
                                    </SectionContent>
                                    {inputPuroductRadioValue === "1" &&
                                        <>
                                            <SectionTitle>{t('データ収集期間')}</SectionTitle>
                                            <SectionContentTerm>
                                                <DateInput
                                                    value={inputDataRetrievalStartAt}
                                                    onChange={(event) => handleInputChange(event, "data_retrieval_start_at")}
                                                />
                                                ~
                                                <DateInput
                                                    value={inputDataRetrievalEndAt}
                                                    onChange={(event) => handleInputChange(event, "data_retrieval_end_at")}
                                                />
                                            </SectionContentTerm>
                                        </>
                                    }
                                    <SectionTitle>{t('製品カテゴリ')}</SectionTitle>
                                    {inputDatabasePackId &&
                                        <ProcessProductCategrySelect ref={processProductCategrySelectRef} />
                                    }
                                </SectionColumn>
                                <ButtonCreateSectionTabel>
                                    <ModalButton onClick={handleOkButtonClick} text={t('作成する')} disabled={isDisabled}/>
                                </ButtonCreateSectionTabel>

                            </SectionTabContent>
                        </SectionTab>
                    </SectionBody>
                </Inner>
            </Modal>
        </processCreateContext.Provider>
    );
};

export default ProcessCreateModal;

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const EditButton = styled.button`
    padding: 2px 12px;
    border-radius: 20px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    background-color: var(--color-gray--01);
    gap: 0 4px;

    border: none;

`

const IconHeader = styled.div`
`

const Icon = styled.span`
    background-color: var(--color-site-secondary);

    mask: url(${iconEdit}) no-repeat center center / contain;
    -webkit-mask: url(${iconEdit}) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
`


const Section = styled.div`
    position: fixed;
    width: min(83.3333333333vw, 1200px);
    padding: 0;
    border: none;
    border-radius: 5px;
    background-color: var(--color-bg-secondary);
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.8);
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const LastSectionBody = styled.div`
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
`

const Data = styled.div`
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
`

const SectionTable = styled.section`
    margin-bottom: 32px;
    display: block;
  
`
const ChiledSection = styled.div`
`

const SectionHead = styled.div`
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
`

const SectionTitle = styled.h2`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionBody = styled.div`
`

const SectionChildTitle = styled.h3`
    padding-bottom: 18px;
    line-height: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 40px;
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-weight: 500;

`

const DataTitle = styled.dt`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const SectionContentTerm = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 12px;
`



const DataText = styled.p`
    font-size: 16px;
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DataError = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const ReloardButton = styled.button`
    padding: 2px 12px;
    border-radius: 20px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
    background-color: var(--color-gray--01);
    gap: 0 4px;

    border: none;

`
const ReloarIcon = styled.div`
`

const RelordIconImage = styled.span`
    background-color: var(--color-site-secondary);

    mask: url(../images/icon/icon_reload.svg) no-repeat center center / contain;
    -webkit-mask: url(../images/icon/icon_reload.svg) no-repeat center center / contain;
    display: block;
    width: 24px;
    height: 24px;
    background: var(--color-txt-primary);
    
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;

    &:hover {
        background-color: #fff;
    }    
`
const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const CheckBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px;
`

const CheckBoxLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
  
`

const CheckBoxInput = styled.input`
    display: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font: inherit;
    outline: none;
    box-sizing: border-box;
`

const CheckBoxIcon = styled.span`
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-txt-primary);
    border-radius: 1px;
    background-color: #fff;
    &:checked ~ .c-checkbox__icon {
      border-color: var(--color-site-secondary);
      background-color: var(--color-site-secondary);
      background-image: url(../images/common/icon_check.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px 8px;
    }       
`

const CheckBoxText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const SectionTab = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 32px 54px;
`

const SectionTabContent = styled.div`
    display: block;
    z-index: 5;
    width: 100%;
    order: 2;
    position: relative;    
`


const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const SectionColumn = styled.dl`
    margin-bottom: 40px;
`

const SectionRequired = styled.span`
    font-size: 0.7em;
    vertical-align: top;
    position: relative;
    top: 0.1em;
    color: var(--color-red--01);
`

const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCategoriesList } from '@hooks/useBackendApi';
import ProductTextTreeSearch from './ProductTextTreeSearch';
import styled from 'styled-components';
import iconAdd from '@images/text_tree/icon_add.svg';
import iconClose from '@images/text_tree/icon_close.svg';
import iconSelect from '@images/table/icon_arrow_back.svg';
import iconFolder from '@images/text_tree/icon_folder.svg'
import { CategoryValues, GetUserDetail } from '@typeList/types';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';

interface ProductTextTreeCategoriesProps {
    type: string; // Select or Edit
    modal_type: string;
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>
    onSelectProduct: (product: { id: number, name: string, unit: { id: number, name: string, unit_group_id: number }, category_values: CategoryValues[]}) => void;
    currentNodeData?: any;
    userData?: GetUserDetail | null;
}


/**
 * カテゴリーツリーで検索
 * @param param0 
 * @returns 
 */
const ProductTextTreeCategories: React.FC<ProductTextTreeCategoriesProps> = ({type, modal_type, setIsSearchModalOpen, onSelectProduct, currentNodeData, userData}) => {
    const { t } = useTranslation();
    const [expandedCategoryIds, setExpandedCategoryIds] = useState<number[]>([]);

    const {
        categoriesListData,
        categoriesListError,
        categoriesListLoading
    } = useGetCategoriesList();


    const toggleCategory = (categoryId: number, event?: React.MouseEvent<HTMLSpanElement>) => {
        if (event) {
            event.stopPropagation();  // イベントが存在する場合のみ伝播を停止
        }
        setExpandedCategoryIds(prevIds =>
            prevIds.includes(categoryId)
                ? prevIds.filter(id => id !== categoryId)
                : [...prevIds, categoryId]
        );
    };

    return (
        <CategoryWrapper>
            {categoriesListLoading &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {categoriesListError && <ErrorMsg>{categoriesListError}</ErrorMsg>}
            <ul>
                <ProductTextTreeSearch
                    categoriesListData={categoriesListData}
                    type={type}
                    modal_type={modal_type}
                    setIsSearchModalOpen={setIsSearchModalOpen}
                    onSelectProduct={onSelectProduct}
                    currentNodeData={currentNodeData}
                    userData={userData}
                />
            </ul>
        </CategoryWrapper>
    );
};

export default ProductTextTreeCategories;

const CategoryWrapper = styled.div`
font-size: 16px;
`

const CategoryItem = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;

  .category-name {
    margin-left: 8px;
  }
`;

const Add = styled.span`
content: "";
background-position: 2px;
line-height: 16px;
vertical-align: -0.125em;
background-repeat: no-repeat;
background-attachment: scroll;
outline: none;
cursor: pointer;
display: inline-flex;
width: 20px !important;
height: 20px !important;
margin-right: 8px !important;
background-image: url(${iconAdd}); 
place-content: center !important;
border: 2px solid var(--color-line-primary) !important;
background-color: #fff !important;
border-radius: 50% !important;
`

const Close = styled.span`
content: "";
background-position: 2px;
line-height: 16px;
vertical-align: -0.125em;
background-repeat: no-repeat;
background-attachment: scroll;
outline: none;
cursor: pointer;
display: inline-flex;
width: 20px !important;
height: 20px !important;
margin-right: 8px !important;
background-image: url(${iconClose}); 
place-content: center !important;
border: 2px solid var(--color-line-primary) !important;
background-color: #fff !important;
border-radius: 50% !important;
`

const Icon = styled.span`
background-color: var(--color-green-01);
mask: url(${iconFolder}) no-repeat center center / contain;
-webkit-mask: url(${iconFolder}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 24px;
height: 24px;
`
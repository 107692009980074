import React, { useState, useCallback, useRef, useEffect, Dispatch, SetStateAction, useContext } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    ColumnMeta,
    createColumnHelper,
    CellContext,
    TableMeta
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { ReviewLevelType } from '@typeList/types';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import styled from 'styled-components';
import AddButton from '@common/button/AddButton';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconDelete from '@images/table/icon_delete.svg';
import { t } from 'i18next';

interface TableProps {
    selectReviewLevelData: { review_type_id: number, review_level_id: number }[] | undefined;
    setSelectReviewLevelData: Dispatch<SetStateAction<{ review_type_id: number, review_level_id: number }[] | undefined>>;
    reviewLevelsDataList: any[] | undefined
    setReviewLevelsDataList: Dispatch<SetStateAction<any[] | undefined>>;
}

const initialPageIndex = 0;
const initialPageSize = 1000;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

interface ExtendedColumnMeta extends ColumnMeta<any, unknown> {
    editable?: boolean;
}

interface CustomTableMeta extends TableMeta<ReviewLevelType> {
    updateData: (newData: ReviewLevelType[]) => void;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getToggleSortingHandler()}
        >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
        </Th>
    );
};

const columnHelper = createColumnHelper<ReviewLevelType>();

const createColumns = (
    selectReviewLevelData: { review_type_id: number, review_level_id: number }[] | undefined,
    setSelectReviewLevelData: Dispatch<SetStateAction<{ review_type_id: number, review_level_id: number }[] | undefined>>
) => [
    columnHelper.accessor('name', {
        header: () => t('レビュータイプ'),
        cell: info => info.getValue(),
    }),
    columnHelper.accessor('levels', {
        header: () => t('レビューレベル'),
        cell: (info: CellContext<ReviewLevelType, ReviewLevelType['levels']>) => {
            const { row } = info;

            const initialLevelId = selectReviewLevelData
                ?.find(item => item.review_type_id === row.original.id)
                ?.review_level_id;

            const selectedLevel = row.original.levels.find(level => level.id === initialLevelId)?.id || row.original.levels[0]?.id;

            const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
                const newLevelId = parseInt(event.target.value);

                const updatedSelectReviewLevelData = selectReviewLevelData?.map(item => {
                    if (item.review_type_id === row.original.id) {
                        return { ...item, review_level_id: newLevelId };
                    }
                    return item;
                });

                setSelectReviewLevelData(updatedSelectReviewLevelData);
            };

            return (
                <SelectLabel>
                    <Select onChange={handleLevelChange} value={selectedLevel}>
                        {row.original.levels.map((level, index) => (
                            <option key={index} value={level.id}>
                                {level.level}
                            </option>
                        ))}
                    </Select>
                </SelectLabel>
            );
        }
    }),
];

/**
 * レビューテーブル
 */
const ReviewLevelTypeTable: React.FC<TableProps> = ({ selectReviewLevelData, setSelectReviewLevelData, reviewLevelsDataList, setReviewLevelsDataList }) => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { reviewLevelTypeData } = context;

    const [columns, setColumns] = useState(() => createColumns(selectReviewLevelData, setSelectReviewLevelData));
    const [sorting, setSorting] = useState<SortingState>([]);
    const [selectedId, setSelectedId] = useState<number | null>(null);

    useEffect(() => {
        setColumns(createColumns(selectReviewLevelData, setSelectReviewLevelData));
    }, [selectReviewLevelData, setSelectReviewLevelData]);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const addRow = () => {
        if (selectedId !== null) {
            const selectedData = reviewLevelTypeData?.find(item => item.id === selectedId);
            if (selectedData && !reviewLevelsDataList?.some(item => item.id === selectedId)) {
                setReviewLevelsDataList([...reviewLevelsDataList || [], selectedData]);
                setSelectReviewLevelData([
                    ...selectReviewLevelData || [],
                    { review_type_id: selectedId, review_level_id: selectedData.levels[0].id }
                ]);
            }
        }
    };

    const removeRow = (rowIndex: number) => {
        const updatedData = reviewLevelsDataList?.filter((_, index) => index !== rowIndex);
        setReviewLevelsDataList(updatedData);

        const updatedSelectReviewLevelData = selectReviewLevelData?.filter((_, index) => index !== rowIndex);
        setSelectReviewLevelData(updatedSelectReviewLevelData);
    };

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedId(Number(event.target.value));
    }

    const updateData = (newData: ReviewLevelType[]) => {
        setReviewLevelsDataList(newData);
    };

    const table = useReactTable<ReviewLevelType>({
        data: reviewLevelsDataList ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        meta: {
            updateData: updateData
        } as CustomTableMeta
    });

    return (
        <Dl>
            <Dt>{t('評価の種別')}</Dt>
            <Dd>
                <div>
                    <Content>
                        <SelectLabel>
                            <Select
                                onChange={handleInputChangeSelect}
                                value={selectedId ?? ''}
                            >
                                <option value="" disabled>{t('選択してください')}</option>
								{reviewLevelTypeData?.map((data_quality) => {
                                    return(
                                        <>
                                        <option style={{ display: selectReviewLevelData?.some(data => data.review_type_id === data_quality.id) ? 'none' : 'block' }} key={data_quality.id} value={data_quality.id}>{data_quality.name}</option>
                                        </>
                                    )
                                })}
                            </Select>
                        </SelectLabel>
                        <AddButton type='button' onClick={addRow} text={t('追加')} ></AddButton>
                    </Content>
                </div>
            </Dd>
            
            <Dt>{t('レビューレベル')}</Dt>
            <Dd>
                <Table>
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id}>
                                <Th>{t('削除')}</Th>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader
                                        key={header.id}
                                        header={header}
                                        index={index}
                                        moveColumn={moveColumn}
                                    />
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map(row => (
                            <Tr key={row.id}>
                                <Td>
                                    <IconButton type="button" onClick={() => removeRow(row.index)}>
                                        <Icon></Icon>
                                    </IconButton>
                                </Td>
                                {row.getVisibleCells().map(cell => (
                                    <Td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Dd>
        </Dl>
    );
};

export default ReviewLevelTypeTable;


const Dl = styled.dl`
display: grid;
gap: 8px 40px;
grid-template-columns: 25% 70%;
`

const Dt = styled.dt`
font-size: 12px;
font-weight: 500;
padding: 12px 0;
`

const Dd = styled.dd`
font-size: 12px;
font-weight: 500;
// padding: 12px 0;
`

const Select = styled.select`
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
// color: var(--color-line-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
// background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
// appearance: none;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
cursor: pointe
`

const Content = styled.div`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`

const TableWrap = styled.div`
overflow-x: auto;
`


const Table = styled.table`
min-width: 100%;
border-collapse: collapse;
margin-bottom: 8px;
background-color: #fff;
border-spacing: 0;
`

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`

const Tbody= styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Td= styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
}
`

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconButton = styled.button`
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const Icon = styled.span`
mask: url(${iconDelete}) no-repeat center center / contain;
-webkit-mask: url(${iconDelete}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`

import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { LciResultItems, CaseStudyLciResultEachSubsystem, CaseStudyCalculate, ResultItems0, ResultItems1, CaseStudyLCIAResults, ElementaryFlowOutSimple } from '@typeList/types';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import CaseStudyCalculateResultTable from '@common/table/CaseStudyCalculateResultTable';
import CaseStudyContributionAnalysisModal from '../contribution_analysis/CaseStudyContributionAnalysisModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { useGetCaseStudyLciResultsCategory, useGetCaseStudyLciResultsUpper } from '@hooks/useBackendApi';
import { formatNumber } from '@utils/utils';
import { FadeLoader } from 'react-spinners';

interface InventoryAnalysisProps {
    calculateData: CaseStudyCalculate | null;
    calculateLoading: boolean;
}

/**
 * 035　ケーススタディ情報　インベントリ分析画面
 * @param param0 
 * @returns 
 */
const InventoryAnalysis: React.FC<InventoryAnalysisProps> = ({ calculateData, calculateLoading }) => {
    const { t } = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const {
        userData,
        caseStudyData,
        inputGroupingValue,
        setInputGroupingValue,
        inputDsplayFormatValue,
        setInputDisplayFormatValue,
        subsystemCategorySetData,
        lciResultsCategoryData,
        lciResultsUpperData,
        lciResultsCategoryLoading,
        lciResultsUpperLoading,
        selectCategorySet,
        setSelectCategorySet
    } = context;


    useEffect(() => {
        if (subsystemCategorySetData && subsystemCategorySetData.length > 0) {
            setSelectCategorySet(subsystemCategorySetData[0].id)
        }
    }, [subsystemCategorySetData])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "grouping":
                setInputGroupingValue(event.target.value);
                break;
            case "displayFormat":
                setInputDisplayFormatValue(event.target.value);
                break;
        }
    };

    // サブシステムカテゴリ
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(event.target.value);
        setSelectCategorySet(selectedValue); // selectCategorySet を更新
    };

    // 型ガード関数の定義
    function isResultItems0(item: ResultItems0 | ResultItems1): item is ResultItems0 {
        return (item as ResultItems0).exchange !== undefined;
    }

    // 型ガード関数の定義
    function isElementaryFlowOutSimple(exchange: any): exchange is ElementaryFlowOutSimple {
        return 'category1_name' in exchange;
    }

    // フラットなデータを作成する関数
    const flattenResultData = useCallback(() => {
        if (!calculateData) return [];

        return calculateData.result_items.map((item) => {
            // 型ガードで lcia_model プロパティが存在することを確認
            if (!isResultItems0(item)) {
                return { ...item, relatedBreakdown: [] };
            }

            const relatedBreakdown = calculateData.result_breakdown?.lci_result_breakdown.map((breakdown) => {
                // result_key_id と exchange.id が一致する場合のみ関連付け
                const matchingItems = breakdown.lci_result_breakdown_items.filter(
                    (breakdownItem) => breakdownItem.result_key_id === item.exchange.id
                );

                // result_key_id が一致する場合のみ breakdown を含める
                if (matchingItems.length === 0) return null;

                return {
                    groupingName: breakdown.grouping_name,
                    subGroupingName: breakdown.sub_grouping_name,
                    amount: matchingItems[0].amount,
                    ratio: matchingItems[0].ratio,
                };
            }).filter(Boolean); // null の値を除外

            return {
                ...item,
                relatedBreakdown: relatedBreakdown || [],
            };
        });
    }, [calculateData, inputDsplayFormatValue, inputGroupingValue]);

    // 最新の値を保持するためのrefを作成
    const inputDsplayFormatRef = useRef(inputDsplayFormatValue);
    useEffect(() => {
        inputDsplayFormatRef.current = inputDsplayFormatValue; // 値が変更されるたびにrefを更新
    }, [inputDsplayFormatValue]);
    // カラム定義
    const generateColumns = useCallback((): ColumnDef<any>[] => {
        let baseColumns: ColumnDef<any>[] = [
            {
                id: 'direction',
                header: () => t('方向'),
                accessorFn: (row) => row.direction, // ソート用のデータを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    if (resultItem.direction === "out") {
                        return t('出力');
                    } else {
                        return  t('入力');
                    }
                    // return isResultItems0(resultItem) ? resultItem.direction : '';
                },
            },
            {
                id: 'exchange.name',
                header: () => t('物質名'),
                accessorKey: 'exchange.name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) ? resultItem.exchange.name : '';
                },
            },
            {
                id: 'exchange.category1_name',
                header: () => t('カテゴリ1'),
                accessorKey: 'exchange.category1_name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) && isElementaryFlowOutSimple(resultItem.exchange)
                        ? resultItem.exchange.category1_name
                        : '';
                },
            },
            {
                id: 'exchange.category2_name',
                header: () => t('カテゴリ2'),
                accessorKey: 'exchange.category2_name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) && isElementaryFlowOutSimple(resultItem.exchange)
                        ? resultItem.exchange.category2_name
                        : '';
                },
            },
            {
                id: 'exchange.category3_name',
                header: () => t('カテゴリ3'),
                accessorKey: 'exchange.category3_name', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) && isElementaryFlowOutSimple(resultItem.exchange)
                        ? resultItem.exchange.category3_name
                        : '';
                },
            },
            {
                id: 'amount',
                header: () => t('合計'),
                accessorKey: 'amount', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    const amount = isResultItems0(resultItem) ? resultItem.amount : 0;
                    if (inputDsplayFormatValue === "2" && amount > 0) {
                        return ((resultItem.amount / amount) * 100).toFixed(2) + '%';
                    }
                    if (userData) {
                        return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                    } else {
                        return amount; // デフォルトではそのままの値を表示
                    }
                },
            },
            {
                id: 'unit.name',
                header: () => t('単位'),
                accessorKey: 'unit.name', // ソート用のキーを指定
                enableSorting: true,    
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems0(resultItem) ? resultItem.unit.name : '';
                },
            },
        ];

        // breakdown のデータを表示するカラムを追加
        if (calculateData?.result_breakdown?.lci_result_breakdown) {
            calculateData.result_breakdown.lci_result_breakdown.forEach((breakdown) => {
                baseColumns.push({
                    id: `breakdown_${breakdown.grouping_key_id}`,
                    header: () =>(
                        <span>
                            {breakdown.grouping_name}
                            <br />
                            {breakdown.sub_grouping_name}
                        </span>
                    ),
                    accessorFn: (row) => {
                        const relatedBreakdown = row.relatedBreakdown.find(
                            (b: any) => b.groupingName === breakdown.grouping_name
                        );
                        return relatedBreakdown ? relatedBreakdown.amount : 0; // ソート用のデータを指定
                    },
                    enableSorting: true,
                    cell: (info) => {
                        const relatedBreakdown = info.row.original.relatedBreakdown.find(
                            (b: any) => b.groupingName === breakdown.grouping_name && b.subGroupingName === breakdown.sub_grouping_name
                        );
                        if (inputDsplayFormatRef.current === "2") {
                            return relatedBreakdown?.ratio ? `${(relatedBreakdown?.ratio * 100).toFixed(2)}%` : `${(0).toFixed(2)}%`
                        }
                        const amount = relatedBreakdown ? relatedBreakdown.amount : 0;
                        if (userData) {
                            return formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                        } else {
                            return amount;
                        }
                    },
                });
            });
        }

        return baseColumns;
    }, [calculateData, inputDsplayFormatValue, inputGroupingValue]);

    const [columns, setColumns] = useState<ColumnDef<any>[]>(generateColumns());
    const [flattenedData, setFlattenedData] = useState<any[]>([]);


    useEffect(() => {
        setFlattenedData(flattenResultData());
    }, [calculateData, flattenResultData]);

    useEffect(() => {
        setColumns(generateColumns());
    }, [calculateData, flattenedData, inputDsplayFormatValue, inputGroupingValue]);


    // エクスポート用のcolumn data
    const [exportColumns, setExportColumns] = useState<any>()
    const [exportData, setExportData] = useState<any>()
    const exportSet = (columns: any, data: any) => {
        setExportColumns(columns);
        setExportData(data);
    }

    // CSVダウンロード用の関数を定義
    const downloadCsv = (csv: string, filename: string) => {
        // UTF-8 BOMを追加
        const csvWithBom = `\uFEFF${csv}`;

        // CSV文字列をBlobオブジェクトに変換
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

        // ダウンロードリンクを作成してダウンロードをトリガー
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // メモリリークを防ぐためにURLを解放
    };

    // データを整形する関数
    const transformData = (data: any[], columns: ColumnDef<any>[]) => {
        const headers = columns.map((column) => {
            if (typeof column.header === 'function') {
                const headerContent = column.header({} as any);
                return headerContent.props ? `${headerContent.props.children[0]} ${headerContent.props.children[2]}` : headerContent;
            }
            return column.header;
        });

        const transformedData = data.map((row) => {
            const transformedRow: any = {};

            columns.forEach((column) => {
                if (!column.id) return;

                switch (column.id) {
                    case 'direction':
                        transformedRow[column.id] = row.direction === 'out' ? '出力' : '入力';
                        break;
                    case 'exchange.name':
                        transformedRow[column.id] = row.exchange?.name || '';
                        break;
                    case 'exchange.category1_name':
                        transformedRow[column.id] = row.exchange?.category1_name || '';
                        break;
                    case 'exchange.category2_name':
                        transformedRow[column.id] = row.exchange?.category2_name || '';
                        break;
                    case 'exchange.category3_name':
                        transformedRow[column.id] = row.exchange?.category3_name || '';
                        break;
                    case 'amount':
                        transformedRow[column.id] = row.amount || 0;
                        break;
                    case 'unit.name':
                        transformedRow[column.id] = row.unit?.name || '';
                        break;
                    default:
                        if (column.id.startsWith('breakdown_')) {
                            const groupingName = typeof column.header === 'function' ? column.header({} as any) : column.header;
                            const relatedBreakdown = row.relatedBreakdown.find(
                                (b: any) => b.groupingName === groupingName
                            );
                            transformedRow[column.id] = relatedBreakdown ? relatedBreakdown.amount : 0;
                        } else {
                            transformedRow[column.id] = ''; // その他のカラムは空に設定
                        }
                        break;
                }
            });

            return transformedRow;
        });

        return { headers, transformedData };
    };



    // エクスポート関数
    const exportCsv = (data: any[], columns: ColumnDef<any>[]) => {
        if (!data || data.length === 0 || !columns || columns.length === 0) {
            console.error('エクスポートするデータまたはカラム情報が不足しています。');
            return;
        }

        // データを変換
        const { headers, transformedData } = transformData(data, columns);

        try {
            // CSVを生成する
            const csvContent = [
                headers.map(header => `"${header}"`).join(','), 
                ...transformedData.map(row => 
                    Object.values(row).map(value => `"${String(value).replace(/"/g, '""')}"`).join(',') 
                )].join('\n');

            // Blobを使ってダウンロード
            downloadCsv(csvContent, "case_study_lci_results" || 'default.csv');
        } catch (error) {
            console.error('CSVのエクスポート中にエラーが発生しました:', error);
        }
    };


    return (
        <Section>
            <Title>{t('インベントリ分析')}</Title>
            <div>
                <H2>{t('グルーピング')}</H2>
                <RadioWrap>
                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="1"
                        value="1"
                        checked={inputGroupingValue === "1"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="1">
                        <RadioText>
                            {t('サブシステム')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="2"
                        value="2"
                        checked={inputGroupingValue === "2"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="2">
                        <RadioText>
                            {t('サブシステムカテゴリ')}
                        </RadioText>
                    </RadioLabel>

                    <SelectLabel>
                        <Select
                            value={selectCategorySet}
                            onChange={(event) => {
                                handleInputChangeSelect(event)
                            }}
                        >
                            {subsystemCategorySetData?.map(categorySet => (
                                <option key={categorySet.id} value={categorySet.id}>
                                    {categorySet.name}
                                </option>
                            ))}
                        </Select>
                    </SelectLabel>

                    <RadioInput
                        type="radio"
                        name="grouping"
                        id="3"
                        value="3"
                        checked={inputGroupingValue === "3"}
                        onChange={(event) => handleInputChange(event, "grouping")}
                    />
                    <RadioLabel htmlFor="3">
                        <RadioText>
                            {t('上流フロー')}
                        </RadioText>
                    </RadioLabel>
                </RadioWrap>
            </div>
            <div>
                <H2>{t('表示形式')}</H2>
                <RadioWrap>
                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="4"
                        value="1"
                        checked={inputDsplayFormatValue === "1"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="4">
                        <RadioText>
                            {t('数値')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="5"
                        value="2"
                        checked={inputDsplayFormatValue === "2"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="5">
                        <RadioText>
                            {t('パーセント')}
                        </RadioText>
                    </RadioLabel>
                </RadioWrap>
            </div>
            {calculateLoading &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {lciResultsCategoryLoading &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {lciResultsUpperLoading &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {!calculateLoading && !lciResultsCategoryLoading && !lciResultsUpperLoading &&
                <>
                    <CaseStudyCalculateResultTable
                        columns={columns}
                        resultData={flattenedData}
                        paging_flag={false}
                        exportSet={exportSet}
                    />
                    <ButtonWrap>
                        <Button type='button' onClick={() => exportCsv(exportData || [], exportColumns || [])}>
                            <ButtonText>{t('エクスポート')}</ButtonText>
                        </Button>
                        <CaseStudyContributionAnalysisModal resultData={calculateData} result_type="lci"></CaseStudyContributionAnalysisModal>
                    </ButtonWrap>
                </>
            }
        </Section>
    );
};

export default InventoryAnalysis;

const Title = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
`

const Section = styled.section`
    margin-bottom: 32px;
    display: block;
`

const H2 = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const RadioWrap = styled.div`
    margin-bottom: 32px;
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const RadioInput = styled.input`
  display: none;

  &:checked + label::before {
    border-color: var(--color-site-secondary);
    background-color: var(--color-site-secondary);
  }

  &:checked + label::after {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 4.5px;
    height: 4.5px;
    content: "";
    border-radius: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
  }

  &:not(:checked) + label .c-input,
  &:not(:checked) + label .c-select,
  &:not(:checked) + label .c-select__inner {
    pointer-events: none;
    color: var(--color-line-primary) !important;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px;
  width: fit-content;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--color-txt-primary);
    border-radius: 50%;
    background-color: #fff;
  }
`;

const RadioText = styled.span`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500;
`;

const ButtonWrap = styled.div`
    margin: 20px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
`

const Button = styled.button`
    margin-right: 10px;
    background-color: var(--color-site-secondary);
    border-radius: 4px;
    border: 1px solid var(--color-site-secondary);
    padding: 7px 16px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    font: inherit;
    outline: none;
`

const ButtonText = styled.span`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
`

const SelectLabel = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointe
`

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`
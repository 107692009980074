import React, { useState, useContext, useImperativeHandle, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import SubsystemDivisionEditModal from '../subsystem_division/SubsystemDivisionEditModal';

import AddButton from '@common/button/AddButton'
import DeleteIconButton from '@common/button/DeleteIconButton'
import SubsystemDivisionCreateModal from '../subsystem_division/SubsystemDivisionCreateModal';
import { useTranslation } from 'react-i18next';
import { useDeleteSubsystemCategorySet } from '@hooks/useBackendApi';

const SubsystemCategorySelect = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { 
        caseStudyData, 
        subsystemCategorySetData,
        setSubsystemCategorySetData,
        setSelectCategorySetList, 
        selectCategorySetList,
        categorySetRows,
        setCategorySetRows
    } = context;

    // カテゴリ値を取得する関数
    useImperativeHandle(ref, () => ({
        getCategoryValueIds: () => categorySetRows.map(row => String(row.selectedCategoryValueId))
    }));

    // 削除
    const {deleteSubsystemCategorySet} = useDeleteSubsystemCategorySet();
    const handleDelete = async(inputCategoryId: number) => {
        const isConfirmed = window.confirm(t('削除しますか？'));
        if(isConfirmed) {
            try {
                await deleteSubsystemCategorySet(caseStudyData?.id || 0, inputCategoryId);

                const updatedRows = categorySetRows.filter(row => row.inputCategoryId !== inputCategoryId);
                setCategorySetRows(updatedRows);
                setSelectCategorySetList(updatedRows.map(row => row.inputCategoryId)); // 削除されたIDを除く
            } catch (error) {
                console.log(error)
            }
        }
    };

    return (
        <div>
            {categorySetRows.map((row, index)  => (
                <Colmun key={`${row.id}-${index}`}>
                    <FlexDiv>
                        <Input
                            type='text'
                            disabled
                            value={row.inputCategoryName}
                        />

                        <Input
                            type='text'
                            disabled
                            value={row.selectedCategoryValue}
                        />
                        <SubsystemDivisionEditModal rowId={row.inputCategoryId} rowName={row.inputCategoryName} subsystemCategories={subsystemCategorySetData?.find(categorySet => categorySet.id === row.inputCategoryId)?.subsystem_categories || []} />

                        <DeleteIconButton type='button' onClick={() => handleDelete(row.inputCategoryId)} />
                    </FlexDiv>


                </Colmun>
            ))}
            <SubsystemDivisionCreateModal/>
        </div>
    );
});

export default SubsystemCategorySelect;

const FlexDiv = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-end;
gap: 12px;
margin-bottom: 10px;
`;

const Colmun = styled.div`
display: flex;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
justify-content: flex-end;
gap: 12px;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`

const Select = styled.select`
color: rgb(85, 85, 85);
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const Input = styled.input`
width: min(100%, 340px);
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const ButtonWrap = styled.div`
text-align: right;
`
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { CaseStudyLCIAResults, ImpactAssesmentMethods, LCIAResults } from '@typeList/types';
import { useCaseStudyLciaResults, useLciaResults } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import AddButton from '@common/button/AddButton';
import styled from 'styled-components';
import Modal from '@common/modal/Modal';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import LciaModelSelectTable from '@common/table/LciaModelSelectTable';
import ModalButton from '@common/button/ModalButton';
import DamageAssessmentLciaModelSelectTable from '@common/table/DamageAssessmentLciaModelSelectTable';

interface LciaModelSelectProps {
    methodsData: ImpactAssesmentMethods[] | null;
    // setResultData: Dispatch<SetStateAction<LCIAResults[]>>
    selectModelIdList: number[]
    setSelectModelIdList: React.Dispatch<React.SetStateAction<number[]>>
    calculateData: any;
}

/**
 * LCIAモデル選択
 * @returns 
 */
const DamageAssessmentLciaModelSelectModal: React.FC<LciaModelSelectProps> = ({ methodsData, selectModelIdList, setSelectModelIdList, calculateData }) => {

    const { t } = useTranslation();
    // モーダル操作
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [selectList, setSelectedList] = useState<number[]>([])
    useEffect(() => {
        setSelectedList(selectModelIdList)
    },[selectModelIdList])

    const handleResultData = async () => {
        setSelectModelIdList(selectList);
        setIsModalOpen(false);
    };

    // 選択されたmethodのIDを管理
    const [selectMethodId, setSelectMethodId] = useState<number | null>(null);

    // methodsDataの最初のIDを初期値として設定
    useEffect(() => {
        if (methodsData && methodsData.length > 0) {
            setSelectMethodId(methodsData[0].impact_assessment_method.id); // 初期値を最初のIDに設定
        }
    }, [methodsData]);

    // プルダウンの選択変更を処理
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = Number(event.target.value);
        setSelectMethodId(selectedId);

        // 選択されたIDでデータをフィルタリング
        const filteredMethodsData = methodsData?.filter((item) => item.id === selectedId);

        // フィルタリングされたデータのIDをsetSelectedListにセット
        if (filteredMethodsData) {
            setSelectedList(filteredMethodsData.map((item) => item.id));
        }
    };

    // 重複を除外したimpact_assessment_method.idのリストを生成
    const uniqueMethods = methodsData
        ? Array.from(new Map(methodsData.map(item => [item.impact_assessment_method.id, item])).values())
        : [];

    // methodsDataを選択されたIDでフィルタリング
    const [filteredMethodsData, setfilteredMethodsData] =useState<ImpactAssesmentMethods[] | undefined>();
    useEffect(() =>{
        const filteredData = selectMethodId
            ? methodsData?.filter((item) => item.impact_assessment_method.id === selectMethodId)
            : [];
        console.log("selectMethodId", selectMethodId)
        setfilteredMethodsData(filteredData);
    },[selectMethodId])

    return (
        <>
            <SectionButtonAdd>
                <ModalButton
                    onClick={openModal}
                    text={t('LCIAモデルの選択')} 
                    disabled={!calculateData || calculateData.result_items.length === 0}
                />
            </SectionButtonAdd>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <SectionTitle>
                        <Title>{t('表示する被害評価を選択')}</Title>
                    </SectionTitle>
                    <SelectLabel>
                        <Select onChange={handleSelectChange} value={selectMethodId || 1}>
                            {uniqueMethods?.map((item: any) => (
                                // <option key={item.id} value={item.id} >
                                <option key={item.id} value={item.impact_assessment_method.id} >
                                    {item.impact_assessment_method.name}
                                </option>
                            ))}
                        </Select>
                    </SelectLabel>
                    <DamageAssessmentLciaModelSelectTable
                        count={0}
                        data={filteredMethodsData || []}
                        paging_flag={false}
                        selectModelIdList={selectModelIdList}
                        setSelectedList={setSelectedList}
                        selectList={selectList}
                    />
                    <ModalButton type='button' onClick={handleResultData} text={t('選択')}></ModalButton>
                </Inner>
            </Modal>
        </>
    );
};

export default DamageAssessmentLciaModelSelectModal;

const SectionButtonAdd = styled.div`
    margin-top: 20px;
    text-align: right;
`;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`;

const SelectLabel = styled.label`
    width: min(100%, 540px);
    position: relative;
    display: block;
    flex: 1 0 1%;
`
const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    // color: var(--color-line-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 10px;
`


const Title = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionTitle = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`
import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
    ColumnMeta,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import styled from '@emotion/styled';
import AddButton from '@common/button/AddButton';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconDelete from '@images/table/icon_delete.svg';


interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    paging_flag: boolean;
    onDataChange: (data: any[]) => void;
}

const initialPageIndex = 0;
const initialPageSize = 1000;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

interface ExtendedColumnMeta extends ColumnMeta<any, unknown> {
    editable?: boolean;
    type?: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getToggleSortingHandler()}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const ProsessParameterSettingTable: React.FC<TableProps> = ({ columns: initialColumns, data, paging_flag, onDataChange }) => {
    const { t } = useTranslation();
    const [columns, setColumns] = useState(initialColumns);
    const context = useContext(ProcessViewContext);
    const { setUpdateProcessParameters, setChangeCheckExtendProperty } = context;
    const [tableData, setTableData] = useState(data);
    const [sorting, setSorting] = useState<SortingState>([]);

    useEffect(() => {
        onDataChange(tableData)
    }, [tableData]);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const addRow = () => {
        setTableData([...tableData, { editable: true }]);
        setChangeCheckExtendProperty(true)
    };

    const removeRow = (rowIndex: number) => {
        const updatedData = tableData.filter((_, index) => index !== rowIndex);
        setTableData(updatedData);
        setUpdateProcessParameters(updatedData)
        setChangeCheckExtendProperty(true)
    };

    const handleCellChange = (rowIndex: number, columnId: string, value: any) => {
        const updatedData = [...tableData];
        updatedData[rowIndex] = {
            ...updatedData[rowIndex],
            [columnId]: value,
        };
        setTableData(updatedData);
        setChangeCheckExtendProperty(true)
    };

    const table = useReactTable({
        data: tableData ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="p-2">
            {paging_flag && (
                <div style={{ margin: '5px' }}>
                    <select
                        style={{ margin: '5px' }}
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {t('表示数')} {pageSize}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                        {'<'}
                    </button>
                    {table.getPageOptions().map((page) => (
                        <button
                            key={page}
                            onClick={() => table.setPageIndex(page)}
                            disabled={table.getState().pagination.pageIndex === page}
                        >
                            {page + 1}
                        </button>
                    ))}
                    <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                        {'>'}
                    </button>
                </div>
            )}
            
            <Table>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            <Th>
                                <ThInner>
                                    {t('削除')}
                                </ThInner>
                            </Th>
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row, rowIndex) => (
                        <Tr key={row.id}>
                            <Td>
                                <IconButton type="button" onClick={() => removeRow(row.index)}>
                                    <Icon></Icon>
                                    {/* {t('削除')} */}
                                </IconButton>
                            </Td>
                            {row.getVisibleCells().map(cell => (
                                <Td key={cell.id}>
                                    {(cell.column.columnDef.meta as ExtendedColumnMeta).editable ? (
                                        <Input
                                            type={(cell.column.columnDef.meta as ExtendedColumnMeta).type }
                                            value={cell.getValue()?.toString() || ''}
                                            onChange={(e) => handleCellChange(row.index, cell.column.id, e.target.value)}
                                        />
                                    ) : (
                                        flexRender(cell.column.columnDef.cell, cell.getContext())
                                    )}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <ButtonWrap>
                <AddButton type='button' onClick={addRow} text={t('追加')}></AddButton>
            </ButtonWrap>
            <div className="h-4" />
        </div>
    );
};

export default ProsessParameterSettingTable;

const Dl = styled.dl`
display: grid;
gap: 8px 40px;
grid-template-columns: 25% 70%;
`

const Dt = styled.dt`
font-size: 12px;
font-weight: 500;
padding: 12px 0;
`

const Dd = styled.dd`
font-size: 12px;
font-weight: 500;
// padding: 12px 0;
`

const Select = styled.select`
position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
// color: var(--color-line-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
// background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
// appearance: none;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
cursor: pointe
`

const Content = styled.div`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`

const TableWrap = styled.div`
overflow-x: auto;
`


const Table = styled.table`
min-width: 100%;
border-collapse: collapse;
margin-bottom: 8px;
background-color: #fff;
border-spacing: 0;
`

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`

const ThInner = styled.div`
width: fit-content;
display: flex;
align-items: left;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`;

const Tbody= styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Td= styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
}
`

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconButton = styled.button`
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`

const Icon = styled.span`
mask: url(${iconDelete}) no-repeat center center / contain;
-webkit-mask: url(${iconDelete}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`

const Input = styled.input`
width: 100%;
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;

appearance: none;
background: transparent;
font: inherit;
outline: none;
}
`


const ButtonWrap = styled.div`
text-align: end;
}
`
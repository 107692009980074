import React, { useState, useCallback, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import Modal from '@common/modal/Modal';  // モーダルコンポーネントのインポート
import { EditDataTableType } from '@typeList/types';
import { ProcessViewContext } from '@pages/process/ProcessView';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconEdit from '@images/table/icon_edit.svg';
import iconCross from '@images/table/icon_cross.svg';
import { t } from 'i18next';

interface TableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    paging_flag: boolean;
    ModalComponent: React.FC<EditDataTableType>;
}

const initialPageIndex = 0;
const initialPageSize = 100;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const OutputElementayFlowEditDataTable: React.FC<TableProps> = ({ columns: initialColumns, data, paging_flag, ModalComponent }) => {
    const context = useContext(ProcessViewContext);
    const { setOutElementaryFlow, outElementaryFlow, setChangeCheckFlow, userData } = context;
    const isAdmin = userData?.is_admin; // 管理者かどうかを判定
    // 未保存の場合リロード時に削除したテーブルを再描画
    useEffect(() => {
        setOutElementaryFlow(data);
    }, [data])
    const adminColumn: ColumnDef<any, any> = {
        id: 'idColumn',
        header: () => t('ID'),
        accessorFn: (row) => {
            // contextからprocess_iosを取得し、対応するIDを見つける
            const processIos = context.processData?.process_ios || [];
            const processIo = processIos.find(io => io.id === row.id); // 行のidを使用して検索
            return processIo ? processIo.id : 'N/A'; // ソート用データを返す
        },
        cell: ({ row }) => {
            // processIosからIDを取得する
            const processIos = context.processData?.process_ios || [];
            const processIo = processIos.find(io => io.id === row.original.id); // 行に基づいて対応するprocessIoを検索
            return (
                <div>
                    <span>{processIo ? processIo.id : 'N/A'}</span>
                </div>
            );
        },
        enableSorting: true, // ソート可にする
    };
    const [columns, setColumns] = useState<ColumnDef<any, any>[]>([
        {
            id: 'actions1',
            header: () => t('編集'),
            cell: ({ row }) => (
                <div>
                    <EditButton type='button' onClick={() => handleOpenModal(row.id)}>
                        <ButtonInner>
                            <EditButtonIcon></EditButtonIcon>
                        </ButtonInner>
                    </EditButton>
                </div>
            ),
            enableSorting: false, // ソート不可にする
        },
        {
            id: 'actions2',
            header: () => t('解除'),
            cell: ({ row }) => (
                <div>
                    <DeleteButton type='button' onClick={() => handleDeleteRow(row.index)}>
                        <ButtonInner>
                            <DeleteButtonIcon></DeleteButtonIcon>
                        </ButtonInner>
                    </DeleteButton>
                </div>
            ),
            enableSorting: false, // ソート不可にする
        },
        ...(isAdmin ? [adminColumn] : []), // 管理者の場合のみadminColumnを追加
        ...initialColumns,
    ]);

    const [sorting, setSorting] = useState<SortingState>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowId, setCurrentRowId] = useState<string | null>(null);

    // console.log("Editdata", data);
    const handleOpenModal = (id: string) => {
        // console.log(id);
        setCurrentRowId(id);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentRowId(null);
    };

    const handleDeleteRow = (index: number) => {
        setOutElementaryFlow((prev) => prev?.filter((_, i) => i !== index));
        setChangeCheckFlow(true)
    };

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: data ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <SectionTableWrap>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <ModalComponent Id={Number(currentRowId)} onClose={handleCloseModal} selectted_database_pack_id={null} />
            </Modal>
            {paging_flag && (
                <div style={{ margin: '5px' }}>
                    <select
                        style={{ margin: '5px' }}
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                表示数 {pageSize}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>
                    {table.getPageOptions().map((page) => (
                        <button
                            key={page}
                            onClick={() => table.setPageIndex(page)}
                            disabled={table.getState().pagination.pageIndex === page}
                        >
                            {page + 1}
                        </button>
                    ))}
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                </div>
            )}
            <Table>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => (
                                <DraggableHeader
                                    key={header.id}
                                    header={header}
                                    index={index}
                                    moveColumn={moveColumn}
                                />
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Tr key={row.id} style={{ background: row.index % 2 === 0 ? '#fafafa' : '#fff' }}>
                            {row.getVisibleCells().map((cell) => (
                                <Td key={cell.id} title={String(cell.getValue())}> {/* title属性で全表示 */}
                                    <CellContent>{flexRender(cell.column.columnDef.cell, cell.getContext())}</CellContent>
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </SectionTableWrap>
    );
};

export default OutputElementayFlowEditDataTable;

const CellContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const EditButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;
const ButtonInner = styled.div`
`;
const EditButtonIcon = styled.span`
mask: url(${iconEdit}) no-repeat center center / contain;
-webkit-mask: url(${iconEdit}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;

const DeleteButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const DeleteButtonIcon = styled.span`
mask: url(${iconCross}) no-repeat center center / contain;
-webkit-mask: url(${iconCross}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;


const Table = styled.table`
min-width: 100%;
border-collapse: collapse;
margin-bottom: 8px;
background-color: #fff;
border-spacing: 0;
`

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
`

const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`

const ThInner = styled.div`
width: fit-content;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`

const Tbody = styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`
const Td = styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
}
`

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`